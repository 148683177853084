import React from 'react';

// Layouts
import MainLayout from "../Layout/MainLayout/MainLayout";
import MainLayoutInstitution from "../Layout/MainLayoutInstitution/MainLayoutInstitution"
import AuthenticationLayout from "../Layout/AuthenticationLayout/AuthenticationLayout";
import LearnerLayout from "../Layout/LearnerLayout/LearnerLayout";
import LearnerLayoutV2 from "../Layout/LearnerLayoutV2/LearnerLayoutV2";

// Views
import InvestorDashboard from "../View/Investor/Dashboard/Dashboard";
import InvestorDashboardV2 from "../View/Investor/DashboardV2/Dashboard";

import ReportingMarketingDashboard from "../View/ReportingMarketing/Dashboard/Dashboard";
import ReportingMarketingCampaign from "../View/ReportingMarketing/Campaign/Campaign";

import ReportingPedagogicSurveySatisfaction from "../View/ReportingPedagogic/SurveySatisfaction/SurveySatisfaction";
import ReportingPedagogicEppList from "../View/ReportingPedagogic/Epp/EppList";
import ReportingPedagogicEpp from "../View/ReportingPedagogic/Epp/Epp";

import CRMReporting from "../View/CRM/Reporting/Reporting";
import CRMDashboard from "../View/CRM/Dashboard/Dashboard";
import CRMProspectList from "../View/CRM/ProspectList/ProspectList";
import CRMProspect from "../View/CRM/Prospect/Prospect";
import CRMCommercialTask from "../View/CRM/CommercialTask/CommercialTask";
import CRMHotlineList from "../View/CRM/HotlineList/HotlineList";
import CRMRelaunchList from "../View/CRM/RelaunchList/RelaunchList";
import CRMCommercialManagementList from "../View/CRM/CommercialManagement/CommercialManagementRegistrationList";
import CRMCommercialUsersManagementList from "../View/CRM/CommercialUsersManagementList/CommercialUsersManagementList";
import CRMCommercialUsersManagement from "../View/CRM/CommercialUsersManagement/CommercialUsersManagement";
import CRMDashboardManager from "../View/CRM/DashboardManager/DashboardManager";
import CRMPersonalRelaunchList from "../View/CRM/PersonalRelaunchList/PersonalRelaunchList";


import OrganisationChartHR from '../View/HumanRessources/HumanResourcesChart/OrganisationChartHR';
import EmployeeRelationship from "../View/HumanRessources/HumanResourcesList/EmployeeRelationship";

import WebcoachRelaunch from '../View/Webcoach/Relaunch/Relaunch';
import WebcoachManager from "../View/Webcoach/Manager/Manager";

import AdministratorUserList from "../View/Administrator/UserList/UserList";
import AdministratorUser from "../View/Administrator/User/User";
import AdministratorOrganismList from "../View/Administrator/OrganismList/OrganismList";
import AdministratorOrganism from "../View/Administrator/Organism/Organism";
import AdministratorRoleList from "../View/Administrator/RoleList/RoleList";
import AdministratorRole from "../View/Administrator/Role/Role";
import AdministratorPoleList from "../View/Administrator/PoleList/PoleList";
import AdministratorPole from "../View/Administrator/Pole/Pole";
import AdministratorSessionList from "../View/Administrator/SessionList/SessionList";
import AdministratorSession from "../View/Administrator/Session/Session";
import AdministratorFormationGroupPropertyList from "../View/Administrator/FormationGroupPropertyList/FormationGroupPropertyList";
import AdministratorFormationGroupProperty from "../View/Administrator/FormationGroupProperty/FormationGroupProperty";
import AdministratorUserHotlineTypeList from "../View/Administrator/HotlineTypeList/UserHotlineTypeList";
import AdministratorProspectHotlineTypeList from "../View/Administrator/HotlineTypeList/ProspectHotlineTypeList";
import PlatformLogs from "../View/Administrator/PlatformLogs/PlatformLogs";
import AdministratorTokenApiList from "../View/Administrator/TokenApi/TokenApi";

import ContentManagerFormationList from "../View/ContentManager/FormationList/FormationList";
import ContentManagerFormation from "../View/ContentManager/Formation/Formation";
import ContentManagerQuestion from "../View/ContentManager/Question/Question";
import ContentManagerChapterList from "../View/ContentManager/ChapterList/ChapterList";
import ContentManagerChapter from "../View/ContentManager/Chapter/Chapter";
import ContentManagerCourseList from "../View/ContentManager/CourseList/CourseList";
import ContentManagerCourse from "../View/ContentManager/Course/Course";
import ContentManagerResourceList from "../View/ContentManager/ResourceList/ResourceList";
import ContentManagerResource from "../View/ContentManager/Resource/Resource";
import ContentManagerResourceTypeList from '../View/ContentManager/ResourceTypeList/ResourceTypeList';
import ContentManagerResourceGroupList from '../View/ContentManager/ResourceGroupList/ResourceGroupList';
import ContentManagerVirtualClassroomList from "../View/ContentManager/VirtualClassroomList/VirtualClassroomList";
import ContentManagerVirtualClassroom from "../View/ContentManager/VirtualClassroom/VirtualClassroom";
import ContentManagerVirtualClassroomPeriodList from "../View/ContentManager/VirtualClassroomPeriodList/VirtualClassroomPeriodList";

import MarketingManagerthematicList from "../View/MarketingManager/ThematicList/ThematicList";
import MarketingManagerthematic from "../View/MarketingManager/Thematic/Thematic";
import MarketingManagerLibraryResourceList from "../View/MarketingManager/LibraryResourceList/LibraryResourceList";
import MarketingManagerLibraryResourceTypeList from "../View/MarketingManager/LibraryResourceTypeList/LibraryResourceTypeList";
import MarketingManagerFeaturedResource from "../View/MarketingManager/FeaturedResource/FeaturedResource";
import MarketingManagerCatalogList from "../View/MarketingManager/CatalogList/CatalogList";
import StripePromotionCode from "../View/MarketingManager/StripePromotionCode/StripePromotionCodeList";
import MarketingManagerHubspotQueue from "../View/MarketingManager/HubspotQueue/HubspotQueueList";

import FinancialManagementBillingListV2 from "../View/FinancialManagement/BillingListV2/BillingListV2";
import FinancialManagementBillingV2 from "../View/FinancialManagement/BillingV2/BillingV2";

import FinancialManagementBillingList from "../View/FinancialManagement/BillingList/BillingList";
import FinancialManagementBilling from "../View/FinancialManagement/Billing/Billing";
import FinancialManagementBillingFIFPLList from "../View/FinancialManagement/BillingFIFPLList/BillingFIFPLList";
import FinancialManagementBillingFIFPL from "../View/FinancialManagement/BillingFIFPL/BillingFIFPL";
import FinancialManagementBillingCPFList from "../View/FinancialManagement/BillingCPFList/BillingCPFLList";
import FinancialManagementBillingCPF from "../View/FinancialManagement/BillingCPF/BillingCPF";
import FinancialManagementPaymentFollowList from "../View/FinancialManagement/PaymentFollowList/PaymentFollowList";
import FinancialManagementRelaunchList from "../View/FinancialManagement/RelaunchList/RelaunchList";
import FinancialManagementStatistics from "../View/FinancialManagement/Statistics/Statistics";
import FinancialManagementAndpcEmailTrackingList from "../View/FinancialManagement/AndpcEmailTracking/AndpcEmailTrackingList";
import FinancialManagementBillingPERSONAL from "../View/FinancialManagement/BillingPERSONAL/BillingPERSONAL";


import AboutUpdate from "../View/About/Update";
import AboutRoadmapSI from "../View/About/RoadmapSI";
import AboutRoadmapPed from "../View/About/RoadmapPed";

import FormerVirtualClassroomList from "../View/Former/FormerVirtualClassroomList/FormerVirtualClassroomList";
import FormerVirtualClassroom from "../View/Former/FormerVirtualClassroom/FormerVirtualClassroom";
import FormerVirtualClassroomLearners from "../View/Former/FormerVirtualClassroom/FormerVirtualClassroomLearners";

import CommunityProspectList from "../View/Community/ProspectList/ProspectList";
import CommunityGroupPrivateList from "../View/Community/GroupPrivateList/GroupPrivateList";
import CommunityGroupFormationList from "../View/Community/GroupFormationList/GroupFormationList";
import CommunityGroupPost from "../View/Community/Group/GroupPost/GroupPost";
import CommunityGroup from "../View/Community/Group/Group";

import SupportProspectHotlineList from "../View/Support/ProspectHotlineList/ProspectHotlineList";
import SupportUserHotlineList from "../View/Support/UserHotlineList/UserHotlineList";
import SupportHotlineDashboard from "../View/Support/Dashboard/Dashboard";

import ItInfrastructureEquipmentList from "../View/ItInfrastructure/EquipmentList/EquipmentList";
import ItInfrastructureEquipment from "../View/ItInfrastructure/Equipment/Equipment";
import ItInfrastructureEquipmentTypeList from "../View/ItInfrastructure/EquipmentTypeList/EquipmentTypeList";
import ItInfrastructureEquipmentBrandList from "../View/ItInfrastructure/EquipmentBrandList/EquipmentBrandList";
import ItInfrastructureEquipmentModelList from "../View/ItInfrastructure/EquipmentModelList/EquipmentModelList";

import LearnerHome from "../View/LearnerV2/Home/Home";
import LearnerInstitutionList from "../View/LearnerV2/Institution/InstitutionList/InstitutionList";
import LearnerInstitutionManager from "../View/LearnerV2/Institution/InstitutionManager/InstitutionManager";
import LearnerInstitutionEmpoyeeList from "../View/LearnerV2/Institution/InstitutionEmpoyeeList/InstitutionEmpoyeeList";
import LearnerInstitutionRegistrationList from "../View/LearnerV2/Institution/InstitutionRegistrationList/InstitutionRegistrationList";
import LearnerCommunity from "../View/LearnerV2/Community/Community";
import LearnerCommunityGroup from "../View/LearnerV2/Community/CommunityGroup";
import LearnerCommunityPost from "../View/LearnerV2/Community/CommunityPost";
import LearnerCatalog from "../View/LearnerV2/Catalog/Catalog";
import LearnerRegister from "../View/LearnerV2/Register/Register";
import LearnerResourceType from "../View/LearnerV2/ResourceType/ResourceType";
import LearnerResource from "../View/LearnerV2/Resource/Resource";
import LearnerLibraryResource from "../View/LearnerV2/LibraryResource/LibraryResource";
import LearnerMyInfo from "../View/LearnerV2/MyInfo/MyInfo";
import LearnerDocument from "../View/LearnerV2/Document/Document";
import LearnerInvoice from "../View/LearnerV2/Invoice/Invoice";
import LearnerRegistration from "../View/LearnerV2/Registration/Registration";
import LearnerRegistrationCourse from "../View/LearnerV2/RegistrationCourse/RegistrationCourse";
import LearnerRegistrationSurveySatisfaction from "../View/LearnerV2/RegistrationSurveySatisfaction/RegistrationSurveySatisfaction";
import LearnerRegistrationSurveyPrePost from "../View/LearnerV2/RegistrationSurveyPrePost/RegistrationSurveyPrePost";
import LearnerRegistrationSurveyEpp from "../View/LearnerV2/RegistrationSurveyEpp/RegistrationSurveyEpp";
import LearnerRegistrationVirtualClassroom from "../View/LearnerV2/RegistrationVirtualClassroom/RegistrationVirtualClassroom";
import LearnerRegistrationVirtualClassroomReplay from "../View/LearnerV2/RegistrationVirtualClassroomReplay/RegistrationVirtualClassroomReplay";
import LearnerRegistrationResourceCourse from "../View/LearnerV2/ResourceCourse/ResourceCourse";

import EnrollmentRegister from "../View/Enrollment/Register/Register";

import Maintenance from "../View/Maintenance/Maintenance";
import Login from "../View/Authentication/Login/Login";
import Register from "../View/Authentication/Register/Register";
import PaymentStripe from '../View/Stripe/PaymentStripe';
import TokenApi from '../View/Administrator/TokenApi/TokenApi';

// Views institution
import InstitutionHealthHome from "../View/InstitutionHealth/Home/Home";

import InstitutionHealthInvestor from "../View/InstitutionHealth/Investor/Investor";

import InstitutionHealthAdministratorSessionList from "../View/InstitutionHealth/Administration/SessionList/SessionList";
import InstitutionHealthAdministratorSession from "../View/InstitutionHealth/Administration/Session/Session";
import InstitutionHealthAdministratorUserList from "../View/InstitutionHealth/Administration/UserList/UserList";
import InstitutionHealthAdministratorUser from "../View/InstitutionHealth/Administration/User/User"

import InstitutionHealthCRMInstitutionList from "../View/InstitutionHealth/CRM/InstitutionList/InstitutionList";
import InstitutionHealthCRMInstitutionGroupList from "../View/InstitutionHealth/CRM/InstitutionGroupList/InstitutionGroupList";
import InstitutionHealthCRMInstitution from "../View/InstitutionHealth/CRM/Institution/Institution";
import InstitutionHealthCRMInstitutionGroup from "../View/InstitutionHealth/CRM/InstitutionGroup/InstitutionGroup";
import InstitutionHealthCRMInstitutionManager from "../View/InstitutionHealth/CRM/InstitutionManager/InstitutionManager";
import InstitutionHealthCRMInstitutionEmployeeList from "../View/InstitutionHealth/CRM/InstitutionEmployeeList/InstitutionEmployeeList";
import InstitutionHealthCRMEmployee from "../View/InstitutionHealth/CRM/InstitutionEmployee/InstitutionEmployee";
import InstitutionHealthCRMRegistrationList from "../View/InstitutionHealth/CRM/RegistrationList/RegistrationList";
import InstitutionHealthCRMDashboardManager from "../View/InstitutionHealth/CRM/DashboardManager/DashboardManager";
import InstitutionHealthCRMHotlineList from "../View/InstitutionHealth/CRM/HotlineEmployeeList/HotlineEmployeeList";
import InstitutionHealthCRMRelaunchList from "../View/InstitutionHealth/CRM/RelaunchList/RelaunchList";

import InstitutionHealthContentManagerFormationList from "../View/InstitutionHealth/ContentManager/FormationList/FormationList";
import InstitutionHealthContentManagerFormation from "../View/InstitutionHealth/ContentManager/Formation/Formation";
import InstitutionHealthContentManagerQuestion from "../View/InstitutionHealth/ContentManager/Question/Question";
import InstitutionHealthContentManagerChapterList from "../View/InstitutionHealth/ContentManager/ChapterList/ChapterList";
import InstitutionHealthContentManagerChapter from "../View/InstitutionHealth/ContentManager/Chapter/Chapter";
import InstitutionHealthContentManagerCourseList from "../View/InstitutionHealth/ContentManager/CourseList/CourseList";
import InstitutionHealthContentManagerCourse from "../View/InstitutionHealth/ContentManager/Course/Course";
import InstitutionHealthContentManagerResourceList from "../View/InstitutionHealth/ContentManager/ResourceList/ResourceList";
import InstitutionHealthContentManagerResource from "../View/InstitutionHealth/ContentManager/Resource/Resource";
import InstitutionHealthContentManagerResourceTypeList from '../View/InstitutionHealth/ContentManager/ResourceTypeList/ResourceTypeList';
import InstitutionHealthContentManagerResourceGroupList from '../View/InstitutionHealth/ContentManager/ResourceGroupList/ResourceGroupList';
import InstitutionHealthContentManagerVirtualClassroomList from "../View/InstitutionHealth/ContentManager/VirtualClassroomList/VirtualClassroomList";
import InstitutionHealthContentManagerVirtualClassroom from "../View/InstitutionHealth/ContentManager/VirtualClassroom/VirtualClassroom";
import InstitutionHealthContentManagerVirtualClassroomPeriodList from "../View/InstitutionHealth/ContentManager/VirtualClassroomPeriodList/VirtualClassroomPeriodList";

import InstitutionHealthMarketingManagerthematicList from "../View/InstitutionHealth/MarketingManager/ThematicList/ThematicList";
import InstitutionHealthMarketingManagerthematic from "../View/InstitutionHealth/MarketingManager/Thematic/Thematic";
import InstitutionHealthMarketingManagerLibraryResourceList from "../View/InstitutionHealth/MarketingManager/LibraryResourceList/LibraryResourceList";
import InstitutionHealthMarketingManagerLibraryResourceTypeList from "../View/InstitutionHealth/MarketingManager/LibraryResourceTypeList/LibraryResourceTypeList";
import InstitutionHealthMarketingManagerFeaturedResource from "../View/InstitutionHealth/MarketingManager/FeaturedResource/FeaturedResource";

import InstitutionHealthFinancialManagementBillingInstitutionMixteList from "../View/InstitutionHealth/FinancialManagement/BillingInstitutionList/BillingInstitutionMixtList";
import InstitutionHealthFinancialManagementBillingInstitutionPersonalList from "../View/InstitutionHealth/FinancialManagement/BillingInstitutionList/BillingInstitutionPersonalList";
import InstitutionHealthFinancialManagementBillingInstitutionInvoice from "../View/InstitutionHealth/FinancialManagement/BillingInstitution/Invoice";
import InstitutionHealthFinancialManagementBillingInstitutionInvoiceList from "../View/InstitutionHealth/FinancialManagement/BillingInstitution/InvoiceList";
import InstitutionHealthFinancialManagementBillingInstitutionInvoicePersonal from "../View/InstitutionHealth/FinancialManagement/BillingInstitution/InvoicePersonal";
import InstitutionHealthFinancialManagementBillingInstitutionInvoicePersonalList from "../View/InstitutionHealth/FinancialManagement/BillingInstitution/InvoicePersonalList"

import InstitutionHealthFinancialManagementBillingFormationList from "../View/InstitutionHealth/FinancialManagement/BillingFormationList/BillingFormationList";
import InstitutionHealthFinancialManagementBillingFormation from "../View/InstitutionHealth/FinancialManagement/BillingFormation/BillingFormation";
import InstitutionHealthFinancialManagementBilling from "../View/InstitutionHealth/FinancialManagement/InvoiceList/InvoiceList";


import PaymentStripeSuccess from '../View/Stripe/PaymentStripeSuccess';



const routing = {
    // USER
    // investor_dashboard: {path: '/investor/dashboard', view: InvestorDashboard, layout: MainLayout, roles: ['ROLE_INVESTOR']},
    investor_dashboard_v2: { path: '/investor/dashboard-v2', view: InvestorDashboardV2, layout: MainLayout, roles: ['ROLE_INVESTOR'] },

    reporting_marketing_dashboard: { path: '/reporting-marketing/dashboard', view: ReportingMarketingDashboard, layout: MainLayout, roles: ['ROLE_REPORTING_MARKETING'] },
    reporting_marketing_campaign: { path: '/reporting-marketing/campaign', view: ReportingMarketingCampaign, layout: MainLayout, roles: ['ROLE_REPORTING_MARKETING'] },

    reporting_pedagogic_survey_satisfaction: { path: '/reporting-pedagogic/survey-satisfaction', view: ReportingPedagogicSurveySatisfaction, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC'] },
    reporting_pedagogic_epp_list: { path: '/reporting-pedagogic/epp/list', view: ReportingPedagogicEppList, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC'] },
    reporting_pedagogic_epp: { path: '/reporting-pedagogic/epp/:id?', match: /\/reporting-pedagogic\/epp.*/, view: ReportingPedagogicEpp, layout: MainLayout, roles: ['ROLE_REPORTING_PEDAGOGIC'] },

    crm_reporting: { path: '/crm/reporting', view: CRMReporting, layout: MainLayout, roles: ['ROLE_CRM_SUPERVISOR'] },
    crm_prospect_list: { path: '/crm/prospect/list', view: CRMProspectList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },
    crm_prospect: { path: '/crm/prospect/:id?', match: /\/crm\/prospect.*/, view: CRMProspect, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },
    crm_commercial_task: { path: '/crm/commercial-task', view: CRMCommercialTask, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },
    crm_dashboard: { path: '/crm/dashboard', view: CRMDashboard, layout: MainLayout, roles: ['ROLE_CRM'] },
    crm_hotline: { path: '/crm/hotline', view: CRMHotlineList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },
    crm_relaunch_list: { path: '/crm/relaunch/list', view: CRMRelaunchList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },
    crm_commercial_management_registration_list: { path: '/crm/commercial-management/registration/list', view: CRMCommercialManagementList, layout: MainLayout, roles: ['ROLE_CRM_COM'] },
    crm_commercial_users_management_list: { path: '/crm/commercial-users-management/list', view: CRMCommercialUsersManagementList, layout: MainLayout, roles: ['ROLE_CRM_COM'] },
    crm_commercial_users_management: { path: '/crm/commercial-users-management/:id?', match: /\/crm\/commercial-users-management.*/, view: CRMCommercialUsersManagement, layout: MainLayout, roles: ['ROLE_CRM_COM'] },
    crm_dashboard_manager: { path: '/crm/dashboard-manager', view: CRMDashboardManager, layout: MainLayout, roles: ['ROLE_CRM_ADMIN'] },

    crm_personal_relaunch_list: { path: '/crm/personal/relaunch/list', view: CRMPersonalRelaunchList, layout: MainLayout, roles: ['ROLE_CRM', 'ROLE_CRM_ADMIN', 'ROLE_CRM_SUPER_ADMIN'] },


    hr_chart: { path: '/hr/organisation-chart', view: OrganisationChartHR, layout: MainLayout, roles: ['ROLE_RH'] },
    hr_hierarchy: { path: '/hr/organisation-hierarchy', view: EmployeeRelationship, layout: MainLayout, roles: ['ROLE_RH'] },

    // Webcoach
    webcoach_relaunch: { path: '/webcoach/relaunch', view: WebcoachRelaunch, layout: MainLayout, roles: ['ROLE_WEBCOACH'] },
    webcoach_manager: { path: '/webcoach/manager', view: WebcoachManager, layout: MainLayout, roles: ['ROLE_WEBCOACH_ADMIN'] },

    administrator_user_list: { path: '/administrator/user/list', view: AdministratorUserList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_user: { path: '/administrator/user/:id?', match: /\/administrator\/user.*/, view: AdministratorUser, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_organism_list: { path: '/administrator/organism/list', view: AdministratorOrganismList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_organism: { path: '/administrator/organism/:id?', match: /\/administrator\/organism.*/, view: AdministratorOrganism, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_role_list: { path: '/administrator/role/list', view: AdministratorRoleList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_role: { path: '/administrator/role/:id?', match: /\/administrator\/role.*/, view: AdministratorRole, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_pole_list: { path: '/administrator/pole/list', view: AdministratorPoleList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_pole: { path: '/administrator/pole/:id?', match: /\/administrator\/pole.*/, view: AdministratorPole, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_session_list: { path: '/administrator/session/list', view: AdministratorSessionList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_SESSIONS'] },
    administrator_session: { path: '/administrator/session/:id?', match: /\/administrator\/session.*/, view: AdministratorSession, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR', 'ROLE_ADMINISTRATOR_SESSIONS'] },
    administrator_user_hotline_type_list: { path: '/administrator/user-hotline-type/list', view: AdministratorUserHotlineTypeList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_prospect_hotline_type_list: { path: '/administrator/prospect-hotline-type/list', view: AdministratorProspectHotlineTypeList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },

    administrator_erp_logs: { path: '/administrator/erp-logs/list', view: PlatformLogs, layout: MainLayout, roles: ['ROLE_SUPER_ADMIN'] },
    administrator_token_api: { path: '/administrator/token-api/list', view: AdministratorTokenApiList, layout: MainLayout, roles: ['ROLE_SUPER_ADMIN'] },


    administrator_formation_group_property_list: { path: '/administrator/formation-group-property/list', view: AdministratorFormationGroupPropertyList, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },
    administrator_formation_group_property: { path: '/administrator/formation-group-property/:id?', match: /\/administrator\/formation-group-property.*/, view: AdministratorFormationGroupProperty, layout: MainLayout, roles: ['ROLE_ADMINISTRATOR'] },

    content_manager_formation_list: { path: '/content-manager/formation/list', view: ContentManagerFormationList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },
    content_manager_formation_question: { path: '/content-manager/formation/:formationId?/question/:id?', match: /\/content-manager\/formation-question.*/, view: ContentManagerQuestion, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },
    content_manager_formation: { path: '/content-manager/formation/:id?', match: /\/content-manager\/formation.*/, view: ContentManagerFormation, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },
    content_manager_chapter_list: { path: '/content-manager/chapter/list', view: ContentManagerChapterList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_chapter: { path: '/content-manager/chapter/:id?', match: /\/content-manager\/chapter.*/, view: ContentManagerChapter, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_course_list: { path: '/content-manager/course/list', view: ContentManagerCourseList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_course: { path: '/content-manager/course/:id?', match: /\/content-manager\/course.*/, view: ContentManagerCourse, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_resource_list: { path: '/content-manager/resource/list', view: ContentManagerResourceList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },

    content_manager_resource_type_list: { path: '/content-manager/resource/type/list', view: ContentManagerResourceTypeList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_resource_type: { path: '/content-manager/resource/type/:id?', match: /\/content-manager\/resource\/type.*/, view: ContentManagerResourceTypeList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },

    content_manager_virtual_classroom_period_list: { path: '/content-manager/virtual-classroom/period/list', view: ContentManagerVirtualClassroomPeriodList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },
    content_manager_virtual_classroom_period: { path: '/content-manager/virtual-classroom/period/:id?', match: /\/content-manager\/virtual-classroom\/period.*/, view: ContentManagerVirtualClassroomPeriodList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER'] },


    content_manager_resource_group_list: { path: '/content-manager/resource/group/list', view: ContentManagerResourceGroupList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },
    content_manager_resource_group: { path: '/content-manager/resource/group/:id?', match: /\/content-manager\/resource\/group.*/, view: ContentManagerResourceGroupList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },

    content_manager_resource: { path: '/content-manager/resource/:id?', match: /\/content-manager\/resource.*/, view: ContentManagerResource, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_CONTENT_MANAGER_FORMATION'] },
    content_manager_virtual_classroom_list: { path: '/content-manager/virtual-classroom/list', view: ContentManagerVirtualClassroomList, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_VIRTUAL_CLASSROOM_MANAGER'] },
    content_manager_virtual_classroom: { path: '/content-manager/virtual-classroom/:id?', match: /\/content-manager\/virtual-classroom.*/, view: ContentManagerVirtualClassroom, layout: MainLayout, roles: ['ROLE_CONTENT_MANAGER', 'ROLE_VIRTUAL_CLASSROOM_MANAGER'] },

    //marketing manager
    marketing_manager_thematic_list: { path: '/marketing-manager/thematic/list', view: MarketingManagerthematicList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_thematic: { path: '/marketing-manager/thematic/:id?', match: /\/marketing-manager\/thematic.*/, view: MarketingManagerthematic, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },

    marketing_manager_library_resource_list: { path: '/marketing-manager/library-resource/list', view: MarketingManagerLibraryResourceList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_library_resource_type_list: { path: '/marketing-manager/library-resource/type/list', view: MarketingManagerLibraryResourceTypeList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_featured_resource: { path: '/marketing-manager/featured-resource', view: MarketingManagerFeaturedResource, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_catalog_list: { path: '/marketing-manager/catalog/list', view: MarketingManagerCatalogList, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_hubspot_queue: { path: '/marketing-manager/hubspot-queue', view: MarketingManagerHubspotQueue, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },
    marketing_manager_stripe_promotion_code: { path: '/marketing-manager/stripe-promotion-code', view: StripePromotionCode, layout: MainLayout, roles: ['ROLE_MARKETING_MANAGER'] },


    financial_management_billing_v2_list: { path: '/financial-management/billing-v2/list', view: FinancialManagementBillingListV2, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_v2: { path: '/financial-management/billing-v2/:id?', match: /\/financial-management\/billing-v2.*/, view: FinancialManagementBillingV2, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },

    financial_management_billing_list: { path: '/financial-management/billing/list', view: FinancialManagementBillingList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_fifpl_list: { path: '/financial-management/billing-fifpl/list', view: FinancialManagementBillingFIFPLList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_cpf_list: { path: '/financial-management/billing-cpf/list', view: FinancialManagementBillingCPFList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing: { path: '/financial-management/billing/:id?', match: /\/financial-management\/billing.*/, view: FinancialManagementBilling, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_fifpl: { path: '/financial-management/billing-fifpl/:id?', match: /\/financial-management\/billing-fifpl.*/, view: FinancialManagementBillingFIFPL, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_cpf: { path: '/financial-management/billing-cpf/:id?', match: /\/financial-management\/billing-cpf.*/, view: FinancialManagementBillingCPF, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_payment_follow: { path: '/financial-management/payment-follow/list', view: FinancialManagementPaymentFollowList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_relaunch_list: { path: '/financial-management/relaunch/list', view: FinancialManagementRelaunchList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_statistics: { path: '/financial-management/statistics', view: FinancialManagementStatistics, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_andpc_email_tracking_list: { path: '/financial-management/andpc-email-tracking/list', view: FinancialManagementAndpcEmailTrackingList, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },
    financial_management_billing_personal: { path: '/financial-management/billing-personal', view: FinancialManagementBillingPERSONAL, layout: MainLayout, roles: ['ROLE_ACCOUNTANT'] },

    //community
    community_prospect_list: { path: '/community/prospect/list', view: CommunityProspectList, layout: MainLayout, roles: ['ROLE_COMMUNITY'] },
    community_group_private_list: { path: '/community/group/private/list', view: CommunityGroupPrivateList, layout: MainLayout, roles: ['ROLE_COMMUNITY'] },
    community_group_formation_list: { path: '/community/group/formation/list', view: CommunityGroupFormationList, layout: MainLayout, roles: ['ROLE_COMMUNITY'] },
    community_group_post: { path: '/community/group/:type?/:groupId?/post/:id?', match: /\/community\/group\/\d*\/post\/\d*/, view: CommunityGroupPost, layout: MainLayout, roles: ['ROLE_COMMUNITY'] },
    community_group: { path: '/community/group/:type?/:id?', match: /\/community\/group.*/, view: CommunityGroup, layout: MainLayout, roles: ['ROLE_COMMUNITY'] },

    support_prospect_hotline: { path: '/support/prospect-hotline', view: SupportProspectHotlineList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    support_user_hotline: { path: '/support/user-hotline', view: SupportUserHotlineList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    support_hotline_dashboard: { path: '/support/hotline-dashboard', view: SupportHotlineDashboard, layout: MainLayout, roles: ['ROLE_SUPPORT'] },

    // IT INFRA
    it_infrastructure_equipment_type_list: { path: '/it-infrastructure/equipment/type', view: ItInfrastructureEquipmentTypeList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    it_infrastructure_equipment_brand_list: { path: '/it-infrastructure/equipment/marque', view: ItInfrastructureEquipmentBrandList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    it_infrastructure_equipment_model_list: { path: '/it-infrastructure/equipment/model', view: ItInfrastructureEquipmentModelList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    it_infrastructure_equipment_list: { path: '/it-infrastructure/equipment/list', view: ItInfrastructureEquipmentList, layout: MainLayout, roles: ['ROLE_SUPPORT'] },
    it_infrastructure_equipment: { path: '/it-infrastructure/equipment/:id?', match: /\/it-infrastructure\/equipment.*/, view: ItInfrastructureEquipment, layout: MainLayout, roles: ['ROLE_SUPPORT'] },

    former_virtual_classroom_list: { path: '/former/virtual-classroom/list', view: FormerVirtualClassroomList, layout: MainLayout, roles: ['ROLE_FORMER'] },
    former_virtual_classroom_learners: { path: '/former/virtual-classroom/learners/:id?', match: /\/former\/virtual-classroom\/learners.*/, view: FormerVirtualClassroomLearners, layout: MainLayout, roles: ['ROLE_FORMER'] },
    former_virtual_classroom: { path: '/former/virtual-classroom/:id?', match: /\/former\/virtual-classroom.*/, view: FormerVirtualClassroom, layout: MainLayout, roles: ['ROLE_FORMER'] },

    // INSTITUTION HEALTH
    institution_health_home: { path: '/institution-health/home', view: InstitutionHealthHome, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION', 'ROLE_INSTITUTION_ADMIN'] },
    institution_health_investor: { path: '/institution-health/investor', view: InstitutionHealthInvestor, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_INVESTOR'] },

    institution_health_crm_institution_list: { path: '/institution-health/crm/institution/list', view: InstitutionHealthCRMInstitutionList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_registration_list: { path: '/institution-health/crm/institution/:institution/registration/list', match: /\/institution-health\/crm\/institution\/\d*\/registration\/list/, view: InstitutionHealthCRMRegistrationList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_group_list: { path: '/institution-health/crm/institution-group/list', view: InstitutionHealthCRMInstitutionGroupList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_group: { path: '/institution-health/crm/institution-group/:id?', match: /\/institution-health\/crm\/institution-group.*/, view: InstitutionHealthCRMInstitutionGroup, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_manager: { path: '/institution-health/crm/institution/:institution/institution-manager', match: /\/institution-health\/crm\/\d*\/institution-manager/, view: InstitutionHealthCRMInstitutionManager, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_employee_list: { path: '/institution-health/crm/institution/:institution/employee/list', match: /\/institution-health\/crm\/institution\/\d*\/employee\/list/, view: InstitutionHealthCRMInstitutionEmployeeList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution_employee: { path: '/institution-health/crm/institution/:institution/employee/:id?', match: /\/institution-health\/crm\/institution\/\d*\/employee.*/, view: InstitutionHealthCRMEmployee, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_institution: { path: '/institution-health/crm/institution/:institution?', match: /\/institution-health\/crm\/institution.*/, view: InstitutionHealthCRMInstitution, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_hotline_list: { path: '/institution-health/crm/hotline/list', view: InstitutionHealthCRMHotlineList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_relaunch_list: { path: '/institution-health/crm/relaunch/list', view: InstitutionHealthCRMRelaunchList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },
    institution_health_crm_dashboard_manager: { path: '/institution-health/crm/dashboard-manager', view: InstitutionHealthCRMDashboardManager, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CRM_ADMIN', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'] },

    institution_health_content_manager_formation_list: { path: '/institution-health/content-management/formation/list', view: InstitutionHealthContentManagerFormationList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_formation: { path: '/institution-health/content-management/formation/:id?', match: /\/institution-health\/content-management\/formation.*/, view: InstitutionHealthContentManagerFormation, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_formation_question: { path: '/institution-health/content-management/formation/:formationId?/question/:id?', match: /\/institution-health\/content-management\/formation-question.*/, view: InstitutionHealthContentManagerQuestion, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_chapter_list: { path: '/institution-health/content-management/chapter/list', view: InstitutionHealthContentManagerChapterList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_chapter: { path: '/institution-health/content-management/chapter/:id?', match: /\/institution-health\/content-management\/chapter.*/, view: InstitutionHealthContentManagerChapter, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_course_list: { path: '/institution-health/content-management/course/list', view: InstitutionHealthContentManagerCourseList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_course: { path: '/institution-health/content-management/course/:id?', match: /\/institution-health\/content-management\/course.*/, view: InstitutionHealthContentManagerCourse, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_resource_list: { path: '/institution-health/content-management/resource/list', view: InstitutionHealthContentManagerResourceList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },

    institution_health_content_manager_resource_type_list: { path: '/institution-health/content-management/resource/type/list', view: InstitutionHealthContentManagerResourceTypeList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_resource_type: { path: '/institution-health/content-management/resource/type/:id?', match: /\/institution-health\/content-management\/resource\/type.*/, view: InstitutionHealthContentManagerResourceTypeList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_resource_group_list: { path: '/institution-health/content-management/resource/group/list', view: InstitutionHealthContentManagerResourceGroupList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_resource_group: { path: '/institution-health/content-management/resource/group/:id?', match: /\/institution-health\/content-management\/resource\/group.*/, view: InstitutionHealthContentManagerResourceGroupList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_resource: { path: '/institution-health/content-management/resource/:id?', match: /\/institution-health\/content-management\/resource.*/, view: InstitutionHealthContentManagerResource, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },

    institution_health_content_manager_virtual_classroom_period_list: { path: '/institution-health/content-management/virtual-classroom/period/list', view: InstitutionHealthContentManagerVirtualClassroomPeriodList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_virtual_classroom_period: { path: '/institution-health/content-management/virtual-classroom/period/:id?', match: /\/institution-health\/content-management\/virtual-classroom\/period.*/, view: ContentManagerVirtualClassroomPeriodList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'] },
    institution_health_content_manager_virtual_classroom_list: { path: '/institution-health/content-management/virtual-classroom/list', view: InstitutionHealthContentManagerVirtualClassroomList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CONTENT_MANAGER', 'ROLE_INSTITUTION_VIRTUAL_CLASSROOM_MANAGER'] },
    institution_health_content_manager_virtual_classroom: { path: '/institution-health/content-management/virtual-classroom/:id?', match: /\/institution-health\/content-management\/virtual-classroom.*/, view: InstitutionHealthContentManagerVirtualClassroom, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_CONTENT_MANAGER', 'ROLE_INSTITUTION_VIRTUAL_CLASSROOM_MANAGER'] },

    institution_health_financial_management_billing_institution_mixte_list: { path: '/institution-health/financial-management/billing/institution/mixte/list', view: InstitutionHealthFinancialManagementBillingInstitutionMixteList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_institution_personal_list: { path: '/institution-health/financial-management/billing/institution/personal/list', view: InstitutionHealthFinancialManagementBillingInstitutionPersonalList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_institution_invoice_list: { path: '/institution-health/financial-management/billing/institution/:institution/invoice/list', match: /\/institution-health\/financial\/billing\/institution\/\d*\/invoice\/list/, view: InstitutionHealthFinancialManagementBillingInstitutionInvoiceList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_institution_invoice: { path: '/institution-health/financial-management/billing/institution/:institution/invoice/:id?', match: /\/institution-health\/financial-management\/billing\/institution\/\d*\/invoice.*/, view: InstitutionHealthFinancialManagementBillingInstitutionInvoice, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_institution_invoice_personal_list: { path: '/institution-health/financial-management/billing/institution/:institution/invoice-personal/list', match: /\/institution-health\/financial-management\/billing\/institution\/\d*\/invoice-personal\/list/, view: InstitutionHealthFinancialManagementBillingInstitutionInvoicePersonalList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_institution_invoice_personal: { path: '/institution-health/financial-management/billing/institution/:institution/invoice-personal/:id?', match: /\/institution-health\/financial-management\/billing\/institution\/\d*\/invoice-personal.*/, view: InstitutionHealthFinancialManagementBillingInstitutionInvoicePersonal, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },

    institution_health_financial_management_billing_formation_list: { path: '/institution-health/financial-management/billing/formation/list', view: InstitutionHealthFinancialManagementBillingFormationList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },
    institution_health_financial_management_billing_formation: { path: '/institution-health/financial-management/billing/formation/:id', match: /\/institution-health\/financial-management\/billing\/formation.*/, view: InstitutionHealthFinancialManagementBillingFormation, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },

    institution_health_financial_management_billing: { path: '/institution-health/financial-management/billing/invoice/list', match: /\/institution-health\/financial-management\/billing\/invoice.*/, view: InstitutionHealthFinancialManagementBilling, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ACCOUNTANT'] },

    institution_health_marketing_manager_thematic_list: { path: '/institution-health/marketing-manager/thematic/list', view: InstitutionHealthMarketingManagerthematicList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_MARKETING_MANAGER'] },
    institution_health_marketing_manager_library_resource_list: { path: '/institution-health/marketing-manager/library-resource/list', view: InstitutionHealthMarketingManagerLibraryResourceList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_MARKETING_MANAGER'] },
    institution_health_marketing_manager_library_resource_type_list: { path: '/institution-health/marketing-manager/library-resource/type/list', view: InstitutionHealthMarketingManagerLibraryResourceTypeList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_MARKETING_MANAGER'] },
    institution_health_marketing_manager_featured_resource: { path: '/institution-health/marketing-manager/featured-resource', view: InstitutionHealthMarketingManagerFeaturedResource, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_MARKETING_MANAGER'] },
    institution_health_marketing_manager_thematic: { path: '/institution-health/marketing-manager/thematic/:id?', match: /\/institution-health\/marketing-manager\/thematic.*/, view: InstitutionHealthMarketingManagerthematic, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_MARKETING_MANAGER'] },

    institution_health_admin_user_list: { path: '/institution-health/admin/user/list', view: InstitutionHealthAdministratorUserList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMINISTRATOR'] },
    institution_health_admin_user: { path: '/institution-health/admin/user/:id?', match: /\/institution-health\/admin\/user.*/, view: InstitutionHealthAdministratorUser, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_ADMINISTRATOR', 'ROLE_USER'] },
    institution_health_admin_session_list: { path: '/institution-health/admin/session/list', view: InstitutionHealthAdministratorSessionList, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_ADMINISTRATOR'] },
    institution_health_admin_session: { path: '/institution-health/admin/session/:id?', match: /\/institution-health\/admin\/session.*/, view: InstitutionHealthAdministratorSession, layout: MainLayoutInstitution, roles: ['ROLE_INSTITUTION_ADMIN', 'ROLE_INSTITUTION_ADMINISTRATOR'] },

    about_update: { path: '/about/update', view: AboutUpdate, layout: MainLayout, roles: ['ROLE_USER'] },
    about_roadmap_si: { path: '/about/roadmap-si', view: AboutRoadmapSI, layout: MainLayout, roles: ['ROLE_USER'] },
    about_roadmap_ped: { path: '/about/roadmap-ped', view: AboutRoadmapPed, layout: MainLayout, roles: ['ROLE_USER'] },

    // LEARNER
    learner_home: { path: '/learner/home', view: LearnerHome, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_institution_list: { path: '/learner/institution/list', view: LearnerInstitutionList, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_institution_employee_list: { path: '/learner/institution/:institution/employee/list', match: /\/learner\/institution\/\d*\/employee\/list/, view: LearnerInstitutionEmpoyeeList, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_institution_registration_list: { path: '/learner/institution/:institution/registration/list', match: /\/learner\/institution\/\d*\/registration\/list/, view: LearnerInstitutionRegistrationList, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_institution_manager: { path: '/learner/institution/:institution?', match: /\/learner\/institution\/\d*/, view: LearnerInstitutionManager, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_course: { path: '/learner/registration/:registration/course/:course?', match: /\/learner\/registration\/\d*\/course\/\d*/, view: LearnerRegistrationCourse, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_survey_satisfaction: { path: '/learner/registration/:registration/survey-satisfaction', match: /\/learner\/registration\/\d*\/survey-satisfaction/, view: LearnerRegistrationSurveySatisfaction, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_survey_pre_post: { path: '/learner/registration/:registration/survey-pre-post/:type?', match: /\/learner\/registration\/\d*\/survey-pre-post\/\w*/, view: LearnerRegistrationSurveyPrePost, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_survey_epp: { path: '/learner/registration/:registration/survey-epp/:type?', match: /\/learner\/registration\/\d*\/survey-epp\/\w*/, view: LearnerRegistrationSurveyEpp, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_virtual_classroom: { path: '/learner/registration/:registration/virtual-classroom/:virtualClassroom?', match: /\/learner\/registration\/\d*\/virtual-classroom\/\d*/, view: LearnerRegistrationVirtualClassroom, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_virtual_classroom_replay: { path: '/learner/registration/:registration/virtual-classroom-replay/:virtualClassroom?', match: /\/learner\/registration\/\d*\/virtual-classroom-replay\/\d*/, view: LearnerRegistrationVirtualClassroomReplay, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_resource_type_resource: { path: '/learner/resource-type/:id?/resource/:resource?', match: /\/learner\/resource-type\/\d*\/resource\/\w*/, view: LearnerResource, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_resource_type: { path: '/learner/resource-type/:id?', match: /\/learner\/resource-type.*/, view: LearnerResourceType, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_library_resource: { path: '/learner/library-resource', view: LearnerLibraryResource, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_catalog: { path: '/learner/catalog', view: LearnerCatalog, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_register: { path: '/learner/register/:id?', match: /\/learner\/register.*/, view: LearnerRegister, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_community: { path: '/learner/community', view: LearnerCommunity, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_community_group: { path: '/learner/community-group/:communityGroup?', match: /\/learner\/community-group\/\d*/, view: LearnerCommunityGroup, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_community_post: { path: '/learner/group/:communityGroup/post/:communityPost?', match: /\/learner\/community-group\/\d*\/post\/\w*/, view: LearnerCommunityPost, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_my_info: { path: '/learner/my-info', view: LearnerMyInfo, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_document: { path: '/learner/document', view: LearnerDocument, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration_resource_course: { path: '/learner/registration/:registration/resource/:resource/course/:course?', match: /\/learner\/registration\/\d*\/resource\/\d*\/course\/\d*/, view: LearnerRegistrationResourceCourse, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_registration: { path: '/learner/registration/:id?', match: /\/learner\/registration\/\d*$/, view: LearnerRegistration, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },
    learner_invoice: { path: '/learner/invoice', view: LearnerInvoice, layout: LearnerLayoutV2, roles: ['ROLE_LEARNER'] },

    // STRIPE
    payment_stripe: { path: '/stripe/payment', match: /\/stripe\/payment/, view: PaymentStripe, layout: AuthenticationLayout, roles: [] },
    payment_stripe: { path: '/stripe/payment', match: /\/stripe\/payment/, view: PaymentStripe, layout: AuthenticationLayout, roles: ['ROLE_LEARNER'] },
    payment_stripe_success: { path: '/stripe/success', match: /\/stripe\/success/, view: PaymentStripeSuccess, layout: AuthenticationLayout, roles: [] },
    payment_stripe_success: { path: '/stripe/success', match: /\/stripe\/success/, view: PaymentStripeSuccess, layout: AuthenticationLayout, roles: ['ROLE_LEARNER'] },

    // ENROLLMENT
    enrollment_register: { path: '/enrollment/register', match: /\/enrollment\/register/, view: EnrollmentRegister, layout: AuthenticationLayout, roles: [] },

    // ANONYMOUS
    authentication_admin: { path: '/authentication/admin/:code?', view: Login, layout: AuthenticationLayout, roles: [] },
    authentication_login: { path: '/authentication/login/:code?', view: Login, layout: AuthenticationLayout, roles: [] },
    authentication_register: { path: '/authentication/register', view: Register, layout: AuthenticationLayout, roles: [] },
    maintenance: { path: '/maintenance', view: Maintenance, layout: AuthenticationLayout, roles: [] },
};

let routes = {};
let defaultPath = '';

// Security
export const updateRouting = (authenticationReducer, loginPage) => {
    let routesTmp = {};
    let urlOrigin = localStorage.getItem('urlOrigin');

    if (authenticationReducer && Object.entries(authenticationReducer).length > 0) { // Login
        for (let index in routing) {
            if (authenticationReducer.roles && (authenticationReducer.roles.includes('ROLE_SUPER_ADMIN') || authenticationReducer.roles.filter(value => routing[index].roles.includes(value)).length > 0)) {
                if (!(authenticationReducer.roles.includes('ROLE_SUPER_ADMIN') && index.startsWith("learner_"))) {
                    routesTmp[index] = routing[index];
                }
            }
            if (index.startsWith("learner_institution_") && !authenticationReducer.isHolder) delete routesTmp[index];
            if (index.startsWith("learner_catalog") && authenticationReducer.isInstituOrganism) delete routesTmp[index];
        }

        delete routesTmp.authentication_login;
        delete routesTmp.authentication_admin;
        delete routesTmp.authentication_register;
        delete routesTmp.enrollment_register;
        delete routesTmp.maintenance;

        if (authenticationReducer.defaultRoute && routesTmp[authenticationReducer.defaultRoute]) {
            defaultPath = routesTmp[authenticationReducer.defaultRoute].path;
        }
        else {
            if (Object.keys(routesTmp)[0]) {

                defaultPath = routesTmp[Object.keys(routesTmp)[0]].path
            }
            else { // Logout

                routesTmp.authentication_login = routing['authentication_login'];
                routesTmp.authentication_admin = routing['authentication_admin'];
                routesTmp.authentication_register = routing['authentication_register'];
                routesTmp.enrollment_register = routing['enrollment_register'];
                routesTmp.payment_stripe_success = routing['payment_stripe_success'];
                routesTmp.payment_stripe = routing['payment_stripe'];
                routesTmp.maintenance = routing['maintenance'];
                defaultPath = '/authentication/login';
            }
        }

        let routeGet = getRouteByPathnameOnRoutesTmp(routesTmp, urlOrigin);
        if (urlOrigin && Object.keys(routeGet).length != 0) {
            if (urlOrigin !== '/stripe/success') {
                defaultPath = urlOrigin;
                localStorage.removeItem('urlOrigin');
            }
        }
    }
    else { // Logout

        routesTmp.authentication_login = routing['authentication_login'];
        routesTmp.authentication_admin = routing['authentication_admin'];
        routesTmp.authentication_register = routing['authentication_register'];
        routesTmp.enrollment_register = routing['enrollment_register'];
        routesTmp.payment_stripe_success = routing['payment_stripe_success'];
        routesTmp.payment_stripe = routing['payment_stripe'];
        routesTmp.maintenance = routing['maintenance'];
        defaultPath = loginPage.page === 'admin' ? '/authentication/admin' : '/authentication/login';
    }

    routes = routesTmp;
};

export const getRoutes = () => {
    return routes;
};

// Dependency Security
export const getRoute = (id) => {
    let routes = getRoutes();
    let route = routes[id];
    return route ? { ...route, id: id } : {};
};

export const getPathDefault = () => {
    return defaultPath;
};

export const getRoutePathname = (id, params = []) => {
    let route = getRoute(id);
    let path = route.path ?? '/';

    for (let index in params) {
        let regex = new RegExp('/:' + index + '[?]{0,1}', 'gi')
        path = path.replace(regex, params[index] ? '/' + params[index] : '');
    }

    return path;
};

export const getRouteByPathname = (pathname) => {
    let routes = getRoutes();
    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return { ...routes[index], id: index };
        }
        else if (routes[index].path === pathname) {
            return { ...routes[index], id: index };
        }
    }

    return {};
};

export const getRouterLayout = () => {
    let routes = getRoutes();
    let routerLayout = {};

    for (let index in routes) {
        if (!routerLayout[routes[index].layout.name]) {
            routerLayout[routes[index].layout.name] = [];
        }
        routerLayout[routes[index].layout.name].push(routes[index])
    }

    return routerLayout;
};

const getRouteByPathnameOnRoutesTmp = (router, pathname) => {
    let routes = router;

    for (let index in routes) {
        if (routes[index].match && routes[index].match.test(pathname)) {
            return { ...routes[index], id: index };
        }
        else if (routes[index].path === pathname) {
            return { ...routes[index], id: index };
        }
    }
    return {};
};
