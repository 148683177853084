import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Drawer as DrawerMUI, Fade, Grid, Slide, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import CardPersonalComponent from './Component/CardPersonalComponent';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import moment from 'moment';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import FilterPaginationComponent from '../../../../Component/FilterPaginationComponent';
import ViewRegistrationInvoicesComponent from './Component/ViewRegistrationInvoicesComponent';

export default function Invoice(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [showConfirmEdit, setShowConfirmEdit] = React.useState(false);
    const [showConvention, setShowConvention] = React.useState(false);
    const [isOpenDiscount, setIsOpenDiscount] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isScreen, setIsScreen] = React.useState(true);
    const [registrationList, setRegistrationList] = React.useState([]);
    const [isActiveBilled, setIsActiveBilled] = React.useState(false);
    const [isConvention, setIsConvention] = React.useState(false);
    const [institution, setInstitution] = React.useState([]);
    const [billingList, setBillingList] = React.useState([]);
    const [errorCard, setErrorCard] = React.useState(false);
    const [isBilled, setIsBilled] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [restCredit, setRestCredit] = React.useState(0);
    const [credit, setCredit] = React.useState(0);
    const [destinationType, setDestinationType] = React.useState(1);
    const [registrationData, setRegistrationData] = React.useState(null);
    const [errorCredit, setErrorCredit] = React.useState({
        isError: false,
        text: ''
    });
    const [error, setError] = React.useState({
        isError: false,
        text: ''
    });

    const [formDeposit, setFormDeposit] = React.useState({
        depositAmount: {
            name: 'depositAmount',
            label: 'Acompte versé',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        credit: {
            name: 'credit',
            label: 'Solde',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
    });
    const handlerDeposit = formHandlerInit(formDeposit, setFormDeposit);
    React.useEffect(handlerDeposit.start, []);

    const [form, setForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const addRegistration = (data) => {
        setBillingList((prev) => {
            if (prev.length != 0) {
                setRegistrationList((prevR) => { return [...prevR, prev[0]] });
                const newPrev = prev.filter(item => item.id !== prev[0].id);
                return [...newPrev, data];
            } else return [...prev, data];
        });
    };

    const removeRegistration = (data) => {
        setBillingList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });

        setRegistrationList((prev) => {
            return [...prev, data];
        });
    };
    const viewRegistration = (data) => {
        return (
            <>
                <Fade in={true} {...{ timeout: 1000 }}>
                    <Box style={{ background: '#FFF', width: 220, borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateRows: 'max-content auto max-content' }}>
                        <Box style={{ padding: '10px 15px', borderBottom: '1px solid #A3ADB7' }}>
                            <p style={{ margin: 0, fontSize: 12 }}>Nom/Prénom</p>
                            <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{`${data.lastname ?? ""} ${data.firstname ?? ""}`}</p>
                        </Box>
                        <Box style={{ padding: '10px 15px 10px' }}>
                            <p style={{ margin: 0, fontSize: 12 }}>Formation :</p>
                            <p style={{ margin: 0, fontWeight: 'bold', fontSize: 14 }}>{data.formationName ?? '-'}</p>
                            <p style={{ margin: '10px 0', fontSize: 12 }}>Session :<br />
                                <span style={{ margin: 0, fontSize: 13, fontWeight: 'bold' }}>{moment(data.sessionStartAt.date).format('ll')} au {moment(data.sessionEndAt.date).format('ll')}</span>
                            </p>
                            <p style={{ margin: 0, fontSize: 12 }} >Financement - <b>{data.financeTypeName ?? '-'}</b></p>
                        </Box>
                        {!props.match.params.id && <ButtonComponent
                            style={{ width: '90%', margin: '0 auto 10px auto', display: 'block' }}
                            onClick={() => { addRegistration(data) }}
                            color='#5E6E82'
                            label={(Object.keys(handler.form).length === 0) ? <>Ajouter</> : <>Échanger</>}
                            disabled={loading || isBilled}
                        />}
                    </Box>
                </Fade>
            </>
        )
    }

    const getActionMore = () => {
        return (
            props.match.params.id ?
                <Box style={{ display: 'flex', gap: 12 }}>
                    <ButtonComponent
                        color={"#5E6E82"}
                        onClick={() => { download() }}
                        label={<>Télécharger le devis</>}
                        disabled={(Object.keys(handler.form).length === 0) || institution.cancelAt || errorCard}
                    />
                    {!isBilled ?
                        <>
                            <ButtonComponent
                                color={"#5E6E82"}
                                onClick={() => { setShowConfirmEdit(true) }}
                                label={<>Enregistrer</>}
                                disabled={(Object.keys(handler.form).length === 0) || institution.cancelAt || errorCard}
                            />
                            <Tooltip title={isActiveBilled ? 'Des modifications ont été effectuées.' : ''} placement={"bottom"}>
                                <div>
                                    <ButtonComponent
                                        onClick={() => { setDialog(true) }}
                                        label={<>Devis signé</>}
                                        disabled={(Object.keys(handler.form).length === 0) || institution.cancelAt || errorCard || isActiveBilled}
                                    />
                                </div>
                            </Tooltip>
                        </> :
                        <ButtonComponent
                            onClick={() => { setDialog(true) }}
                            label={<>Facture total</>}
                            disabled={billingList[0] && billingList[0]?.paymentData?.toBill === 0}
                        />
                    }
                </Box> :
                <Box style={{ display: 'flex', gap: 12 }}>
                    <ButtonComponent
                        color={"#5E6E82"}
                        onClick={() => { save() }}
                        label={<>Enregistrer le devis</>}
                        disabled={(Object.keys(handler.form).length === 0) || errorCard}
                    />
                </Box>
        )
    }

    const getInstitution = (id) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        if (props.match.params.institution) {
            Api.get({
                route: 'institution_health_financial_management_billing_institution_invoice_get',
                data: { contractId: id ?? null, isPersonal: true },
                params: { institution: props.match.params.institution }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                if (response.status === 200) {
                    let data = response.data;
                    setInstitution(data);
                    setRegistrationList((data.registrations && data.registrations.length != 0) ? data.registrations : []);
                    setBillingList((data.registrationsBilled && data.registrationsBilled.length != 0) ? data.registrationsBilled : []);
                    setRestCredit(data.creditBalance ?? 0);
                    setIsBilled(false);
                    setIsOpen(true);
                } else if (response.status === 409) {
                    dispatch(LinearProgressMainLayoutActivate());
                    setLoading(true);
                    getInfoContract(id);
                } else {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_personal_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                        })
                    );
                }
            })
        }
    }

    const getInfoContract = (id) => {
        Api.get({
            route: 'institution_health_financial_management_billing_institution_estimation_info',
            params: { institution: props.match.params.institution, contractId: id }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            setLoading(false);
            if (response.status === 200) {
                let data = response.data;
                setInstitution(data);
                setIsBilled(true);
                setIsOpen(true);
                setRestCredit(data.creditBalance ?? 0);
                setIsConvention(data.isConvention)
                setBillingList((data.registrationsBilled && data.registrationsBilled.length != 0) ? data.registrationsBilled : []);
            } else {
                props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal_list', { institution: props.match.params.institution }));
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'warning',
                    })
                );
            }
        });
    }

    const newData = () => {
        let creditValue = null;
        let depositValue = null;
        let tvaValue = null;
        let tvaAmountValue = null;
        let amountHtValue = null;
        let amountTtcValue = null;
        let remainTtcValue = null;
        let destinationTypeValue = null;

        const newHandler = Object.keys(handler.form).reduce((acc, handlerData) => {
            const { registrationId, value, isPercentage } = handler.form[handlerData];
            const fieldKey = handlerData.split('-').slice(1).join('-');

            if (!acc[registrationId]) acc[registrationId] = {};

            acc[registrationId][fieldKey] = isPercentage !== undefined
                ? { value: value ?? null, isPercentage }
                : value ?? null;

            if (fieldKey === 'tva') tvaValue = value ?? null;
            if (fieldKey === 'tvaAmount') tvaAmountValue = value ?? null;
            if (fieldKey === 'remainHt') amountHtValue = value ?? null;
            if (fieldKey === 'amountTtc') amountTtcValue = value ?? null;
            if (fieldKey === 'remainTtc') remainTtcValue = value ?? null;
            if (fieldKey === 'credit') creditValue = value ?? null;
            if (fieldKey === 'deposit') depositValue = value ?? null;
            if (fieldKey === 'destinationType') destinationTypeValue = value ?? null;

            delete acc[registrationId].tva;
            delete acc[registrationId].tvaAmount;
            delete acc[registrationId].remainTtc;
            delete acc[registrationId].credit;
            delete acc[registrationId].deposit;
            delete acc[registrationId].destinationType;

            return acc;
        }, {});

        return {
            invoiceRegistrations: newHandler,
            deposit: 30,
            destinationType: destinationTypeValue,
            depositAmount: depositValue,
            amountHt: amountHtValue,
            amountTtc: amountTtcValue,
            tva: tvaValue,
            tvaAmount: tvaAmountValue,
            remainTtc: remainTtcValue,
            credit: creditValue,
            contractId: props.match.params.id ?? null,
            isPersonal: true
        };
    };

    const saveBilled = () => {
        let destinationTypeValue = null;
        Object.keys(handler.form).reduce((acc, handlerData) => {
            const { registrationId, value } = handler.form[handlerData];
            const fieldKey = handlerData.split('-').slice(1).join('-');
            if (!acc[registrationId]) acc[registrationId] = {};
            if (fieldKey === 'destinationType') destinationTypeValue = value ?? null;
            return acc;
        }, {});
        let remainTtc = (billingList[0] ? billingList[0]?.paymentData?.toBill : 0) - handlerDeposit?.form.credit.value
        const data = {
            amountTtc: (billingList[0] ? billingList[0]?.paymentData?.toBill : 0),
            destinationType: destinationTypeValue,
            remainTtc: remainTtc.toFixed(2),
            contractId: props.match.params.id,
            credit: destinationTypeValue === 1 ? handlerDeposit?.form.credit.value : '',
        }

        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_institution_invoice_rest_add',
            data: data,
            params: { institution: props.match.params.institution }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            setLoading(false);
            getInstitution(props.match.params.id)
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Paiement total effectué.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                setLoading(true);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                setLoading(true);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const saveDeposit = () => {
        let amountTtcValue = null;
        let destinationTypeValue = null;
        let remainTtcValue = null;
        Object.keys(handler.form).reduce((acc, handlerData) => {
            const { registrationId, value, isPercentage } = handler.form[handlerData];
            const fieldKey = handlerData.split('-').slice(1).join('-');

            if (!acc[registrationId]) acc[registrationId] = {};

            acc[registrationId][fieldKey] = isPercentage !== undefined
                ? { value: value ?? null, isPercentage }
                : value ?? null;

            if (fieldKey === 'destinationType') destinationTypeValue = value ?? null;
            if (fieldKey === 'amountTtc') amountTtcValue = value ?? null;
            if (fieldKey === 'remainTtc') remainTtcValue = value ?? null;

            return acc;
        }, {});
        const data = {
            amountTtc: amountTtcValue,
            destinationType: destinationTypeValue,
            remainTtc: remainTtcValue,
            contractId: props.match.params.id,
            depositAmount: handlerDeposit?.form.depositAmount.value,
            credit: destinationTypeValue === 1 ? handlerDeposit?.form.credit.value : '',
        }

        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_institution_invoice_deposit_add',
            data: data,
            params: { institution: props.match.params.institution }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            setLoading(false);
            getInstitution(props.match.params.id);
            handlerDeposit.reset();
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Paiement reçu.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                setLoading(true);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                setLoading(true);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const save = () => {
        if (handler.checkError() || (Object.keys(handler.form).length === 0)) {
            console.log('error');
            dispatch(
                SnackbarOpen({
                    text: 'Veuillez entrer tous les champs.',
                    variant: 'error',
                })
            );
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            const data = newData();
            if (props.match.params.id) {
                Api.post({
                    route: 'institution_health_financial_management_billing_institution_contract_edit',
                    data: data,
                    params: { institution: props.match.params.institution, contractId: props.match.params.id }
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    getInstitution(props.match.params.id)
                    if (response.status === 200) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Devis modifiée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                })
            } else {
                Api.post({
                    route: 'institution_health_financial_management_billing_institution_contract_add',
                    data: data,
                    params: { institution: props.match.params.institution }
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        let data = response.data;
                        getInstitution(data.contractId)
                        props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal', { institution: props.match.params.institution, id: data.contractId }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Devis enregistrée',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const saveConvention = (isConvention) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_convention',
            params: { institution: props.match.params.institution, contractId: props.match.params.id },
            data: { isConvention: !isConvention, isPersonal: true }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const download = () => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_download',
            data: { type: 1 },
            params: { institution: props.match.params.institution, id: props.match.params.id },
        });
        window.open(url, '_blank');
    };

    function parseDiscount(discount) {
        if (typeof discount === 'string') {
            if (discount.includes('%')) {
                return { value: Number(discount.replace('%', '')), isPercentage: true };
            } else if (discount.includes('€')) {
                return { value: Number(discount.replace('€', '')), isPercentage: false };
            }
        }
        return { value: discount ? Number(discount) : '', isPercentage: false };
    }

    const description = () => {
        const descriptions = {
            1: { text: 'Facture | Annuler', background: '#DD4E4E' },
            2: { text: 'Facture | payé', background: '#28a745' },
            3: { text: 'Facture | non payé', background: '#E6A731' },
            4: { text: 'Facture | modifier', background: '#007bff' },
            default: { text: 'En attente de signature...', background: '#007bff' }
        };

        const conditions = [
            { condition: institution.cancelAt, type: 1 },
            { condition: isBilled && institution?.firstInvoice?.isPaid, type: 2 },
            { condition: isBilled && !institution?.firstInvoice?.isPaid, type: 3 },
            { condition: institution.isTreated, type: 4 }
        ];

        const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
        const { text: description, background } = descriptions[descriptionType];

        return (
            props.match.params.id ?
                <span className={classes.statsStyle}>
                    {isBilled ? 'Devis' : 'Édition du devis'}
                    <span style={{ borderRadius: 5, background: background }}>
                        {description}
                    </span>
                </span> :
                <span>Création d'un devis</span>
        );
    }

    const handleDepositAmountChange = (event) => {
        if (parseFloat(institution.amountTtc) < parseFloat(event)) {
            console.log('error');
            setError({
                isError: true,
                text: 'Attention, l\'acompte ne doit pas dépasser le net à payer.'
            });
        } else {
            setError({
                isError: false,
                text: ''
            });
        }
    }

    React.useEffect(() => {
        if (billingList.length !== 0) {
            setForm((prevData) => {
                const newData = billingList.reduce((acc, registration) => {
                    const keyEF = `${registration.id}-educationalFeeHt`;
                    const keyED = `${registration.id}-educationalDiscount`;
                    const keyDestination = `${registration.id}-destinationType`;
                    const keyIF = `${registration.id}-inscriptionFeeHt`;
                    const keyID = `${registration.id}-inscriptionDiscount`;
                    const keyTAX = `${registration.id}-tva`;
                    const keyCredit = `${registration.id}-credit`;
                    const keyComment = `${registration.id}-comment`;

                    let educationalDiscount = parseDiscount(registration.educationalDiscount);
                    let inscriptionDiscount = parseDiscount(registration.inscriptionDiscount);

                    acc[keyIF] = {
                        name: keyIF,
                        label: 'Frais d’inscription',
                        type: 'float',
                        value: (registration.inscriptionFeeHt && registration.inscriptionFeeHt != 0) ? registration.inscriptionFeeHt : '0',
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };

                    acc[keyID] = {
                        name: keyID,
                        label: 'Remise frais d’inscription',
                        type: 'float',
                        value: inscriptionDiscount.value ?? '',
                        registrationId: registration.id,
                        isPercentage: inscriptionDiscount.isPercentage,
                        options: {}
                    };

                    acc[keyEF] = {
                        name: keyEF,
                        label: 'Coût pédagogique',
                        type: 'float',
                        value: (registration.educationalFeeHt && registration.educationalFeeHt != 0) ? registration.educationalFeeHt : '0',
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };

                    acc[keyED] = {
                        name: keyED,
                        label: 'Remise coût pédagogique',
                        type: 'float',
                        value: educationalDiscount.value ?? '',
                        registrationId: registration.id,
                        isPercentage: educationalDiscount.isPercentage,
                        options: {}
                    };

                    acc[keyDestination] = {
                        name: keyDestination,
                        label: 'Destination',
                        type: 'integer',
                        value: institution.firstInvoice?.destinationType ? institution.firstInvoice.destinationType : institution.destinationType ?? 1,
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };

                    acc[keyTAX] = {
                        name: keyTAX,
                        label: 'Tva',
                        type: 'float',
                        value: (institution.tva && institution.tva != 0) ? institution.tva : '0',
                        registrationId: registration.id,
                        options: {}
                    };

                    acc[keyComment] = {
                        name: keyComment,
                        label: 'Description remise',
                        type: 'text',
                        value: registration.comment ?? '',
                        registrationId: registration.id,
                        options: {}
                    };

                    acc[keyCredit] = {
                        name: keyCredit,
                        label: 'Utiliser',
                        type: 'float',
                        value: institution.credit ?? '0',
                        registrationId: registration.id,
                        options: {}
                    };

                    return acc;
                }, {});

                const mergedData = { ...prevData };
                Object.keys(newData).forEach(key => {
                    mergedData[key] = {
                        ...newData[key],
                        ...prevData[key]
                    };
                });

                return mergedData;
            });

            setRegistrationList((prev) => {
                return prev.filter(item => !billingList.some(reg => reg.id === item.id));
            });

        }
        setForm((prev) => {
            const result = {};
            billingList.forEach(item => {
                const registrationId = item.id.toString();
                for (const key in prev) {
                    if (prev[key].registrationId.toString() === registrationId) {
                        result[key] = prev[key];
                    }
                }
            });
            return result;
        });

        setRegistrationData({
            id: billingList[0]?.registrationId ?? billingList[0]?.id,
            prospectName: `${billingList[0]?.prospectName ?? ''}`
        })
    }, [billingList]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: institution.name ? institution.name : '-',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: description(),
            links: props.match.params.id ? [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_personal_list'), label: 'Facturation établissement' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal_list', { institution: props.match.params.institution }), label: 'Liste des devis' }
            ] : [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_personal_list'), label: 'Facturation établissement' },
            ],
            html: getActionMore()
        });
    }, [institution, form, isBilled, billingList, errorCard, isActiveBilled, isConvention]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    React.useEffect(() => {
        if ((credit > institution.creditBalance) && institution.creditBalance) {
            setErrorCredit({
                isError: true,
                text: <>Attention, vous avez dépassé le montant disponibles. Le montant est actuellement à <b>{institution.creditBalance} €</b></>
            });
        } else if (parseFloat(credit) > parseFloat(!isBilled ? handlerDeposit.form.depositAmount.value : (billingList[0] ? billingList[0]?.paymentData?.toBill : 0))) {
            setErrorCredit({
                isError: true,
                text: <>Attention, le montant actuel de la facture est de <b>{!isBilled ? handlerDeposit.form.depositAmount.value : (billingList[0] ? billingList[0]?.paymentData?.toBill : 0)} €</b>. Par conséquent, le montant utilisé sera identique.</>
            });
        } else setErrorCredit({
            isError: false,
            text: ``
        });
    }, [credit, institution, handlerDeposit.form.depositAmount.value, billingList]);

    React.useEffect(() => {
        getInstitution(props.match.params.id);
    }, [props.match.params.id]);

    return (
        <>
            <Box className={classes.styleContentView}>
                <DrawerMUI
                    variant="permanent"
                    className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                    style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                    open={isOpen}>
                    {(isBilled || institution.isTreated) ?
                        <Box style={{ margin: 30, display: 'grid', gridTemplateRows: 'max-content auto', gap: 10, color: '#5E6E82', height: '100%' }}>
                            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content max-content', alignItems: 'center', gap: 10, justifyContent: 'space-between' }}>
                                <h1 style={{ fontSize: 15, margin: 0 }}>Référence:<br /><span style={{ fontSize: 13, fontWeight: 'initial' }} >{institution.estimationLabel ?? institution.label}</span></h1>
                                <ButtonComponent
                                    onClick={() => { setShowConvention(true) }} label={isConvention ? 'Annuler la Convention' : 'Valider la Convention'}
                                    color={isConvention ? '#28a745' : '#DD4E4E'}
                                    disabled={loading || institution.cancelAt || !isBilled}
                                />
                            </Box>
                            <ViewRegistrationInvoicesComponent getInstitution={getInstitution} institution={institution} isPersonal={true} loading={loading} registration={registrationData} {...props} />
                        </Box>
                        :
                        <>
                            {(props.match.params.id) &&
                                <div style={{ position: 'absolute', zIndex: 1, background: '#0000006e', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    {(registrationList.length === 0) ?
                                        <ButtonComponent disabled={loading} style={{ background: '#FFF', color: '#5E6E82', opacity: 1 }} onClick={() => { setIsBilled(false); props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal_list', { institution: props.match.params.institution })) }} label={'Liste des devis'} />
                                        : <ButtonComponent disabled={loading} style={{ background: '#FFF', color: '#5E6E82', opacity: 1 }} onClick={() => { setIsBilled(false); props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_personal', { institution: props.match.params.institution, id: null })) }} label={'Créer un devis'} />
                                    }
                                </div>
                            }
                            <FilterPaginationComponent
                                data={registrationList}
                                className={classes.filterStyle}
                                title={'Liste des inscriptions'}
                                filter={[
                                    {
                                        data: ['formationName', 'lastname', 'firstname'],
                                        type: 'textField'
                                    },
                                ]}
                                error={'Aucune inscription'}
                                pageNumber={10}
                                viewList={viewRegistration}
                            />
                        </>
                    }
                </DrawerMUI>
                <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
                    <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                        {Object.keys(handler.form).length != 0 ?
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20, justifyContent: 'center', height: '100%' }}>
                                {billingList.map((registration, index) => (
                                    <Fade key={index} in={true} {...{ timeout: 1000 }}>
                                        <Box className={classes.cardStyle}>
                                            <CardPersonalComponent
                                                data={registration}
                                                institution={institution}
                                                isBilled={isBilled}
                                                setIsActiveBilled={setIsActiveBilled}
                                                setDestinationType={setDestinationType}
                                                handler={handler}
                                                handlerDeposit={handlerDeposit}
                                                error={(value) => setErrorCard(value)} onClick={removeRegistration}
                                                loading={loading || isBilled || institution.cancelAt}
                                                {...props}
                                            />
                                        </Box>
                                    </Fade>
                                ))}
                            </div>
                            : <p className={classes.error} style={{ lineHeight: '16px' }}>Ajouter une inscription<br /><span style={{ fontSize: 10 }}>(Ajouter une inscription pour créer le devis.)</span></p>
                        }
                    </Box>

                    <Box className={classes.total}>
                        {(institution.discounts && (institution.discounts.length != 0)) &&
                            <>
                                <Box style={{ position: 'relative' }}>
                                    <Slide direction="right" in={isOpenDiscount} mountOnEnter unmountOnExit>
                                        <Box className={classes.discountPopup}>
                                            {institution.discounts.map((discount, index) => (
                                                <Box key={index} style={{ border: '1px solid rgba(163, 173, 183, 0.61)', borderRadius: 5, }}>
                                                    <p className='date-root'>Date : du <span>{moment(discount.startAt).format('ll')}</span> au <span>{discount.endAt ? moment(discount.endAt).format('ll') : '-'}</span></p>
                                                    {(discount.percentage || discount.value) &&
                                                        <p className='discount-root'>
                                                            {discount.percentage && <span>Réduction : {discount.percentage}%</span>}
                                                            {discount.value && <span>Réduction : {discount.value}€</span>}
                                                        </p>
                                                    }
                                                    <p className='comment-root'>commentaire :<span>{discount.comment}</span></p>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Slide>
                                    <ButtonComponent onClick={() => { setIsOpenDiscount(!isOpenDiscount) }} label={'Remise'} color={"#5E6E82"} />
                                </Box>
                                <b>|</b>
                            </>
                        }
                    </Box>

                </ContentViewComponent >
            </Box >
            <Dialog open={dialog} className={classes.dialogStyle} maxWidth={'xl'} style={{ textAlign: 'center' }}>
                <DialogTitle>
                    <ErrorOutlineOutlinedIcon style={{ color: '#F8BB86', width: 90, height: 90 }} />
                    {!isBilled ?
                        <>
                            <p style={{ fontSize: 25, margin: 0, color: '#575757', fontWeight: 'bold' }}>Devis signé</p>
                            <p style={{ fontSize: 16, margin: 0, color: '#797979', }}>Êtes-vous sûr de vouloir signé ce devis ?<br /> Si c'est le cas, entrer l'acompte versé a la signature.</p>
                        </> : <>
                            <p style={{ fontSize: 25, margin: 0, color: '#575757', fontWeight: 'bold' }}>Facture total</p>
                            <p style={{ fontSize: 16, margin: 0, color: '#797979', }}>Êtes-vous sûr de vouloir facturer la totalité ?</p>
                        </>
                    }
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        {!isBilled ?
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TextFieldComponent name={'depositAmount'} handler={handlerDeposit} onChange={(value) => { handleDepositAmountChange(value) }} />
                                <p style={{ display: 'block', fontSize: 10, textAlign: 'center', color: '#E05059', margin: 'auto' }}>{error.text}</p>
                            </Grid> : <p style={{ margin: 0, color: '#575757', textAlign: 'center', width: '100%', padding: '0 0 10px' }}>
                                Montant demander : <br />
                                <b>{(billingList[0] && billingList[0]?.paymentData?.toBill) ?? 0} €</b>
                            </p>
                        }
                        {(institution.creditBalance && (destinationType == 1)) &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: ' 0 12px' }}>
                                <hr style={{ opacity: .4 }} />
                                <span style={{ fontSize: 15, margin: 0, color: '#575757', fontWeight: 'bold' }}>Solde de {restCredit?.toFixed(2)} €</span><span style={{ display: 'block', fontSize: 10, textAlign: 'center' }}>(À utilser)</span>
                                <div style={{ padding: '10px 0' }}>
                                    <span style={{ margin: 'auto' }} >{<TextFieldComponent name={`credit`} onChange={(value) => { setRestCredit(institution.creditBalance - value); setCredit(value) }} handler={handlerDeposit} disabled={institution.creditBalance === 0} />}</span>
                                    <span style={{ display: 'block', fontSize: 10, textAlign: 'center', color: '#E05059', margin: 'auto', padding: '10px 0 0' }}>{errorCredit.text}</span>
                                </div>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} />
                    <ButtonComponent disabled={error.isError || errorCredit.isError} label={'Enregistrer'} onClick={() => {
                        setDialog(false);
                        !isBilled ? saveDeposit() : saveBilled();
                    }} />
                </DialogActions>
            </Dialog>
            <SweetAlert
                show={showConvention}
                title={!isConvention ? 'Valider la convention' : 'Annuler la convention'}
                text={!isConvention ? 'Êtes-vous sûr de vouloir valider la convention ?' : 'Êtes-vous sûr de vouloir annuler la convention ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => { saveConvention(isConvention); setShowConvention(false); setIsConvention(!isConvention) }}
                onCancel={() => setShowConvention(false)}
            />
            <SweetAlert
                show={showConfirmEdit}
                title={'Éditer le devis'}
                text={'Ce devis est en attente de signature. \nÊtes-vous sûr de vouloir éditer ce devis ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirmEdit(false);
                    save();
                }}
                onCancel={() => setShowConfirmEdit(false)}
            /></>
    )
}

const drawerOpenWidth = 572;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        color: '#5E6E82',
        '& .ContentView-root': {
            height: '100%',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto min-content',
            padding: 0,
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                padding: '15px 55px',
                borderBottom: '1px solid rgba(163, 173, 183, 0.38)',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    filterStyle: {
        margin: 30,
        display: 'grid',
        gridTemplateRows: 'max-content auto max-content',
        gap: 10,
        color: 'rgb(94, 110, 130)',
        height: '100%',
        overflow: 'hidden',
        width: 'initial !important',
        '&>div:nth-of-type(1) div:nth-of-type(2)>div>div': {
            padding: '10px 0px 0px !important',
            '& div:nth-of-type(2)': {
                display: 'none'
            }
        },
        '&>div:nth-of-type(1)>div:nth-of-type(1) p': {
            fontSize: 20,
            margin: 0,
            fontWeight: 'bold !important',
        },
        '& .listPagination-root': {
            background: "rgb(239, 243, 244)",
            borderRadius: 10,
            border: "1px solid rgba(163, 173, 183, 0.36)",
            position: "relative",
            overflow: "hidden auto",
            padding: 20,
            display: "flex",
            flexWrap: "wrap",
            gap: 20,
            height: "100%",
            alignContent: 'baseline',
        },
        '& .title-root': {
            margin: '0px !important'
        },
        '& .error-root': {
            fontSize: 30,
            textTransform: 'initial',
            color: '#5E6E82',
            opacity: 0.3,
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)'
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        borderLeft: '6px solid #5E6E8226',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth,
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth,
    },
    creditStyle: {
        position: 'relative',
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        '& input': {
            textAlign: 'center'
        },
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    discountText: {
        '& .MuiTextField-root fieldset': {
            borderRadius: '4px 0 0 4px'
        },
        '& .MuiFormControl-root .MuiOutlinedInput-root': {
            borderRadius: '0 4px 4px 0'
        }
    },
    discountPopup: {
        position: 'absolute',
        bottom: 40,
        border: '1px solid rgb(163, 173, 183)',
        minHeight: 100,
        minWidth: 270,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        '& .date-root': {
            background: '#E5E7EA',
            borderBottom: '1px solid',
            padding: 10,
            fontSize: 12,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .discount-root': {
            margin: '0 10px',
            borderBottom: '1px solid',
            padding: 10
        },
        '& .comment-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            gap: 5,
            '& span': {
                display: 'block',
                height: '100%',
                background: 'rgb(239, 243, 244)',
                border: '1px solid rgba(163, 173, 183, 0.36)',
                borderRadius: 5,
                padding: '5px 10px',
                fontSize: 14
            }
        }
    },
    total: {
        margin: 0,
        display: 'flex',
        padding: '10px 55px',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        background: '#FFF',
        gap: 5,
        '& .MuiFormHelperText-root': {
            display: 'none'
        },
        '& .TextField-root': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 5,
            width: 76,
        },
        '& p': {
            margin: '0 0 0 auto',
        },
    },
    statsStyle: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        '& span': {
            color: '#FFF',
            padding: '3px 10px',
            display: 'block',
            borderRadius: 5
        }
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 478,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #depositAmount': {
            textAlign: 'center'
        },
        '& #credit': {
            textAlign: 'center'
        }
    },
    cardStyle: {
        background: '#FFF',
        width: 650,
        borderRadius: 10,
        border: '1px solid #A3ADB7',
        minHeight: '95%',
        margin: 'auto',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        position: 'relative',
    },
    cardStyleError: {
        background: '#FFF',
        width: 650,
        borderRadius: 10,
        border: '4px solid #DD4E4E',
        minHeight: '95%',
        margin: 'auto',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        position: 'relative',
        '&:before': {
            content: '" "',
            height: '100%',
            width: '100%',
            position: 'absolute',
            background: '#00000059',
            zIndex: 1
        },
        '& .alert': {
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            background: '#DD4E4E',
            padding: 16,
            top: '50%',
            left: '50%',
            fontSize: 15,
            transform: 'translate(-50%,-50%)',
            color: '#FFF',
            borderRadius: 5,
            fontWeight: 'bold',
            zIndex: 1
        }
    }
}))