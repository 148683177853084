import React from 'react';
import { Box, Drawer as DrawerMUI, Fade, Grid, IconButton, Slide, Tooltip, colors, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import CardComponent from './Component/CardComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import SelectComponent from '../../../../Component/SelectComponent';
import ViewRegistrationInvoicesComponent from './Component/ViewRegistrationInvoicesComponent';
import ViewInvoiceComponent from './Component/ViewInvoiceComponent';
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewRegistrationComponent from './Component/ViewRegistrationComponent';
import ViewRegistrationBilledComponent from './Component/ViewRegistrationBilledComponent';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import moment from 'moment';

export default function Invoice(props) {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isBilled, setIsBilled] = React.useState(false);
    const classes = useStyles(screenReducer, isBilled)();
    const dispatch = useDispatch();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [showConfirmEdit, setShowConfirmEdit] = React.useState(false);
    const [showConfirmValid, setShowConfirmValid] = React.useState(false);
    const [isOpenDiscount, setIsOpenDiscount] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [registrationList, setRegistrationList] = React.useState([]);
    const [institution, setInstitution] = React.useState([]);
    const [billingList, setBillingList] = React.useState([]);
    const [currentBillingList, setCurrentBillingList] = React.useState([]);
    const [total, setTotal] = React.useState({});
    const [isActiveBilled, setIsActiveBilled] = React.useState(false);
    const [totalAmount, setTotalAmount] = React.useState({});
    const [tva, setTva] = React.useState(0);
    const [isInvoiceEdit, setIsInvoiceEdit] = React.useState(false);
    const [useCredit, setUseCredit] = React.useState(0);
    const [addList, setAddList] = React.useState([]);
    const [isAllChecked, setIsAllChecked] = React.useState(false);
    const [isValidInvoice, setIsValidInvoice] = React.useState(false);
    const [errorCredit, setErrorCredit] = React.useState('');
    const [isAllTrue, setIsAllTrue] = React.useState(false);
    const [trueCount, setTrueCount] = React.useState(0);
    const [countDeleteAndLock, setCountDeleteAndLock] = React.useState(0);
    const [countConvention, setCountConvention] = React.useState(0);
    const [errorData, setErrorData] = React.useState([]);
    const [checkConventionList, setCheckConventionList] = React.useState([]);
    const [saveConfirm, setSaveConfirm] = React.useState(false);
    const [registrationData, setRegistrationData] = React.useState(null);

    const optionUnit = [
        { value: 1, label: '%' },
        { value: 2, label: '€' }
    ];
    const [formAuto, setFormAuto] = React.useState({
        supportAmount_Opco: {
            name: 'supportAmount_Opco',
            label: 'Prise en charge OPCO',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        supportAmount_Andpc: {
            name: 'supportAmount_Andpc',
            label: 'Prise en charge ANDPC',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        supportAmount_Fifpl: {
            name: 'supportAmount_Fifpl',
            label: 'Prise en charge FIFPL',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionFeeHt: {
            name: 'inscriptionFeeHt',
            label: 'Frais d’inscription',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionDiscount: {
            name: 'inscriptionDiscount',
            label: 'Remise d’inscription',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionDiscount_Unit: {
            name: 'inscriptionDiscount_Unit',
            label: 'Unité',
            type: 'integer',
            defaultValue: 2,
            options: {}
        },
        educationalDiscount: {
            name: 'educationalDiscount',
            label: 'Remise coût pédagogique',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        educationalDiscount_Unit: {
            name: 'educationalDiscount_Unit',
            label: 'Unité',
            type: 'integer',
            defaultValue: 2,
            options: {}
        },
    });
    const handlerAuto = formHandlerInit(formAuto, setFormAuto);
    React.useEffect(handlerAuto.start, []);
    const [formInstituion, setFormInstituion] = React.useState({
        label: {
            name: 'label',
            label: 'Référence de la facturation',
            type: 'text',
            textHelper: 'Ajouter une référence.(Exemple : \'INS-FACTURE-15\')',
            defaultValue: '',
            options: {}
        },
        totalDiscount: {
            name: 'totalDiscount',
            label: 'Remise net à payer',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        tva: {
            name: 'tva',
            label: 'TVA',
            type: 'float',
            defaultValue: 0,
            options: {}
        },
        credit: {
            name: 'credit',
            label: 'Utiliser',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
    });
    const handlerInstitution = formHandlerInit(formInstituion, setFormInstituion);
    React.useEffect(handlerInstitution.start, []);

    const [form, setForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const addBilling = (data) => {
        setBillingList((prev) => {
            return [...prev, data];
        });
    };

    const removeBilling = (data) => {
        setBillingList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });

        setRegistrationList((prev) => {
            return [...prev, data];
        });
    };

    const getActionMore = () => {
        return (
            isInvoiceEdit ?
                <Box style={{ display: 'flex', gap: 12 }}>
                    <ButtonComponent
                        disabled={addList.length === 0}
                        onClick={() => { setShowConfirmEdit(true) }}
                        label={'Enregistrer'}
                    />
                    <ButtonComponent
                        color={"rgb(220, 53, 69)"}
                        onClick={() => {
                            setAddList([]);
                            setIsInvoiceEdit(false);
                        }}
                        label={'Annuler'}
                    />
                </Box>
                :
                isBilled ?
                    <Box style={{ display: 'flex', gap: 12 }}>
                        <ButtonComponent
                            disabled={institution.cancelAt || billingList.filter((item) => !item.deletedAt && !item.isLock && !item.isConvention).length === 0}
                            onClick={() => {
                                if (!institution.contractHistorique?.firstInvoice?.isPaid) {
                                    setShowConfirmEdit(true);
                                    setIsAllChecked(true);
                                } else {
                                    setIsInvoiceEdit(true);
                                }
                            }}
                            label={'Créer un avoir'}
                        />
                        <ButtonComponent
                            onClick={() => {
                                dispatch(LinearProgressMainLayoutActivate());
                                setReload(true);
                                getInfoContract(props.match.params.id);
                            }}
                            disabled={reload}
                            color={"#5E6E82"}
                            style={{ padding: 4, minWidth: 40 }}
                            label={reload ? <CircularProgress size={18} /> : <ReplayIcon style={{ color: '#FFF' }} />}
                        />
                    </Box> :
                    <Box style={{ display: 'flex', gap: 12 }}>
                        <ButtonComponent
                            color={"#5E6E82"}
                            onClick={() => { isValidInvoice ? setIsValidInvoice(false) : save(false) }}
                            label={isValidInvoice ? <>Annuler</> : props.match.params.id ? <>Enregistrer</> : <>Créer le contrat</>}
                            disabled={(Object.keys(handler.form).length === 0) || errorCredit ? true : false || errorData.length != 0}
                        />
                        {props.match.params.id &&
                            <Tooltip title={errorData.length != 0 ? '' : isActiveBilled ? 'Des modifications ont été effectuées.' : ''} placement={"bottom"}>
                                <div>
                                    <ButtonComponent
                                        onClick={() => { isValidInvoice ? setShowConfirmValid(true) : setIsValidInvoice(true) }}
                                        label={(isValidInvoice) ? <>Valider</> : <>À Facturer</>}
                                        disabled={(Object.keys(handler.form).length === 0) || errorCredit ? true : false || isActiveBilled || errorData.length != 0}
                                    />
                                </div>
                            </Tooltip>
                        }
                        {(screenReducer.size <= 1812 && !isBilled) &&
                            <ButtonComponent
                                color={"#5E6E82"}
                                onClick={() => { setIsOpen(!isOpen) }}
                                label={'Ajouter des inscriptions'}
                            />
                        }
                    </Box>
        )
    }

    const getInstitution = (id) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        if (props.match.params.institution) {

            Api.get({
                route: 'institution_health_financial_management_billing_institution_invoice_get',
                data: { contractId: id ?? null, isPersonal: false },
                params: { institution: props.match.params.institution }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                setReload(false);
                if (response.status === 200) {
                    let data = response.data;
                    setInstitution(data);
                    setUseCredit(data.credit ?? '0');
                    setRegistrationList((data.registrations && data.registrations.length != 0) ? data.registrations : []);
                    setCurrentBillingList((data.registrationsBilled && data.registrationsBilled.length != 0) ? data.registrationsBilled : []);
                    setBillingList((data.registrationsBilled && data.registrationsBilled.length != 0) ? data.registrationsBilled : []);
                    setTva(data.tva ?? 0);
                    handlerInstitution.setValue('tva', data.tva ?? 0);
                    if (id) {
                        handlerInstitution.setValue('label', data.label);
                        handlerInstitution.setValue('credit', data.credit ?? '0');
                    } else {
                        handlerInstitution.setValue('label', '');
                        handlerInstitution.setValue('credit', '0');
                    }
                } else if (response.status === 409) {
                    dispatch(LinearProgressMainLayoutActivate());
                    setLoading(true);
                    setReload(true);
                    getInfoContract(id);
                    setBillingList([]);
                } else {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }));
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                        })
                    );
                }
            });
        }

    }

    const getInfoContract = (id) => {
        Api.get({
            route: 'institution_health_financial_management_billing_institution_contract_info',
            data: { isPersonal: false },
            params: { institution: props.match.params.institution, contractId: id }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            setLoading(false);
            setReload(false);
            if (response.status === 200) {
                let data = response.data;
                setInstitution(data);
                setIsBilled(true);
                setTva(data.tva ?? 0);
                handlerInstitution.setValue('tva', data.tva ?? 0);
                handlerInstitution.setValue('credit', data.credit ?? '0');
                const newBillingList = [...data.registrationsBilled, ...data.registrationsArchive]
                setBillingList(newBillingList ?? []);
            } else {
                props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }));
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'warning',
                    })
                );
            }
        });
    }

    const saveConvention = (checkList, id, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);

        let checkedData = null;
        if (id) {
            checkedData = [{ id: id, isConvention: checked }];
        } else {
            const newCheckList = checkList.map(item => {
                const { registrationContractId, ...rest } = item;
                return { ...rest, id: registrationContractId };
            });
            checkedData = newCheckList
        }

        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_convention',
            data: { conventions: checkedData },
            params: { institution: props.match.params.institution, contractId: props.match.params.id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else if (response.status === 409) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const save = (isBilled) => {
        if (handler.checkError() || (Object.keys(handler.form).length === 0 || handlerInstitution.checkError())) {
            console.log('error');
            dispatch(
                SnackbarOpen({
                    text: 'Veuillez entrer tous les champs.',
                    variant: 'error',
                })
            );
        } else if (useCredit > institution.creditBalance) {
            console.log('error');
            dispatch(
                SnackbarOpen({
                    text: errorCredit,
                    variant: 'error',
                })
            );
        } else {
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            const newHandler = Object.keys(handler.form).reduce((acc, handlerData) => {
                const { registrationId, value, isPercentage, isValidAmount } = handler.form[handlerData];
                const fieldKey = handlerData.split('-').slice(1).join('-');
                if (!acc[registrationId]) acc[registrationId] = {};
                acc[registrationId][fieldKey] = value ?? null
                if (isPercentage !== undefined) {
                    acc[registrationId][fieldKey] = {
                        value: value ?? null,
                        isPercentage: isPercentage
                    }
                }
                if (isValidAmount !== undefined) {
                    acc[registrationId][fieldKey] = {
                        value: value ?? null,
                        isValidAmount: isValidAmount
                    }
                }
                return acc;
            }, {});

            const data = {
                tva: tva,
                amountHt: totalAmount.htt,
                tvaAmount: totalAmount.tvaAmount,
                amountTtc: totalAmount.ttc,
                remainTtc: totalAmount.ttcUseCredit,
                restCredit: totalAmount.restCredit,
                invoiceRegistrations: newHandler,
                contractId: props.match.params.id,
                credit: handlerInstitution.getData().credit,
            }

            if (props.match.params.id) {
                let postData = {};
                isBilled ?
                    postData = {
                        route: 'institution_health_financial_management_billing_institution_invoice_add',
                        params: { institution: props.match.params.institution },
                        text: 'Facturer.',
                        data: data
                    } :
                    postData = {
                        route: 'institution_health_financial_management_billing_institution_contract_edit',
                        params: { institution: props.match.params.institution, contractId: props.match.params.id },
                        text: 'Contrat modifiée.',
                        data: data
                    }

                Api.post({
                    route: postData.route,
                    data: postData.data,
                    params: postData.params
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 200) {
                        getInstitution(props.match.params.id)
                        setIsValidInvoice(false)
                        dispatch(
                            SnackbarOpen({
                                text: postData.text,
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else if (response.status === 409) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                                duration: 10000
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_financial_management_billing_institution_contract_add',
                    data: data,
                    params: { institution: props.match.params.institution }
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 200) {
                        let data = response.data;
                        getInstitution(data.contractId)
                        setIsValidInvoice(false)
                        props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice', { institution: props.match.params.institution, id: data.contractId }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contrat créer.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else if (response.status === 409) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const saveCredit = () => {
        if ((isAllChecked && (countDeleteAndLock == countConvention) && institution.contractHistorique?.firstInvoice?.isPaid) || !institution.contractHistorique?.firstInvoice?.isPaid) {
            Api.delete({
                route: 'institution_health_financial_management_billing_institution_invoice_cancel',
                params: { institution: props.match.params.institution, invoiceId: institution.contractHistorique?.firstInvoice?.id },
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                if (response.status === 201) {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }));
                    setAddList([]);
                    setIsInvoiceEdit(false)
                    dispatch(
                        SnackbarOpen({
                            text: 'Facture annuler.',
                            variant: 'success',
                            duration: 10000
                        })
                    );
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                } else if (response.status === 409) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                            duration: 10000
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        } else {
            Api.post({
                route: 'institution_health_financial_management_billing_institution_credit_add',
                data: { registrationIds: addList },
                params: { institution: props.match.params.institution, invoiceId: institution.contractHistorique?.firstInvoice?.id },
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                if (response.status === 201) {
                    getInstitution(props.match.params.id)
                    setIsValidInvoice(false)
                    setAddList([]);
                    setIsInvoiceEdit(false)
                    dispatch(
                        SnackbarOpen({
                            text: 'Facture modifiée.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    const calculateHT = (price) => {
        const htValue = price / (1 + tva / 100);
        return htValue.toFixed(2)
    };

    const saveHandlerAuto = () => {
        const autoData = handlerAuto.getData();
        billingList.forEach(({ id, financeType }) => {
            if (autoData.inscriptionFeeHt != undefined || autoData.inscriptionFeeHt != null)
                handler.setValue(`${id}-inscriptionFeeHt`, autoData.inscriptionFeeHt);
            if (autoData.inscriptionDiscount != undefined || autoData.inscriptionDiscount != null)
                handler.setValue(`${id}-inscriptionDiscount`, autoData.inscriptionDiscount);
            if (autoData.educationalDiscount != undefined || autoData.educationalDiscount != null)
                handler.setValue(`${id}-educationalDiscount`, autoData.educationalDiscount);

            handler.form[`${id}-inscriptionDiscount`].isPercentage = autoData.inscriptionDiscount_Unit == 1 ? true : false;
            handler.form[`${id}-educationalDiscount`].isPercentage = autoData.educationalDiscount_Unit == 1 ? true : false;

            const supportAmountMapping = {
                1: autoData.supportAmount_Andpc,
                3: autoData.supportAmount_Fifpl,
                8: autoData.supportAmount_Opco,
            };

            if (financeType in supportAmountMapping) {
                if (supportAmountMapping[financeType] != undefined || supportAmountMapping[financeType] != null) {
                    handler.setValue(`${id}-supportAmount`, calculateHT(supportAmountMapping[financeType]));
                }
            }
        });
        handler.setDataApi();
    };

    const viewHandlerAuto = () => {
        return (
            <Box className={classes.formAuto}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }} >
                        <h1 style={{ fontSize: 20, margin: 0 }}>Saisie automatique</h1>
                        <hr style={{ margin: 0, opacity: 0.5 }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '0 0 5px 0' }}>
                                <fieldset className={classes.fieldsetStyle}>
                                    <legend style={{ margin: '0 0 0 auto', padding: '0 12px' }}><span>Prix TTC</span></legend>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <TextFieldComponent name='supportAmount_Opco' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <TextFieldComponent name='supportAmount_Andpc' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                            <TextFieldComponent name='supportAmount_Fifpl' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.discountText} style={{ display: 'grid', gridTemplateColumns: 'auto 53px' }}>
                                <TextFieldComponent name='inscriptionDiscount' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                <SelectComponent name={'inscriptionDiscount_Unit'} handler={handlerAuto} options={optionUnit} disabled={loading || institution.cancelAt || isValidInvoice} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} className={classes.discountText} style={{ display: 'grid', gridTemplateColumns: 'auto 53px' }}>
                                <TextFieldComponent name='educationalDiscount' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                <SelectComponent name={'educationalDiscount_Unit'} handler={handlerAuto} options={optionUnit} disabled={loading || institution.cancelAt || isValidInvoice} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto 230px' }} >
                                <TextFieldComponent name='inscriptionFeeHt' handler={handlerAuto} disabled={loading || institution.cancelAt || isValidInvoice} />
                                <ButtonComponent onClick={() => { saveHandlerAuto() }} label={'valider'} disabled={loading || (Object.keys(handler.form).length === 0) || institution.cancelAt || isValidInvoice} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    function parseDiscount(discount) {
        if (typeof discount === 'string') {
            if (discount.includes('%')) {
                return { value: Number(discount.replace('%', '')), isPercentage: true };
            } else if (discount.includes('€')) {
                return { value: Number(discount.replace('€', '')), isPercentage: false };
            }
        }
        return { value: discount ? Number(discount) : '', isPercentage: false };
    }

    const description = () => {
        const descriptions = {
            1: { text: 'Facture | Annuler', background: '#DD4E4E' },
            2: { text: 'Facture | Non payé', background: '#E6A731' },
            3: { text: 'Création d\'un avoir', background: 'rgb(156, 39, 176)' },
            4: { text: 'Facture | payé', background: '#28a745' },
            default: { text: 'Contrat', background: '#007bff' }
        };
        const contractHistorique = institution.contractHistorique;
        const conditions = [
            { condition: contractHistorique?.firstInvoice?.cancelAt || institution.cancelAt, type: 1 },
            { condition: isBilled && !contractHistorique?.firstInvoice?.isPaid, type: 2 },
            { condition: isInvoiceEdit, type: 3 },
            { condition: isBilled && contractHistorique?.firstInvoice?.isPaid, type: 4 }
        ];

        const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
        const { text: description, background } = descriptions[descriptionType];

        return (
            props.match.params.id ?
                <span style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                    {isBilled ? 'Suivi de contrat' : 'Édition du contrat'}
                    <span style={{ borderRadius: 5, background: background, color: '#FFF', padding: '3px 10px', display: 'block' }}>
                        {description}
                    </span>
                </span> :
                <span>Création d'un contrat</span>
        );
    }

    const savePaid = (checked) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_institution_invoice_paid',
            data: { isPaid: checked },
            params: { institution: props.match.params.institution, invoiceId: institution.contractHistorique?.firstInvoice?.id }
        }, (response) => {
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 201) {
                getInstitution(props.match.params.id)
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const handleToggleAll = () => {
        setAddList((prev) => {
            const filteredBillingList = billingList.filter(registration => (registration.isConvention && !registration.deletedAt) || (!registration.isConvention && !registration.deletedAt));

            const exists = filteredBillingList.every(registration =>
                prev.some(prevItem => prevItem === registration.registrationId)
            );

            if (exists) {
                setIsAllChecked(false);
                return [];
            } else {
                setIsAllChecked(true);
                return filteredBillingList.map(registration => registration.registrationId);
            }
        });
    };



    const checkAllChecked = (addList, billingList) => {
        return billingList.every(registration =>
            addList.some(prevItem => prevItem === registration.registrationId)
        );
    };

    const compareArrays = (arr1, arr2) => {
        const allIdsExist = arr1.every(item1 =>
            arr2.some(item2 => item2.id === item1.id)
        );
        const allIdsExistInReverse = arr2.every(item2 =>
            arr1.some(item1 => item1.id === item2.id)
        );
        return allIdsExist && allIdsExistInReverse;
    }

    React.useEffect(() => {
        setIsActiveBilled((isPrev) => {
            return (isPrev || !compareArrays(currentBillingList, billingList) || (parseFloat(tva) != (institution.tva ?? 0)));
        })
    }, [billingList, isActiveBilled, tva])

    //calcule total
    React.useEffect(() => {
        const amount = Object.values(total).map(value => parseFloat(value)).reduce((acc, value) => acc + value, 0);
        const tvaAmount = amount * (tva / 100);
        let ttc = amount + tvaAmount;
        let restCredit = Math.max(institution.creditBalance - useCredit, 0);
        let ttcUseCredit = Math.max(ttc - useCredit, 0);

        if ((useCredit > institution.creditBalance) && institution.creditBalance) {
            setErrorCredit(<>Attention, vous avez dépassé le montant disponibles. Le montant est actuellement à <b>{institution.creditBalance} €</b></>);
        } else if (parseFloat(useCredit) > parseFloat(ttc.toFixed(2))) {
            setErrorCredit(<>Attention, le montant actuel de la facture est de <b>{ttc.toFixed(2)} €</b>. Par conséquent, le montant utilisé sera identique.</>);
        } else setErrorCredit(``);

        setTotalAmount({
            restCredit: restCredit.toFixed(2),
            htt: amount.toFixed(2),
            tvaAmount: tvaAmount.toFixed(2),
            ttc: ttc.toFixed(2),
            ttcUseCredit: ttcUseCredit.toFixed(2),
        });

    }, [total, tva, useCredit, institution]);

    React.useEffect(() => {
        const filteredBillingList = billingList.filter(registration => !registration.isConvention && !registration.deletedAt);
        const allChecked = checkAllChecked(addList, filteredBillingList);
        setIsAllChecked(allChecked);
        setCountDeleteAndLock(billingList.filter((item) => !item.deletedAt).length)
        setCountConvention(billingList.filter((item) => !item.isConvention && !item.deletedAt).length)
    }, [addList, billingList]);

    React.useEffect(() => {
        if (billingList.length !== 0) {
            setForm((prevData) => {
                const newData = billingList.reduce((acc, registration) => {
                    const keyEF = `${registration.id}-educationalFeeHt`;
                    const keyED = `${registration.id}-educationalDiscount`;
                    const keySA = `${registration.id}-supportAmount`;
                    const keyIF = `${registration.id}-inscriptionFeeHt`;
                    const keyID = `${registration.id}-inscriptionDiscount`;
                    const keyComment = `${registration.id}-comment`;

                    let inscriptionDiscount = parseDiscount(registration.inscriptionDiscount);
                    let educationalDiscount = parseDiscount(registration.educationalDiscount);

                    acc[keyEF] = {
                        name: keyEF,
                        label: 'Coût pédagogique',
                        type: 'float',
                        value: (registration.educationalFeeHt && registration.educationalFeeHt != 0) ? registration.educationalFeeHt : '0',
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };
                    acc[keyED] = {
                        name: keyED,
                        label: 'Remise coût pédagogique',
                        type: 'float',
                        value: educationalDiscount.value ?? '',
                        registrationId: registration.id,
                        isPercentage: educationalDiscount.isPercentage,
                        options: {}
                    };
                    acc[keySA] = {
                        name: keySA,
                        label: 'Prise en charge',
                        type: 'float',
                        value: (registration.supportAmount && registration.supportAmount != 0) ? registration.supportAmount : '0',
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };
                    acc[keyIF] = {
                        name: keyIF,
                        label: 'Frais d’inscription',
                        type: 'float',
                        value: (registration.inscriptionFeeHt && registration.inscriptionFeeHt != 0) ? registration.inscriptionFeeHt : '0',
                        registrationId: registration.id,
                        options: { validation: ['required'] }
                    };
                    acc[keyID] = {
                        name: keyID,
                        label: 'Remise frais d’inscription',
                        type: 'float',
                        value: inscriptionDiscount.value ?? '',
                        registrationId: registration.id,
                        isPercentage: inscriptionDiscount.isPercentage,
                        options: {}
                    };
                    acc[keyComment] = {
                        name: keyComment,
                        label: 'Commentaire',
                        type: 'text',
                        value: registration.comment ?? '',
                        registrationId: registration.id,
                        options: {}
                    };

                    return acc;
                }, {});

                const mergedData = { ...prevData };
                Object.keys(newData).forEach(key => {
                    mergedData[key] = {
                        ...newData[key],
                        ...prevData[key]
                    };
                });

                return mergedData;
            });

            setRegistrationList((prev) => {
                return prev.filter(item => !billingList.some(reg => reg.id === item.id));
            });

        }
        setForm((prev) => {
            const result = {};
            billingList.forEach(item => {
                const registrationId = item.id.toString();
                for (const key in prev) {
                    if (prev[key].registrationId.toString() === registrationId) {
                        result[key] = prev[key];
                    }
                }
            });
            return result;
        });
        setRegistrationData({
            id: billingList[0]?.registrationId,
            prospectName: `${billingList[0]?.prospectName ?? ''}`
        })

    }, [billingList]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: institution.name ? institution.name : '-',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: description(),
            links: props.match.params.id ? [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_mixte_list'), label: 'Facturation établissement' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_invoice_list', { institution: props.match.params.institution }), label: 'Liste des contrats' }
            ] : [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_mixte_list'), label: 'Facturation établissement' },
            ],
            html: getActionMore()
        });
    }, [screenReducer, institution, form, isBilled, billingList, totalAmount, isInvoiceEdit, addList, isValidInvoice, isActiveBilled, checkConventionList, reload, isOpen]);

    React.useEffect(() => {
        setTotal((prev) => {
            const result = {};
            for (let key in form) {
                const registrationId = form[key].registrationId.toString();
                if (prev.hasOwnProperty(registrationId)) {
                    result[registrationId] = prev[registrationId];
                }
            }
            return result;
        });
    }, [form]);

    React.useEffect(() => {
        getInstitution(props.match.params.id);
    }, [props.match.params.id]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
        if (loading) { setIsOpen(!(screenReducer.size <= 1812 && !isBilled)) }
    }, [screenReducer, loading]);

    return (
        <>
            <Box className={classes.styleContentView}>
                <DrawerMUI
                    variant="permanent"
                    className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                    open={isOpen}>
                    {(screenReducer.size <= 1812 && !isBilled) &&
                        <IconButton onClick={() => { setIsOpen(!isOpen) }} style={{
                            background: '#FFF', border: '8px solid #E7E9EC', width: 'max-content', borderRadius: 100,
                            position: 'absolute', left: isOpen ? -30 : 0, padding: 3, top: 29, transition: 'all .5s'
                        }}>
                            <ArrowLeftIcon style={{ color: '#5E6E82', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all .5s' }} />
                        </IconButton>
                    }
                    <Box style={{ padding: 30, display: 'grid', gridTemplateRows: 'max-content auto', gap: 10, color: '#5E6E82', height: '100%', overflow: 'hidden' }}>
                        {isBilled ?
                            <>
                                <ViewInvoiceComponent
                                    dataList={billingList.filter((item) => !item.deletedAt)}
                                    institution={institution}
                                    isInvoiceEdit={isInvoiceEdit}
                                    trueCount={trueCount}
                                    isAllTrue={isAllTrue}
                                    checkList={checkConventionList}
                                    setCheckList={setCheckConventionList}
                                    loading={loading || institution.cancelAt || institution.isLock}
                                    savePaid={savePaid}
                                    saveConvention={saveConvention}
                                    setSaveConfirm={setSaveConfirm}
                                    {...props}
                                />
                                <ViewRegistrationInvoicesComponent institution={institution} registration={registrationData} {...props} />
                            </> :
                            <>
                                {viewHandlerAuto()}
                                <ViewRegistrationComponent dataList={registrationList} addBilling={addBilling} loading={loading} disabled={institution.cancelAt || isValidInvoice} />
                            </>
                        }
                    </Box>
                </DrawerMUI>
                <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
                    {isBilled ?
                        <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                            <ViewRegistrationBilledComponent
                                dataList={billingList}
                                dataInstitution={institution}
                                save={saveConvention}
                                setIsAllTrue={setIsAllTrue}
                                setTrueCount={setTrueCount}
                                checkList={checkConventionList}
                                setCheckList={setCheckConventionList}
                                setSaveConfirm={setSaveConfirm}
                                saveConfirm={saveConfirm}
                                loading={loading}
                                setAddList={setAddList}
                                setRegistration={setRegistrationData}
                                setIsAllChecked={setIsAllChecked}
                                isAllChecked={isAllChecked}
                                isInvoiceEdit={isInvoiceEdit}
                                disabled={institution.cancelAt}
                                {...props}
                            />
                        </Box> :
                        <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                            {Object.keys(handler.form).length != 0 ?
                                <div style={{ padding: 20, display: 'flex', flexWrap: 'wrap', gap: 20, justifyContent: (screenReducer.size <= 1239) ? 'center' : 'left', maxWidth: 1200, margin: 'auto' }}>
                                    {billingList.map((registration, index) => {
                                        return (
                                            <Fade key={index} in={true} {...{ timeout: 800 }}>
                                                <Box className={`${classes.cardStyle}`}>
                                                    <CardComponent
                                                        data={registration}
                                                        dataInstitution={institution}
                                                        handler={handler}
                                                        isInvoiceEdit={isInvoiceEdit}
                                                        setErrorData={setErrorData}
                                                        tva={tva}
                                                        setIsActiveBilled={setIsActiveBilled}
                                                        setTotal={setTotal}
                                                        isValidInvoice={isValidInvoice}
                                                        onClick={removeBilling}
                                                        loading={loading || isBilled || institution.cancelAt || isValidInvoice}
                                                        {...props}
                                                    />
                                                </Box>
                                            </Fade>
                                        )
                                    })}
                                </div>
                                : <p className={classes.error}>Ajouter une inscription</p>
                            }
                        </Box>
                    }
                    <Box>
                        {(institution.creditBalance && !isBilled && isValidInvoice) ?
                            <Box className={classes.creditStyle}>
                                {errorCredit && <p style={{ margin: 'auto', width: 'fit-content', fontSize: 13, color: '#E05059' }}>{errorCredit}</p>}
                                <div style={{ display: 'grid', gridTemplateColumns: 'max-content max-content auto', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                                    <p style={{ fontWeight: 'bold', margin: 'auto', textAlign: 'center', lineHeight: '16px' }}>Solde restant {totalAmount.restCredit} €<br />
                                        <span style={{ fontSize: 12 }}>[Solde initial {institution.creditBalance.toFixed(2)} €]</span>
                                    </p>
                                    <b style={{ width: 3, background: '#5E6E82', height: '50%', borderRadius: 5 }}></b>
                                    <TextFieldComponent name='credit' handler={handlerInstitution} onChange={(value) => { setUseCredit(value) }} disabled={loading || isBilled || !isValidInvoice} />
                                </div>
                            </Box> : institution.contractHistorique?.firstInvoice?.credit ?
                                <Box className={classes.creditStyle}>
                                    <p style={{ fontWeight: 'bold', margin: 'auto' }}>Solde utiliser : {institution.contractHistorique?.firstInvoice?.credit} €</p>
                                </Box> : ''
                        }
                        <Box className={classes.total}>
                            {(institution.discounts && (institution.discounts.length != 0)) ?
                                <>
                                    <Box style={{ position: 'relative' }}>
                                        <Slide direction="right" in={isOpenDiscount} mountOnEnter unmountOnExit>
                                            <Box className={classes.discountPopup}>
                                                {institution.discounts.map((discount, index) => (
                                                    <Box key={index} style={{ border: '1px solid rgba(163, 173, 183, 0.61)', borderRadius: 5, }}>
                                                        <p className='date-root'>Date : du <span>{moment(discount.startAt).format('ll')}</span> au <span>{discount.endAt ? moment(discount.endAt).format('ll') : '-'}</span></p>
                                                        {(discount.percentage || discount.value) &&
                                                            <p className='discount-root'>
                                                                {discount.percentage && <span>Réduction : {discount.percentage}%</span>}
                                                                {discount.value && <span>Réduction : {discount.value}€</span>}
                                                            </p>
                                                        }
                                                        <p className='comment-root'>commentaire :<span>{discount.comment}</span></p>
                                                    </Box>
                                                ))}
                                            </Box>
                                        </Slide>
                                        <ButtonComponent onClick={() => { setIsOpenDiscount(!isOpenDiscount) }} label={'Remise'} color={"#5E6E82"} />
                                    </Box>
                                </> : ''
                            }
                            {(isInvoiceEdit && (billingList.filter(registration => registration.isLock).length == 0)) &&
                                <ButtonComponent
                                    onClick={() => handleToggleAll()}
                                    label={isAllChecked ? 'Tout décocher' : 'Tout cocher'}
                                />
                            }
                            <p style={{ fontWeight: 'bold' }}>Net à payer : {isBilled ? (institution.amountTtc).toFixed(2) ?? 0 : totalAmount.ttcUseCredit} €</p>
                            <b>|</b>
                            <Box className='TextField-root'>
                                <TextFieldComponent name='tva' handler={handlerInstitution} onChange={(value) => { setTva(value) }} disabled={true} /> %
                            </Box>
                        </Box>
                    </Box>
                </ContentViewComponent >
            </Box >
            <SweetAlert
                show={showConfirmEdit}
                title={`Créer un avoir`}
                text={
                    (isAllChecked && (countDeleteAndLock == countConvention) && institution.contractHistorique?.firstInvoice?.isPaid) || !institution.contractHistorique?.firstInvoice?.isPaid ?
                        '[Complet]\nÊtes-vous sûr de vouloir créer un avoir ?\nSi c\'est le cas, toutes les inscriptions seront supprimées.' :
                        '[Partiel]\nÊtes-vous sûr de vouloir créer un avoir ?\nSi c\'est le cas, les apprenants sélectionnés sur cette facturation seront retirés.'
                }
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirmEdit(false);
                    saveCredit();
                }}
                onCancel={() => { setShowConfirmEdit(false) }}
            />
            <SweetAlert
                show={showConfirmValid}
                title={'Valider la facture'}
                text={'Êtes-vous sûr de vouloir valider cette facture ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => { save(true); setShowConfirmValid(false) }}
                onCancel={() => setShowConfirmValid(false)}
            />
        </>
    )
}

const drawerOpenRight = 0;
const drawerCloseRight = -572;
const useStyles = (screenReducer, isBilled) => makeStyles(style => ({
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        color: '#5E6E82',
        position: 'relative',
        overflow: 'hidden',
        '& .MuiCircularProgress-svg': {
            color: '#FFF'
        },
        '& .ContentView-root': {
            height: '100%',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto min-content',
            padding: 0,
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                padding: '15px 55px',
                borderBottom: '1px solid rgba(163, 173, 183, 0.38)',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: (screenReducer.size <= 1440 && isBilled) ? '480px !important' : '572px !important',
        right: (screenReducer.size <= 1812 && !isBilled) ? drawerCloseRight : `${drawerOpenRight}px !important`,
        height: '100%',
        flexShrink: 0,
        borderLeft: '6px solid #5E6E8226',
        background: '#FFFFFF',
        //overflowX: 'hidden',
        zIndex: 10,
        boxSizing: 'border-box',
        position: (screenReducer.size <= 1812 && !isBilled) ? 'absolute' : '',
        boxShadow: (screenReducer.size <= 1812 && !isBilled) ? '-1px 0px 4px #00000036' : '',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflow: 'initial !important',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('right', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        right: `${drawerOpenRight}px !important`,
    },
    drawerClose: {
        transition: style.transitions.create('right', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        right: drawerCloseRight,
    },
    creditStyle: {
        background: '#E5E7EA',
        position: 'relative',
        padding: 10,
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        '& input': {
            textAlign: 'center'
        },
    },
    formAuto: {
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    circularProgress: {
        margin: 0,
        '& svg': { color: '#FFF' }
    },
    discountText: {
        '& .MuiTextField-root fieldset': {
            borderRadius: '4px 0 0 4px'
        },
        '& .MuiFormControl-root .MuiOutlinedInput-root': {
            borderRadius: '0 4px 4px 0'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    discountPopup: {
        position: 'absolute',
        bottom: 40,
        border: '1px solid rgb(163, 173, 183)',
        minHeight: 100,
        minWidth: 270,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        '& .date-root': {
            background: '#E5E7EA',
            borderBottom: '1px solid',
            padding: 10,
            fontSize: 12,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .discount-root': {
            margin: '0 10px',
            borderBottom: '1px solid',
            padding: 10
        },
        '& .comment-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            gap: 5,
            '& span': {
                display: 'block',
                height: '100%',
                background: 'rgb(239, 243, 244)',
                border: '1px solid rgba(163, 173, 183, 0.36)',
                borderRadius: 5,
                padding: '5px 10px',
                fontSize: 14
            }
        }
    },
    total: {
        margin: 0,
        display: 'flex',
        padding: '10px 55px',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        background: '#FFF',
        gap: 5,
        '& .TextField-root': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 5,
            width: 84,
            '& .MuiFormHelperText-root': {
                display: 'none'
            }
        },
        '& p': {
            margin: '0 0 0 auto',
        },
    },
    cardStyle: {
        overflow: 'hidden',
        background: '#FFF',
        width: 588,
        borderRadius: 10,
        position: 'relative',
        border: '1px solid #A3ADB7',
        display: 'flex',
        flexDirection: 'column'
    },
    cardStyleError: {
        overflow: 'hidden',
        background: '#FFF',
        width: 588,
        borderRadius: 10,
        position: 'relative',
        border: '4px solid #DD4E4E',
        '&:before': {
            content: '" "',
            height: '100%',
            width: '100%',
            position: 'absolute',
            background: '#00000059',
            zIndex: 1
        },
        '& .alert': {
            height: 'auto',
            width: 'auto',
            position: 'absolute',
            background: '#DD4E4E',
            padding: 16,
            top: '50%',
            left: '50%',
            fontSize: 15,
            transform: 'translate(-50%,-50%)',
            color: '#FFF',
            borderRadius: 5,
            fontWeight: 'bold',
            zIndex: 1
        }
    },
    fieldsetStyle: {
        borderRadius: 5,
        borderColor: '#5E6E825E',
        fontSize: 11,
        color: '#5E6E82',
        fontWeight: 'bold',
        padding: '5px 10px 10px'
    }
}))
