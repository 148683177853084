import React from 'react';
import { Box, IconButton, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import moment from "moment";
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Slide from "@material-ui/core/Slide";
import ChartColorOpacity from "../../../Constant/ChartColorOpacity";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { TrendingUp } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Card from "./Component/Card";
import BoxPopupActionComponent from "../../../Component/BoxPopupActionComponent";
import Grid from "@material-ui/core/Grid";
import Zoom from "@material-ui/core/Zoom";
import Api from "../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import { inArray } from "../../../Tool/ArrayCommon";
import { Bar, Doughnut } from 'react-chartjs-2';
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { TableReload } from "../../../Action/TableAction";
import ButtonComponent from '../../../Component/ButtonComponent';
import { getRoutePathname } from '../../../Config/Route';

const useStyles = makeStyles({
    td: { width: '8%', fontSize: 12, padding: 12 },
    th: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    thSize: { width: '8%', fontWeight: 900, fontSize: 12, padding: 12 },
    up: { fontSize: 16, verticalAlign: 'bottom', color: '#008000' },
    upText: { fontSize: 11, verticalAlign: 'bottom', color: '#008000' },
    down: { fontSize: 16, verticalAlign: 'bottom', color: '#dc3545' },
    downText: { fontSize: 11, verticalAlign: 'bottom', color: '#dc3545' },
    equalText: { fontSize: 9, verticalAlign: 'middle', color: '#000' },
    textSearchSlider: { fontSize: 16, textAlign: 'center', height: 40, lineHeight: '40px', textTransform: 'capitalize' },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    select: {
        '& select': {
            padding: 10
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    linearProgress: {
        position: 'absolute',
        top: 75,
        width: '100vw',
        height: 2,
    },
    containerButtonArrow: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        margin: 'auto',
        padding: '12px 0',
        '& p': {
            margin: 'auto',
            width: '60%',
            textAlign: 'center',
            background: '#EFF2F4',
            padding: '10px 0',
            border: '1px solid #0000002e',
            borderRadius: 5
        },
    },
    buttonArrow: {
        width: 25,
        height: 25,
        overflow: 'hidden',
        background: 'rgb(239, 242, 244)',
        position: 'relative',
        borderRadius: 20,
        cursor: 'pointer',
        '& svg': {
            position: 'absolute',
            width: '80%',
            height: '100%',
            left: '50%',
            top: '50%',
        }
    },
    boxPopupAction: {
        '& .root-BoxPopupAction-Popup': {
            width: 300,
            height: 'auto',
        }
    },
    icon: {
        position: 'absolute',
        top: 40,
        left: 15,
        fontSize: 30
    },
    titleStyle: {
        '& hr': {
            margin: '0 !important',
        }
    }
});

const ExpandableTableRow = ({ name, children, expandComponent }) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (
        <>
            <TableRow>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.thSize}>{name}</TableCell>
                {children}
            </TableRow>
            {isExpanded && (
                <>
                    {expandComponent}
                </>
            )}
        </>
    );
};

function Investor(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [organism, setOrganism] = React.useState(0);
    const [financeType, setFinanceType] = React.useState(0);
    const [commercials, setCommercials] = React.useState([]);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [textSearchSlider, setTextSearchSlider] = React.useState(moment().year());
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [numberRegistrations, setNumberRegistrations] = React.useState({});
    const [numberNewClients, setNumberNewClients] = React.useState({});
    const [numberPlanneds, setNumberPlanneds] = React.useState({});
    const [numberCompleteds, setNumberCompleteds] = React.useState({});
    const [numberCAs, setNumberCAs] = React.useState({});
    const [numberCAsBilling, setNumberCAsBilling] = React.useState({});
    const [numberBilling, setNumberBilling] = React.useState({});
    const [numberSatisfactions, setNumberSatisfactions] = React.useState({});
    const [startAt, setStartAt] = React.useState(moment());
    const [loadDownload, setLoadDownload] = React.useState(false);

    const formationGroups = [
        { index: 'med', text: 'Médecin' },
        { index: 'inf', text: 'Idel' },
        { index: 'dent', text: 'Dentiste' },
        { index: 'kin', text: 'Kinésithérpeute' },
        { index: 'orth', text: 'Orthophonistes' },
        { index: 'ruma', text: 'Rhumatologue' },
        { index: 'diver', text: 'Divers' },
    ];

    const month = [
        { index: '01', text: 'Janvier' },
        { index: '02', text: 'Février' },
        { index: '03', text: 'Mars' },
        { index: '04', text: 'Avril' },
        { index: '05', text: 'Mai' },
        { index: '06', text: 'Juin' },
        { index: '07', text: 'Juillet' },
        { index: '08', text: 'Août' },
        { index: '09', text: 'Septembre' },
        { index: '10', text: 'Octobre' },
        { index: '11', text: 'Novembre' },
        { index: '12', text: 'Décembre' },
        { index: '13', text: 'Année N+1' },
    ];

    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            type: 'array',
            options: {}
        }
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);

    React.useEffect(handlerSearch.start, []);
    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
    };

    const getDataPercentage = (value, data, text) => {
        let tmpData = { 'data': [], 'labels': [] };
        if (data && data[value]) {
            for (let formationGroup in formationGroups) {
                let labels = formationGroups[formationGroup]['text'] + (text ? ' (' + data[value][formationGroups[formationGroup]['index']] + ' ' + text + ')' : '')
                tmpData['labels'].push(labels);
                tmpData['data'].push(data[value][formationGroups[formationGroup]['index']]);
            }
        }
        return tmpData;
    };

    const dataPercentageRegistration = {
        datasets: [{
            data: getDataPercentage('total', numberRegistrations, null)['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataPercentage('total', numberRegistrations, 'inscrit')['labels'],
        options: {
            legend: {
                display: true,
                position: 'right',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                position: 'top',
                text: 'Nombre d\'inscriptions par année',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return '';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let datas = dataset['data'];
                        let reducer = (accumulator, curr) => accumulator + curr;
                        let percent = ((dataset['data'][tooltipItem['index']] / datas.reduce(reducer)) * 100).toFixed(1)
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    const dataPercentagePlanned = {
        datasets: [{
            data: getDataPercentage('total', numberPlanneds, null)['data'],
            backgroundColor: ChartColorOpacity,
            borderColor: '#FFFFFF'
        }],
        labels: getDataPercentage('total', numberPlanneds, 'formation')['labels'],
        options: {
            legend: {
                display: true,
                position: 'right',
                fontColor: '#FFFFFF',
                labels: {
                    fontColor: '#A1A2A9',
                    padding: 15
                }
            },
            title: {
                display: true,
                position: 'top',
                text: 'Nombre de formation planifiée par année',
                fontSize: 20,
                fontColor: '#A1A2A9',
                padding: 10
            },
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data['labels'][tooltipItem[0]['index']];
                    },
                    label: function (tooltipItem, data) {
                        return '';
                    },
                    afterLabel: function (tooltipItem, data) {
                        let dataset = data['datasets'][0];
                        let datas = dataset['data'];
                        let reducer = (accumulator, curr) => accumulator + curr;
                        let percent = ((dataset['data'][tooltipItem['index']] / datas.reduce(reducer)) * 100).toFixed(1)
                        return 'Soit : ' + percent + '%';
                    }
                },
                backgroundColor: '#FFF',
                titleFontSize: 16,
                titleFontColor: '#A1A2A9',
                bodyFontColor: '#A1A2A9',
                bodyFontSize: 14,
                displayColors: false,
                borderColor: '#A1A2A9',
                borderWidth: 1
            }
        }
    };

    const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());

    const goToPreviousMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth === 0 ? 12 : prevMonth - 1));
    };

    const goToNextMonth = () => {
        setCurrentMonth((prevMonth) => (prevMonth === 12 ? 0 : prevMonth + 1));
    };
    const text_planneds_completeds = {
        title: 'Taux de complétion de formation pour le mois de ' + month[currentMonth].text + ' (Planifiées/Terminées)',
        label_1: 'Planifiées',
        label_2: 'Terminées',
    };

    const text_numberCAs_ = {
        title: 'chiffre d\'affaire prévisionnel / chiffre d\'affaire facturé pour le mois de ' + month[currentMonth].text,
        label_1: 'CA Prévisionnel',
        label_2: 'CA Facturé',
    };

    const getTurnover2 = (data1, data2, text) => {
        const dataTurnover = {
            datasets: [{
                data: getDataPercentage(month[currentMonth].index, data1, null)['data'],
                label: 1,
                backgroundColor: 'rgba(54, 162, 235, 0.7)',
                borderColor: '#FFFFFF'
            },
            {
                data: getDataPercentage(month[currentMonth].index, data2, null)['data'],
                label: 2,
                backgroundColor: 'rgba(204, 101, 254, 0.7)',
                borderColor: '#FFFFFF'
            }],
            labels: getDataPercentage(month[currentMonth].index, data1, null)['labels'],
            options: {
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: text.title,
                    fontSize: 20,
                    fontColor: '#A1A2A9',
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data.labels[tooltipItem[0].index];
                        },
                        label: function (tooltipItem, data) {
                            if (tooltipItem.datasetIndex === 0) {
                                return data.datasets[0].data[tooltipItem.index] + ' ' + text.label_1;
                            } else if (tooltipItem.datasetIndex === 1) {
                                return data.datasets[1].data[tooltipItem.index] + ' ' + text.label_2;
                            }
                        }
                    },
                    backgroundColor: '#FFF',
                    titleFontSize: 16,
                    titleFontColor: '#A1A2A9',
                    bodyFontColor: '#A1A2A9',
                    bodyFontSize: 14,
                    displayColors: false,
                    borderColor: '#A1A2A9',
                    borderWidth: 1
                }
            }
        };
        return dataTurnover;
    }


    const getRenderSatisfaction = (value) => {
        if (value >= 0) {
            if (value >= 50) {
                return <span style={{ color: '#008000' }}>{value}%</span>;
            } else {
                return <span style={{ color: '#dc3545' }}>{value}%</span>;
            }
        } else {
            return 'N/A';
        }
    };

    const changeSearchSlider = (direction = null) => {
        let newStartAt;

        if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
        else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
        else newStartAt = startAt;

        newStartAt = moment(newStartAt.startOf('year').format());

        setTextSearchSlider(newStartAt.format('YYYY'));
        setStartAt(newStartAt);
    };

    const convertPercentage = (value1, value2) => {
        if (value1 > 0 && value2 > 0) {
            return <span>{(value1 * 100 / value2).toFixed(2)}% <span style={{ fontSize: 10 }}>({value1})</span></span>;
        } else {
            return <span>0% <span style={{ fontSize: 10 }}>({value1})</span></span>;
        }
    };
    const rows2 = [
        ['Médecin', 20, 159, 6.0, 24, 4.0, 1414, 20, 159, 6.0, 24, 4.0, 1414],
        ['Idel', 45757, 237, 9.0, 37, 2452, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Dentiste', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Kinésithérpeute', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Orthophonistes', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Rhumatologue', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452],
        ['Divers', 257, 262, 16.0, 24, 55, 2452, 45757, 237, 9.0, 37, 2452, 2452]
    ];
    // Get Info all chart
    const getInfo = () => {
        if (startAt) {
            dispatch(LinearProgressMainLayoutActivate());
            let data = handlerSearch.getData();
            Api.get({
                route: 'institution_health_investor_info',
                data: {
                    year: startAt.format('YYYY'),
                    organism: (organism === 'all') ? '' : organism,
                    financeType: (financeType === 'all') ? '' : financeType,
                    commercials: (!data.commercial) ? '' : data.commercial
                }
            },
                (response) => {
                    if (response && response.data) {
                        setNumberRegistrations(response.data.numberRegistrations ? response.data.numberRegistrations : {});
                        setNumberPlanneds(response.data.numberPlanneds ? response.data.numberPlanneds : {});
                        setNumberCompleteds(response.data.numberCompleteds ? response.data.numberCompleteds : {});
                        setNumberCAs(response.data.numberCAs ? response.data.numberCAs : {});
                        setNumberCAsBilling(response.data.numberCAsBilling ? response.data.numberCAsBilling : {});
                        setNumberSatisfactions(response.data.numberSatisfactions ? response.data.numberSatisfactions : {});
                        setNumberNewClients(response.data.numberNewClients ? response.data.numberNewClients : {});
                        setNumberBilling(response.data.numberBilling ? response.data.numberBilling : {});
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setReadyContent(true);
                });
        }
    };

    const render = (type) => {
        let RenderTable = Object.keys(formationGroups).map((formationGroup, index) => {
            let RenderCell = Object.keys(type.year)
                .sort((a, b) => a < b ? -1 : 1)
                .filter((month) => month !== 'total' && month !== 'totalPercentage')
                .map((month) => {
                    return (
                        <TableCell className={classes.td}>{type['year'][month][formationGroups[formationGroup].index]}</TableCell>
                    )
                });

            return (
                <TableRow>
                    <TableCell padding="checkbox" />
                    <TableCell key={index} className={classes.thSize}>{formationGroups[formationGroup].text}</TableCell>
                    {RenderCell}
                </TableRow>
            )
        });
        return RenderTable;
    }

    const renderTableRow = () => {
        return Object.keys(numberRegistrations['types']).map((type, index) => {
            let RenderCell = Object.keys(numberRegistrations['types'][type]['year'])
                .sort((a, b) => a < b ? -1 : 1)
                .map((month) => {
                    return (
                        <TableCell className={classes.td}>{numberRegistrations['types'][type]['year'][month]['total']}</TableCell>
                    )
                });

            return <ExpandableTableRow key={index} name={numberRegistrations['types'][type].name} children={RenderCell} expandComponent={render(numberRegistrations['types'][type])} />;
        });
    }

    const getDownloadExcel = (typeArray) => {
        let data = handlerSearch.getData();
        setLoadDownload(true);
        Api.getDownload({
            route: 'institution_health_investor_info',
            filename: ((typeArray === 'registrations') ? 'export_nombre_d_inscription_' : 'export_taux_de_complétion_') + startAt.format('YYYY') + '.xls',
            data: {
                year: startAt.format('YYYY'),
                organism: (organism === 'all') ? '' : organism,
                financeType: (financeType === 'all') ? '' : financeType,
                commercials: (!data.commercial) ? '' : data.commercial,
                typeArray: typeArray
            },
        }, (response) => {
            if (response.status === 200) {
                setLoadDownload(false)
            }
        })
    }

    React.useEffect(() => {
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    if (response && response.data) {
                        for (let index in response.data) {
                            if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                                data.push(response.data[index]);
                            }
                        }
                        setOptionOrganisms(data);
                    }
                }
            });

        Api.get({
            route: 'select_finance_types',
            data: { 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(parseInt(response.data[index].value), authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionFinanceTypes(data);
                }
            });

        Api.get({
            route: 'institution_health_crm_commercial_list',
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([{ value: 'all', label: 'Tous' }, { value: 0, label: 'Aucun' }, ...response.data]);
                }
            });

    }, []);
    React.useEffect(changeSearchSlider, []);
    React.useEffect(getInfo, [startAt, organism, financeType, commercials]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Tableau de bord',
            context: 'Investisseur',
            path: getRoutePathname('institution_health_home'),
            description: 'Statistiques.'
        });
    }, []);
    //  <TrendingUp className={classes.up}/> <span className={classes.upText}>(+30)</span>
    //  <TrendingUp className={classes.down}/> <span className={classes.downText}>(-30)</span>
    //  <span className={classes.equalText}>(0)</span>

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <BoxPopupActionComponent
                className={classes.boxPopupAction}
                title={<TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />}
                textButton={{
                    text: <p style={{ margin: 0, color: '#FFF', fontSize: 14 }}>Filtre</p>,
                    icon: <FilterListIcon style={{ color: '#FFF', margin: 'auto', transform: 'translate(-1%,5%)', width: 20, height: 'auto' }} />,
                    style: { padding: '0 10px' },
                }}
                dimensionsButton={{ width: 'fit-content', height: 30 }}
                isBoxPopup={true}
                closedBoxActive={false}
                direction={'top'}
                template={
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Organisme</InputLabel>
                                <Select
                                    native
                                    value={organism}
                                    onChange={(event) => setOrganism(event.target.value)}
                                    label="Organisme"
                                    inputProps={{ name: 'Organisme', id: 'outlined-age-native-simple' }}
                                    className={classes.select}
                                >
                                    <option value={'all'}>Tous</option>
                                    {
                                        optionOrganisms && optionOrganisms.map((organism) => {
                                            return <option key={organism.value} value={organism.value}>{organism.label}</option>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <InputLabel htmlFor="outlined-age-native-simple">Type de financement</InputLabel>
                                <Select
                                    native
                                    value={financeType}
                                    onChange={(event) => setFinanceType(event.target.value)}
                                    label="Type de financement"
                                    inputProps={{ name: 'Type de financement', id: 'outlined-age-native-simple' }}
                                    className={classes.select}
                                >
                                    <option value={'all'}>Tous</option>
                                    {
                                        optionFinanceTypes && optionFinanceTypes.map((financeType) => {
                                            return <option key={financeType.value} value={financeType.value}>{financeType.label}</option>;
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <MultipleSelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercials} onChange={(val) => setCommercials(val)} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3} style={{ position: 'relative' }}>
                            <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')} />
                            <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                            <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')} />
                        </Grid>

                    </Grid>
                }
            />
            {(numberRegistrations && numberRegistrations.total && numberPlanneds && numberPlanneds.total) &&
                <>
                    <br />
                    <Grid container spacing={3}>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card
                                    title={'Inscriptions total validées'}
                                    number={Object.values(numberRegistrations.total).reduce((acc, val) => acc + val, 0)}
                                    turnover={Object.values(numberRegistrations.total).reduce((acc, val) => acc + val, 0)}
                                    color={'#000'}
                                    icon={<AssignmentIndIcon className={classes.icon} />}
                                    label={'Nombre total d\'inscriptions cette année validées'}
                                    labelEmpty={'Aucune d\'inscriptions cette année'}
                                />
                            </Grid>
                        </Zoom>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card
                                    title={'Inscriptions total brut'}
                                    number={Object.values(numberPlanneds.total).reduce((acc, val) => acc + val, 0)}
                                    turnover={Object.values(numberPlanneds.total).reduce((acc, val) => acc + val, 0)}
                                    color={'#000'}
                                    icon={<AssignmentIcon className={classes.icon} />}
                                    label={'Nombre totale d\'inscriptions brut cette année'}
                                    labelEmpty={'Aucune formation planifiée cette année'}
                                />
                            </Grid>
                        </Zoom>
                    </Grid>
                    <Grid container spacing={3}>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <ShadowBoxComponent>
                                    <Doughnut data={dataPercentageRegistration} options={dataPercentageRegistration.options} />
                                </ShadowBoxComponent>
                                <br />
                            </Grid>
                        </Zoom>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <ShadowBoxComponent>
                                    <Doughnut data={dataPercentagePlanned} options={dataPercentagePlanned.options} />
                                </ShadowBoxComponent>
                                <br />
                            </Grid>
                        </Zoom>
                    </Grid>
                </>
            }

            {(numberRegistrations && numberRegistrations.total) && <>
                <ShadowBoxComponent>
                    <div style={{ display: 'grid', gridTemplateColumns: '80% auto', gap: 14, marginBottom: 20, }}>
                        <div className={classes.titleStyle}>
                            <TitleComponent
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                        Nombre d'inscriptions par année
                                        <Tooltip title={'Cette liste donne un aperçu du nombre total de personnes inscrites par mois au cours de l\'année.'} enterDelay={500} leaveDelay={200}>
                                            <p style={{ margin: 0, padding: 0, background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</p>
                                        </Tooltip>
                                    </div>
                                }
                            />
                        </div>
                        <ButtonComponent label={'Télécharger au format Excel'} onClick={() => getDownloadExcel('registrations')} disabled={loadDownload} />
                    </div>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell className={classes.thSize}>Type</TableCell>
                                    <TableCell className={classes.th}>Décembre n-1</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                    <TableCell className={classes.th}>Janvier n+1</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderTableRow()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br />
            </>}

            {(numberPlanneds && numberPlanneds.total && numberCompleteds && numberCompleteds.total && numberBilling && numberBilling.total) &&
                <Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={5} lg={5}>
                            <ShadowBoxComponent style={{ height: '100%', display: 'flex', alignItems: 'center', }}>
                                <Grid container spacing={0}>
                                    <Box className={classes.containerButtonArrow}>
                                        <div className={classes.buttonArrow} onClick={() => goToPreviousMonth()}><ArrowBackIosIcon style={{ transform: 'translate(-32%,-50%)' }} /></div>
                                        <p><b>{month[currentMonth].text}</b></p>
                                        <div className={classes.buttonArrow} onClick={() => goToNextMonth()}><ArrowForwardIosIcon style={{ transform: 'translate(-44%,-50%)' }} /></div>
                                    </Box>
                                    <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '90%', margin: 'auto', padding: '12px 0' }}>
                                        <TitleComponent
                                            title={
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    Taux de complétion de formation par mois
                                                    <Tooltip title={'Cette liste de données présente le pourcentage de complétion des formations par mois au cours de l\'année.'} enterDelay={500} leaveDelay={200}>
                                                        <p style={{ margin: 0, padding: 0, background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</p>
                                                    </Tooltip>
                                                </div>
                                            } />
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={classes.thSize}>Profession</TableCell>
                                                        <TableCell className={classes.th}>Planifiées</TableCell>
                                                        <TableCell className={classes.th}>Terminées</TableCell>
                                                        <TableCell className={classes.th}>Facturées</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {formationGroups.map((formationGroup, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                                            <TableCell className={classes.td}>{numberPlanneds[month[currentMonth].index][formationGroup['index']]}</TableCell>
                                                            <TableCell className={classes.td}>{convertPercentage(numberCompleteds[month[currentMonth].index][formationGroup['index']], numberPlanneds[month[currentMonth].index][formationGroup['index']])}</TableCell>
                                                            <TableCell className={classes.td}>{convertPercentage(numberBilling[month[currentMonth].index][formationGroup['index']], numberPlanneds[month[currentMonth].index][formationGroup['index']])}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </ShadowBoxComponent>
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <ShadowBoxComponent style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <Bar data={getTurnover2(numberPlanneds, numberCompleteds, text_planneds_completeds)} options={getTurnover2(numberPlanneds, numberCompleteds, text_planneds_completeds).options} />
                            </ShadowBoxComponent>
                        </Grid>
                    </Zoom>
                </Grid>
            }

            <br />
            {(numberCAs && numberCAs.total && numberCAsBilling && numberCAsBilling.total) &&
                <>
                    <Grid container spacing={3}>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card
                                    title={'Total du chiffre d\'affaire prévisionnel'}
                                    number={Object.values(numberCAs.total).reduce((acc, val) => acc + val, 0)}
                                    turnover={Object.values(numberCAs.total).reduce((acc, val) => acc + val, 0)}
                                    color={'#000'}
                                    icon={<TrendingUpIcon className={classes.icon} />}
                                    label={'Nombre total de chiffre d\'affaire prévisionnel cette année'}
                                    labelEmpty={'Aucune chiffre d\'affaire prévisionnel cette année'}
                                />
                            </Grid>
                        </Zoom>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={6} md={6} lg={6}>
                                <Card
                                    title={'Total de chiffre d\'affaire facturé'}
                                    number={Object.values(numberCAsBilling.total).reduce((acc, val) => acc + val, 0)}
                                    turnover={Object.values(numberCAsBilling.total).reduce((acc, val) => acc + val, 0)}
                                    color={'#000'}
                                    icon={<TrendingUpIcon className={classes.icon} />}
                                    label={'Nombre totale de chiffre d\'affaire facturé cette année'}
                                    labelEmpty={'Aucune chiffre d\'affaire facturé cette année'}
                                />
                            </Grid>
                        </Zoom>
                    </Grid>
                    <Grid container spacing={3}>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <ShadowBoxComponent style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                    <Bar data={getTurnover2(numberCAs, numberCAsBilling, text_numberCAs_)} options={getTurnover2(numberCAs, numberCAsBilling, text_numberCAs_).options} />
                                </ShadowBoxComponent>
                            </Grid>
                        </Zoom>
                        <Zoom in={isReadyContent}>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <ShadowBoxComponent style={{ height: '100%', display: 'flex', alignItems: 'center', }}>
                                    <Grid container spacing={0}>
                                        <Box className={classes.containerButtonArrow}>
                                            <div className={classes.buttonArrow} onClick={() => goToPreviousMonth()}><ArrowBackIosIcon style={{ transform: 'translate(-32%,-50%)' }} /></div>
                                            <p><b>{month[currentMonth].text}</b></p>
                                            <div className={classes.buttonArrow} onClick={() => goToNextMonth()}><ArrowForwardIosIcon style={{ transform: 'translate(-44%,-50%)' }} /></div>
                                        </Box>
                                        <Grid item xs={12} sm={12} md={12} lg={12} style={{ width: '90%', margin: 'auto', padding: '12px 0' }}>
                                            <TitleComponent title={
                                                <>
                                                    chiffre d'affaire prévisionnel / chiffre d'affaire facturé par mois
                                                    <Tooltip title={'Cette liste de données présente les chiffres d\'affaires prévisionnels et les chiffres d\'affaires effectivement facturés par mois au cours de l\'année.'} enterDelay={500} leaveDelay={200}>
                                                        <span style={{ margin: '0 5px', padding: '0px 7px', background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</span>
                                                    </Tooltip>
                                                </>
                                            } />
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className={classes.thSize}>Profession</TableCell>
                                                            <TableCell className={classes.th}>CA prévisionnel </TableCell>
                                                            <TableCell className={classes.th}>CA Facturé</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {formationGroups.map((formationGroup, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                                                <TableCell className={classes.td}>{numberCAs[month[currentMonth].index][formationGroup['index']]}</TableCell>
                                                                <TableCell className={classes.td}>{numberCAsBilling[month[currentMonth].index][formationGroup['index']]}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    </Grid>
                                </ShadowBoxComponent>
                            </Grid>
                        </Zoom>
                    </Grid>
                </>
            }
            <br />
            {(numberPlanneds && numberPlanneds.total && numberCompleteds && numberCompleteds.total && numberCAs && numberCAs.total) &&
                <ShadowBoxComponent>
                    <div style={{ display: 'grid', gridTemplateColumns: '80% auto', gap: 14, marginBottom: 20, }}>
                        <div className={classes.titleStyle}>
                            <TitleComponent
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                        Taux de complétion de formation
                                        <Tooltip title={'Cette liste de données présente le pourcentage de complétion des formations par mois au cours de l\'année.'} enterDelay={500} leaveDelay={200}>
                                            <p style={{ margin: 0, padding: 0, background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</p>
                                        </Tooltip>
                                    </div>
                                }
                            />
                        </div>
                        <ButtonComponent label={'Télécharger au format Excel'} onClick={() => getDownloadExcel('completeds')} disabled={loadDownload} />
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Janvier (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Janvier (CA)</TableCell>
                                    <TableCell className={classes.th}>Février (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Février (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Février (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['01'][formationGroup['index']], numberPlanneds['01'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['02'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['02'][formationGroup['index']], numberPlanneds['02'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['02'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mars (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Mars (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Mars (CA)</TableCell>
                                    <TableCell className={classes.th}>Avril (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Avril (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Avril (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['03'][formationGroup['index']], numberPlanneds['03'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['04'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['04'][formationGroup['index']], numberPlanneds['04'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['04'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mai (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Mai (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Mai (CA)</TableCell>
                                    <TableCell className={classes.th}>Juin (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Juin (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Juin (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['05'][formationGroup['index']], numberPlanneds['05'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['06'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['06'][formationGroup['index']], numberPlanneds['06'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['06'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Juillet (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Juillet (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Juillet (CA)</TableCell>
                                    <TableCell className={classes.th}>Août (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Août (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Août (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['07'][formationGroup['index']], numberPlanneds['07'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['08'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['08'][formationGroup['index']], numberPlanneds['08'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['08'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Septembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Septembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Septembre (CA)</TableCell>
                                    <TableCell className={classes.th}>Octobre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Octobre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Octobre (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['09'][formationGroup['index']], numberPlanneds['09'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['10'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['10'][formationGroup['index']], numberPlanneds['10'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['10'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Novembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Novembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Novembre (CA)</TableCell>
                                    <TableCell className={classes.th}>Décembre (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Décembre (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Décembre (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['11'][formationGroup['index']], numberPlanneds['11'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['12'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['12'][formationGroup['index']], numberPlanneds['12'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['12'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Année N+1 (Planifiées)</TableCell>
                                    <TableCell className={classes.th}>Année N+1 (Terminées)</TableCell>
                                    <TableCell className={classes.th}>Année N+1 (CA)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberPlanneds['13'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{convertPercentage(numberCompleteds['13'][formationGroup['index']], numberPlanneds['13'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{numberCAs['13'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>
            }
            <br />
            {(numberNewClients && numberNewClients['NewClients']) && <>
                {<Grid container spacing={3}>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Nouveau client'}
                                number={Object.values(numberNewClients['NewClients'].total).reduce((acc, val) => acc + val, 0)}
                                turnover={Object.values(numberNewClients['NewClients'].total).reduce((acc, val) => acc + val, 0)}
                                color={'#000'}
                                label={'Nombre nouveau client cette année'}
                                labelEmpty={'Aucun nouveau client cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Client N-1'}
                                number={Object.values(numberNewClients['NewClientsN-1'].total).reduce((acc, val) => acc + val, 0)}
                                turnover={Object.values(numberNewClients['NewClientsN-1'].total).reduce((acc, val) => acc + val, 0)}
                                color={'#000'}
                                label={'Nombre client N-1 cette année'}
                                labelEmpty={'Aucun client N-1 cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Client N-2'}
                                number={Object.values(numberNewClients['NewClientsN-2'].total).reduce((acc, val) => acc + val, 0)}
                                turnover={Object.values(numberNewClients['NewClientsN-2'].total).reduce((acc, val) => acc + val, 0)}
                                color={'#000'}
                                label={'Nombre client n-2 cette année'}
                                labelEmpty={'Aucun client n-2 cette année'}
                            />
                        </Grid>
                    </Zoom>
                    <Zoom in={isReadyContent}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <Card
                                title={'Client < N-2'}
                                number={Object.values(numberNewClients['NewClients<N-2'].total).reduce((acc, val) => acc + val, 0)}
                                turnover={Object.values(numberNewClients['NewClients<N-2'].total).reduce((acc, val) => acc + val, 0)}
                                color={'#000'}
                                label={'Nombre client < N-2 cette année'}
                                labelEmpty={'Aucun client < N-2 cette année'}
                            />
                        </Grid>
                    </Zoom>
                </Grid>}

                <br />

                {<ShadowBoxComponent>
                    <TitleComponent
                        title={
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                Répartition des clients cette année
                                <Tooltip title={'Cette liste de données indique le total de nouveaux inscrits à une formation par mois au cours de l\'année, tout en vérifiant si ces nouveaux inscrits ont déjà suivi une formation les années précédentes.'} enterDelay={500} leaveDelay={200}>
                                    <p style={{ margin: 0, padding: 0, background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</p>
                                </Tooltip>
                            </div>
                        }
                    />

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Janvier N-1</TableCell>
                                    <TableCell className={classes.th}>Janvier N-2</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Février N-1</TableCell>
                                    <TableCell className={classes.th}>Février N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['01'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['02'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['02'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['02'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Mars N-1</TableCell>
                                    <TableCell className={classes.th}>Mars N-2</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Avril N-1</TableCell>
                                    <TableCell className={classes.th}>Avril N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['03'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['04'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['04'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['04'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Mai N-1</TableCell>
                                    <TableCell className={classes.th}>Mai N-2</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juin N-1</TableCell>
                                    <TableCell className={classes.th}>Juin N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['05'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['06'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['06'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['06'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Juillet N-1</TableCell>
                                    <TableCell className={classes.th}>Juillet N-2</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Août N-1</TableCell>
                                    <TableCell className={classes.th}>Août N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['07'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['08'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['08'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['08'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Septembre N-1</TableCell>
                                    <TableCell className={classes.th}>Septembre N-2</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Octobre N-1</TableCell>
                                    <TableCell className={classes.th}>Octobre N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['09'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['10'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['10'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['10'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Novembre N-1</TableCell>
                                    <TableCell className={classes.th}>Novembre N-2</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                    <TableCell className={classes.th}>Décembre N-1</TableCell>
                                    <TableCell className={classes.th}>Décembre N-2</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['11'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClients']['12'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-1']['12'][formationGroup['index']]}</TableCell>
                                        <TableCell className={classes.td}>{numberNewClients['NewClientsN-2']['12'][formationGroup['index']]}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>}

                <br />
            </>}

            {(numberSatisfactions && numberSatisfactions.total) && <>
                <ShadowBoxComponent>
                    <TitleComponent
                        title={
                            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                                Taux de satisfaction par année
                                <Tooltip title={'Cette liste indique taux de satisfaction des inscrits à une formation par mois au cours de l\'année.'} enterDelay={500} leaveDelay={200}>
                                    <p style={{ margin: 0, padding: 0, background: '#FFF', boxShadow: '0px 0px 2px', borderRadius: 100, width: 17, textAlign: 'center', fontSize: 14, cursor: 'pointer' }}>i</p>
                                </Tooltip>
                            </div>
                        }
                    />

                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.thSize}>Profession</TableCell>
                                    <TableCell className={classes.th}>Janvier</TableCell>
                                    <TableCell className={classes.th}>Février</TableCell>
                                    <TableCell className={classes.th}>Mars</TableCell>
                                    <TableCell className={classes.th}>Avril</TableCell>
                                    <TableCell className={classes.th}>Mai</TableCell>
                                    <TableCell className={classes.th}>Juin</TableCell>
                                    <TableCell className={classes.th}>Juillet</TableCell>
                                    <TableCell className={classes.th}>Août</TableCell>
                                    <TableCell className={classes.th}>Septembre</TableCell>
                                    <TableCell className={classes.th}>Octobre</TableCell>
                                    <TableCell className={classes.th}>Novembre</TableCell>
                                    <TableCell className={classes.th}>Décembre</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formationGroups.map((formationGroup, index) => (
                                    <TableRow key={index}>
                                        <TableCell className={classes.thSize}>{formationGroup['text']}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['01'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['02'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['03'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['04'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['05'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['06'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['07'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['08'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['09'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['10'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['11'][formationGroup['index']])}</TableCell>
                                        <TableCell className={classes.td}>{getRenderSatisfaction(numberSatisfactions['12'][formationGroup['index']])}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ShadowBoxComponent>

                <br />
            </>}

        </ContentViewComponent>
    );
}

export default Investor;
