import React from 'react';
import { Box, IconButton, makeStyles, Drawer as DrawerMUI } from '@material-ui/core';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutePathname } from '../../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../../Component/ButtonComponent';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import Api from '../../../../Api';
import { TableReload } from '../../../../Action/TableAction';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../../Component/SelectComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import SelectAutocompleteComponent from "../../../../Component/SelectAutocompleteComponent";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ContactsIcon from '@material-ui/icons/Contacts';
import BillingFinancialMix from './BillingFinancialMix';
import BillingFinancialPersonal from './BillingFinancialPersonal';

export default function BillingInstitutionMixtList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isOpen, setIsOpen] = React.useState(false);
    const [isFinancial, setIsFinancial] = React.useState(cacheTableServer.institution_health_isfinancial ?? false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [optionCommercial, setOptionCommercial] = React.useState([]);
    const [optionGroup, setOptionGroup] = React.useState([]);
    const [optionType, setOptionType] = React.useState([]);
    const [optionFinanceTypes, setOptionFinanceTypes] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const [formSearch, setFormSearch] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].commercial ? cacheTableServer['institution_health_financial_management_billing_institution_list'].commercial : 'all',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez une ville.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].city ? cacheTableServer['institution_health_financial_management_billing_institution_list'].city : '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez un téléphone.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].phone ? cacheTableServer['institution_health_financial_management_billing_institution_list'].phone : '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal de l\’établissement',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].zipCode ? cacheTableServer['institution_health_financial_management_billing_institution_list'].zipCode : '',
            options: {}
        },
        group: {
            name: 'group',
            label: 'Groupement d’établissement',
            textHelper: 'Trier par groupement d’établissement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].group ? cacheTableServer['institution_health_financial_management_billing_institution_list'].group : 'all',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type d’établissement',
            textHelper: 'Trier par Type d’établissement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_institution_list'] && cacheTableServer['institution_health_financial_management_billing_institution_list'].type ? cacheTableServer['institution_health_financial_management_billing_institution_list'].type : 'all',
            options: {}
        },
    });

    React.useEffect(() => {
        Api.get({
            route: 'institution_health_crm_commercial_list',
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercial([...[{ value: 'all', label: 'Tous' }, { value: '0', label: 'Aucun' }], ...response.data]);;
                }
            });
        Api.get({
            route: 'institution_groups',
        },
            (response) => {
                if (response && response.data) {
                    setOptionGroup([...[{ value: 'all', label: 'Tous' }, { value: '0', label: 'Aucun' }], ...response.data]);
                }
            });
        Api.get({
            route: 'institution_types',
        },
            (response) => {
                if (response && response.data) {
                    setOptionType([...[{ value: 'all', label: 'Tous' }, ...response.data]]);
                }
            });
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true, 'isInstitution': true }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceTypes(response.data);
                }
            });
    }, [])

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_financial_management_billing_institution_list'] = 0;
        cacheTableServer['institution_health_financial_management_billing_institution_list'].page = 0;
        cacheTableServer['institution_health_financial_management_billing_institution_list'][index] = value;
        dispatch(TableReload('institution_health_financial_management_billing_institution_list'))
    };

    const getFilterValues = (options) => {
        let data = handlerSearch.getData();
        for (const key in data) {
            if (key === 'group') {
                if (data[key] === '0') {
                    data.isGroupLess = true;
                    data[key] = null
                } else {
                    data.isGroupLess = null;
                    data[key] = data[key] === 'all' ? null : data[key]
                }
            } else if (
                key !== 'phone'
                && key !== 'zipCode'
                && key !== 'city'
                && data[key] === 'all'
            ) {
                data[key] = null;
            }
        }
        let optionsFilter = {}
        if (options) {
            optionsFilter = {
                text: options.searchText,
                limit: options.rowsPerPage,
                offset: options.page * options.rowsPerPage,
                sortName: options.sortOrder.name,
                sortDirection: options.sortOrder.direction
            }
        } else {
            optionsFilter = {
                limit: 10000,
            }
        }

        const filters = {
            ...data,
            ...optionsFilter
        };
        return filters
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box style={{
                    margin: 0,
                    display: 'flex',
                    alignItems: 'center',
                    padding: '19px 19px 10px 19px',
                    color: '#5E6E82', opacity: 0.7, fontSize: 14
                }}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'commercial'} handler={handlerSearch} options={optionCommercial} onChange={(val) => inputSearchChange('commercial', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'city'} handler={handlerSearch} onChange={(val) => inputSearchChange('city', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'zipCode'} handler={handlerSearch} onChange={(val) => inputSearchChange('zipCode', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'phone'} handler={handlerSearch} onChange={(val) => inputSearchChange('phone', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectAutocompleteComponent name={'group'} handler={handlerSearch} options={optionGroup} onChange={(val) => inputSearchChange('group', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'type'} handler={handlerSearch} options={optionType} onChange={(val) => inputSearchChange('type', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<><FilterListIcon style={{ color: '#FFFFFF' }} /> Filtre</>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Facturation Mixte',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des établissements',
            link: [
                { label: 'Gestion financière' }
            ],
            html: <GetActionMore />
        });
        setReadyContent(true);
    }, [isOpen, isFinancial]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <BillingFinancialMix getFilterValues={getFilterValues} {...props} />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },

}))