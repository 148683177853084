import { Box, Checkbox, IconButton, Slide, Tooltip, makeStyles } from '@material-ui/core';
import TextFieldComponent from "../../../../../Component/TextFieldComponent";
import React from "react";
import SelectComponent from '../../../../../Component/SelectComponent';
import { formHandlerInit } from '../../../../../Tool/FormHandlerCommon';
import BackspaceIcon from '@material-ui/icons/Backspace';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';

const CardComponent = (props) => {
    const classes = useStyles(props.dataInstitution)();
    const data = props.data;
    const handler = props.handler;
    const [isOpenComment, setIsOpenComment] = React.useState(false);
    const [isInscriptionFeeHt, setIsInscriptionFeeHt] = React.useState(true);
    const [isEducationalFeeHt, setIsEducationalFeeHt] = React.useState(true);
    const [textComment, setTextComment] = React.useState('');
    const [values, setValues] = React.useState('');
    const [error, setError] = React.useState([]);

    const description = (value) => {
        const input = value ? value.trim() : '';
        if (input === '') {
            setTextComment('Aucun commentaire...');
        } else {
            setTextComment(input);
        }
    }

    const calculateHT = (price, discount, isPercentage) => {
        const amount = isPercentage ? price.toFixed(2) - (price.toFixed(2) * discount / 100) : price.toFixed(2) - discount;
        return amount;
    };

    const calculateTTC = (price) => {
        return price + (price * ((props.tva ?? 0) / 100));
    }

    const getInitialValues = () => {
        const fields = [
            { key: 'inscriptionFeeHt', discountKey: 'inscriptionDiscount' },
            { key: 'educationalFeeHt', discountKey: 'educationalDiscount' },
            { key: 'supportAmount' }
        ];

        let values = { inscriptionFee: 0, inscriptionFeeRemainHT: 0, educationalFee: 0, educationalFeeRemainHt: 0, supportAmountHT: 0, totalHT: 0, remainHT: 0 };

        fields.forEach(field => {
            const { key, discountKey } = field;
            const value = parseFloat(handler.form[`${data.id}-${key}`]?.value || 0);

            if (discountKey) {
                const discount = parseFloat(handler.form[`${data.id}-${discountKey}`]?.value || 0);
                const isPercentage = handler.form[`${data.id}-${discountKey}`]?.isPercentage;
                const amountHT = calculateHT(value, discount, isPercentage);
                if (key === 'inscriptionFeeHt') {
                    values.inscriptionFeeRemainHT += amountHT;
                } else if (key === 'educationalFeeHt') {
                    values.educationalFeeRemainHt += amountHT;
                }
            } else {
                values.remainHT -= value;
                values.supportAmountHT = value;
            }
        });

        values.educationalFee = values.educationalFeeRemainHt;
        onChangeError(values.educationalFee, `${data.id}-educationalFeeHt`)
        values['educationalFeeTTC'] = calculateTTC(values.educationalFeeRemainHt);

        values.inscriptionFee = values.inscriptionFeeRemainHT;
        onChangeError(values.inscriptionFee, `${data.id}-inscriptionFeeHt`)
        values['inscriptionFeeTTC'] = calculateTTC(values.inscriptionFeeRemainHT);

        values.educationalFeeRemainHt = isEducationalFeeHt ? values.educationalFeeRemainHt : 0;
        values.inscriptionFeeRemainHT = isInscriptionFeeHt ? values.inscriptionFeeRemainHT : 0;

        values.remainHT += values.educationalFeeRemainHt;
        values.totalHT = values.inscriptionFeeRemainHT + values.remainHT;

        values['supportAmountTTC'] = calculateTTC(values.supportAmountHT);

        values.remainHT = values.remainHT;
        onChangeError(values.remainHT, `${data.id}-remainHT`)
        values['remainTTC'] = calculateTTC(values.remainHT);
        values.totalHT = values.totalHT < 0 ? 0 : values.totalHT;
        values['totalTTC'] = calculateTTC(values.totalHT);

        handler.form[`${data.id}-inscriptionFeeRemainHt`] = { value: values.inscriptionFeeRemainHT.toFixed(2), isValidAmount: isInscriptionFeeHt, registrationId: data.id };
        handler.form[`${data.id}-educationalFeeRemainHt`] = { value: values.educationalFeeRemainHt.toFixed(2), isValidAmount: isEducationalFeeHt, registrationId: data.id };
        handler.form[`${data.id}-educationalRemain`] = { value: values.remainHT.toFixed(2), registrationId: data.id };
        handler.form[`${data.id}-remainHt`] = { value: values.totalHT.toFixed(2), registrationId: data.id };

        props.setTotal((prev) => {
            return { ...prev, [data.id]: values.totalHT.toFixed(2) }
        });

        return values;
    };

    const updateErrorList = (prev, input, value) => {
        const alreadyInErrors = prev.includes(input);

        if (typeof value === 'number' && value < 0) {
            return alreadyInErrors ? prev : [...prev, input];
        } else {
            return alreadyInErrors ? prev.filter(item => item !== input) : prev;
        }
    };

    const onChangeError = (value, input) => {
        setError((prev) => updateErrorList(prev, input, value));
        props.setErrorData((prev) => updateErrorList(prev, input, value));
    };

    const onChange = (value, name, type) => {
        if (type === 'unit') {
            handler.form[name].isPercentage = value == 1;
        } else {
            handler.form[name].value = value;
        }
        const differences = findDifferences(handler.form, props.dataInstitution.registrationsBilled);
        props.setIsActiveBilled(differences.length != 0);
        setValues(getInitialValues());
    };

    const removeData = () => {
        props.onClick(data);
        setIsOpenComment(false);
        setError((prev) => {
            return prev.filter(item => !item.startsWith(data.id + "-"))
        });
        props.setErrorData((prev) => {
            return prev.filter(item => !item.startsWith(data.id + "-"))
        });
    }

    React.useEffect(() => {
        if (!props.isValidInvoice) {
            setIsEducationalFeeHt((props.dataInstitution.invoiceId ? true : false) ? data.hasEducational : true);
            setIsInscriptionFeeHt((props.dataInstitution.invoiceId ? true : false) ? data.hasInscription : true);
        }
    }, [props.isValidInvoice, data]);

    React.useEffect(() => {
        setValues(getInitialValues());
        if (props.dataInstitution.registrationsBilled) {
            const differences = findDifferences(handler.form, props.dataInstitution.registrationsBilled);
            props.setIsActiveBilled(differences.length != 0);
        }
    }, [handler.form, isEducationalFeeHt, isInscriptionFeeHt, props.tva]);

    React.useEffect(() => {
        description(data.comment);
    }, [data]);

    return (
        <>
            <Box style={{ borderBottom: '1px solid #A3ADB7', padding: '0px', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center', gap: 0 }}>
                <p style={{ margin: '15px 20px', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>Formation : {data.formationName ?? '-'}</p>
                {!props.loading &&
                    <Box onClick={() => { removeData() }} style={{ borderRadius: 5, cursor: 'pointer', margin: '15px 20px 15px 0' }}>
                        <BackspaceIcon style={{ margin: 'auto', display: 'block', color: '#E05059', fontSize: 25 }} />
                    </Box>
                }
            </Box>
            <Box style={{ padding: '10px 20px 0px' }}>
                <p style={{ margin: 0, fontSize: 12 }}>Nom/Prénom</p>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{`${data.lastname ?? ''} ${data.firstname ?? ''}`}</p>
            </Box>
            <Box style={{ padding: '10px 20px 5px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold', }}>Session : {moment(data.sessionStartAt.date).format('ll')} au {moment(data.sessionEndAt.date).format('ll')}</p>
                <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold', }}>(tva: {(props.tva == "" ? 0 : props.tva)}%)</p>
            </Box>
            <Box style={{ padding: '0px 20px 20px' }}>
                <table className={classes.priceRoot}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ textAlign: 'center' }}>P.U. HT</th>
                            <th style={{ textAlign: 'center' }}>REMISE</th>
                            <th style={{ textAlign: 'center' }}>Montant<sup>HT</sup></th>
                            <th style={{ textAlign: 'center' }}>Montant<sup>TTC</sup></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div>Frais d’inscription</div></td>
                            <td><div style={{ justifyContent: 'center' }}><span>{handler.form[`${data.id}-inscriptionFeeHt`] && <TextFieldComponent name={`${data.id}-inscriptionFeeHt`} handler={handler} onChange={(value) => onChange(value, `${data.id}-inscriptionFeeHt`, null)} disabled={props.loading} />}</span></div></td>
                            <td><div><span style={{ margin: 'auto', transform: 'translate(10%)' }}>{handler.form[`${data.id}-inscriptionDiscount`] && <SelectDiscount name={`${data.id}-inscriptionDiscount`} handler={handler} onChange={onChange} disabled={props.loading} />}</span></div></td>
                            {(values.inscriptionFee == 0) || (values.educationalFee == 0) ?
                                <td><span>{values.inscriptionFee?.toFixed(2)}</span></td> :
                                <td className={(props.isValidInvoice || (props.dataInstitution.invoiceId ? true : false)) && classes.costValid}
                                    style={{ position: 'relative', border: error.some((prev) => prev === `${data.id}-inscriptionFeeHt`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                    <span>{values.inscriptionFee?.toFixed(2)}</span>
                                    {error.some((prev) => prev === `${data.id}-inscriptionFeeHt`) && <ErrorIcon className={classes.ErrorIcon} />}
                                </td>
                            }
                            <td style={{ position: 'relative' }}><span>{values.inscriptionFeeTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div>Coût pédagogique</div></td>
                            <td><div style={{ justifyContent: 'center' }}><span>{handler.form[`${data.id}-educationalFeeHt`] && <TextFieldComponent name={`${data.id}-educationalFeeHt`} handler={handler} onChange={(value) => onChange(value, `${data.id}-educationalFeeHt`, null)} disabled={props.loading} />}</span></div></td>
                            <td><div><span style={{ margin: 'auto', transform: 'translate(10%)' }}>{handler.form[`${data.id}-educationalDiscount`] && <SelectDiscount name={`${data.id}-educationalDiscount`} handler={handler} onChange={onChange} disabled={props.loading} />}</span></div></td>
                            <td style={{ position: 'relative', border: error.some((prev) => prev === `${data.id}-educationalFeeHt`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                <span>{values.educationalFee?.toFixed(2)}</span>
                                {error.some((prev) => prev === `${data.id}-educationalFeeHt`) && <ErrorIcon className={classes.ErrorIcon} />}
                            </td>
                            <td><span>{values.educationalFeeTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td><div>Prise en charge : <br /><b>{data.financeTypeName}</b></div></td>
                            <td><div style={{ justifyContent: 'center' }}><span>{handler.form[`${data.id}-supportAmount`] && <TextFieldComponent name={`${data.id}-supportAmount`} handler={handler} onChange={(value) => onChange(value, `${data.id}-supportAmount`, null)} disabled={props.loading} />}</span></div></td>
                            <td></td>
                            <td><span>{values.supportAmountHT != 0 && '-'}{values.supportAmountHT?.toFixed(2)}</span></td>
                            <td><span>{values.supportAmountTTC != 0 && '-'}{values.supportAmountTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td><div>Reste à Charge</div></td>
                            <td></td>
                            <td></td>
                            {(values.inscriptionFee == 0) || (values.educationalFee == 0) ?
                                <td style={{ position: 'relative', border: error.some((prev) => prev === `${data.id}-remainHT`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                    <span>{values.remainHT?.toFixed(2)}</span> {error.some((prev) => prev === `${data.id}-remainHT`) && <ErrorIcon className={classes.ErrorIcon} />}
                                </td> :
                                <td className={(props.isValidInvoice || (props.dataInstitution.invoiceId ? true : false)) && classes.costValid}
                                    style={{ position: 'relative', border: error.some((prev) => prev === `${data.id}-remainHT`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                    <span>{values.remainHT?.toFixed(2)}</span>
                                    {error.some((prev) => prev === `${data.id}-remainHT`) && <ErrorIcon className={classes.ErrorIcon} />}
                                </td>
                            }
                            <td><span>{values.remainTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div><b>TOTAL</b></div></td>
                            <td></td>
                            <td></td>
                            <td><span>{values.totalHT?.toFixed(2)}</span></td>
                            <td><span>{values.totalTTC?.toFixed(2)}</span></td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <Box style={{ borderTop: '1px solid #A3ADB7', background: '#E5E7EA', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center', margin: 'auto 0 0' }}>
                <Box style={{ overflow: 'hidden', padding: '10px 20px', }}>
                    <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold' }}>Commentaire :</p>
                    <p style={{ margin: 0, textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{textComment}</p>
                </Box>

                <Box style={{ position: 'relative', height: '100%' }}>
                    <Slide direction="left" in={isOpenComment} mountOnEnter unmountOnExit>
                        <Box className={classes.DescriptionPopup}>
                            {handler.form[`${data.id}-comment`] && <TextFieldComponent name={`${data.id}-comment`} handler={handler} onChange={(value) => { description(value) }} disabled={props.loading} multiline />}
                        </Box>
                    </Slide>
                    <Tooltip title={'Modifier'} placement="left">
                        <span className={classes.IconButtonRoot} style={{ background: '#5E6E82C4' }}>
                            <IconButton onClick={() => { setIsOpenComment(!isOpenComment) }}>
                                {!isOpenComment ?
                                    <EditIcon style={{ color: '#FFF' }} /> :
                                    <HighlightOffIcon style={{ color: '#FFF' }} />
                                }
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

const SelectDiscount = (props) => {
    const [form, setForm] = React.useState({
        unit: {
            name: 'unit',
            label: 'unité',
            type: 'integer',
            defaultValue: props.handler.form[props.name].isPercentage ? 1 : 2,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const optionUnit = [
        { value: 1, label: '%' },
        { value: 2, label: '€' }
    ];

    React.useEffect(() => {
        handler.form.unit.value = props.handler.form[props.name].isPercentage ? 1 : 2;
    }, [props.handler.form[props.name].isPercentage]);

    React.useEffect(handler.start, []);
    return (
        <>
            <TextFieldComponent name={props.name} handler={props.handler} onChange={(value) => { props.onChange && props.onChange(value, props.name, null) }} disabled={props.disabled} />
            <div className={'select-root'}>
                <SelectComponent name={'unit'} handler={handler} options={optionUnit} onChange={(value) => { props.onChange && props.onChange(value, props.name, 'unit') }} disabled={true} />
            </div>
        </>
    )
}

const findDifferences = (obj1, obj2) => {
    const diffs = [];
    if (obj2) {
        const obj2Data = obj2.reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
        }, {});

        const ids = Array.from(new Set(
            Object.keys(obj1).map((key) => key.split("-")[0])
        ));

        ids.forEach((id) => {
            if (!obj2Data[id]) return;

            for (let key in obj1) {
                if (obj1.hasOwnProperty(key) && key.startsWith(id)) {
                    const obj1Field = obj1[key];

                    if (!obj1Field.type) continue;

                    const genericFieldName = key.replace(`${id}-`, "");
                    let obj2Value = obj2Data[id][genericFieldName];

                    let obj1Value, unit1, unit2;
                    if (obj1Field.hasOwnProperty("isPercentage")) {
                        const isEuroComparison = !obj1Field.isPercentage;
                        unit1 = isEuroComparison ? "€" : "%";
                        unit2 = typeof obj2Value === "string" && obj2Value.includes('%') ? "%" : "€";

                        const numericObj1Value = parseFloat(obj1Field.value) || 0;
                        const numericObj2Value = parseFloat(obj2Value) || 0;

                        obj1Value = numericObj1Value + unit1;
                        obj2Value = numericObj2Value + unit2;

                        if (numericObj1Value !== numericObj2Value || unit1 !== unit2) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                                unitChanged: unit1 !== unit2
                            });
                        }
                    } else if (obj1Field.type === "float") {
                        obj1Value = parseFloat(obj1Field.value) || 0;
                        obj2Value = parseFloat(obj2Value) || 0;

                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    } else if (obj1Field.type === "text") {
                        obj1Value = obj1Field.value || "";
                        obj2Value = obj2Value || "";

                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    } else {
                        obj1Value = obj1Field.value;
                        if (obj1Value !== obj2Value) {
                            diffs.push({
                                field: key,
                                valueInObj1: obj1Value,
                                valueInObj2: obj2Value,
                            });
                        }
                    }
                }
            }
        });
    }
    return diffs;
};


const useStyles = (institution) => makeStyles({
    costValid: {
        background: 'rgb(40, 167, 69) !important',
        color: '#FFF',
        position: 'relative',
        transition: 'all .4s',
        '&:hover': {
            background: 'rgb(28, 125, 50) !important',
        },
    },
    costInValid: {
        background: 'rgb(221, 78, 78) !important',
        color: '#FFF',
        position: 'relative',
        transition: 'all .4s',
        '&:hover': {
            background: 'rgb(179, 65, 65) !important',
        },
        '&:hover::before': {
            opacity: 0
        },
        '&::before': {
            width: '95%',
            content: (institution.invoiceId ? true : false) ? "'Non payé'" : "'Annuler'",
            background: 'rgb(179, 65, 65)',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
            transition: 'all .4s',
            borderRadius: 5,
            textAlign: 'center',
            padding: '5px 0',
            fontWeight: 'bold'
        },
    },
    priceRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th:nth-of-type(1)::before': {
                width: '95%',
                height: 5,
                content: "' '",
                background: '#E5E7EA',
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 100,
            },
            '& th:nth-of-type(n+2)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& th:nth-of-type(2)': {
                borderRadius: '5px 0 0 0',
            },
            '& th:last-child': {
                borderRadius: '0 5px 0 0',
            },
        },
        '& tbody': {
            '& tr:nth-of-type(5) td:nth-of-type(2)': {
                borderRadius: '0px 0 0 5px',
            },
            '& tr:nth-of-type(5) td:last-child': {
                borderRadius: '0 0px 5px 0',
            },
            '& tr:nth-of-type(3) td:nth-of-type(2)': {
                borderRadius: '5px 0 0 0px',
            },
            '& tr:nth-of-type(3) td:last-child': {
                borderRadius: '0 5px 0px 0',
            },
            '& tr:nth-of-type(1) td:nth-of-type(2)': {
                borderRadius: '0 0 0 5px',
            },
            '& tr:nth-of-type(1) td:last-child': {
                borderRadius: '0 0 5px 0',
            },
            '& tr:nth-of-type(2) td, tr:nth-of-type(6) td': {
                padding: 5,
                background: '#FFF'
            },
            '& td:nth-of-type(n+2)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& tr:last-of-type td:nth-of-type(2)': {
                borderRadius: '5px 0 0 5px',
            },
            '& tr:last-of-type td:last-child': {
                borderRadius: '0 5px 5px 0',
            },
            '& td': {
                '& >div': {
                    fontSize: 15
                },
                '& p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    margin: 0,
                    flexWrap: 'wrap',
                    fontSize: 15
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& .MuiFormControl-root': {
            width: 60,
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& select': {
                padding: 0,
                background: 'transparent !important',
                color: '#5E6E82',
            },
            '& input': {
                padding: 6,
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
        '& .select-root': {
            '& fieldset': {
                padding: 0,
                display: 'none'
            },
            '& .MuiFormControl-root': {
                width: 11
            },
            '& .MuiSelect-icon': {
                right: -6,
                display: 'none'
            }
        },
    },
    IconButtonRoot: {
        display: 'flex',
        height: '100%',
        '& .MuiIconButton-root': {
            padding: '0 20px',
            borderRadius: 0
        }
    },
    DescriptionPopup: {
        position: 'absolute',
        bottom: 60,
        border: '1px solid rgb(163, 173, 183)',
        minWidth: 280,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        right: 5,
        '& textarea': {
            overflow: 'hidden auto !important',
            minHeight: 100,
            maxHeight: 200
        }
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '12px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
    ErrorIcon: {
        position: 'absolute',
        padding: 1,
        top: '50%',
        right: -1,
        transform: 'translate(50%,-50%)',
        color: '#E5E7EA',
        borderRadius: 100,
        background: '#E05059',
        zIndex: 1
    }
})

export default CardComponent;