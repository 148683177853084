import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import Checkbox from "@material-ui/core/Checkbox";
import {inArray} from "../../../Tool/ArrayCommon";
import {useDispatch} from "react-redux";
import {TableReload} from "../../../Action/TableAction";
import {SnackbarOpen} from "../../../Action/SnackbarAction";

let sessionFormationChecked = [];
let sessionFormationDisabled = [];
let datas = [];

export default function SessionFormation(props = {organismId: 0}) {
    const dispatch = useDispatch();
    const columnsFormations = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'name',
            label: 'Nom',
            options: {filter: true, sort: true}
        },
        {
            name: 'formationGroupName',
            label: 'Groupe',
            options: {filter: true, sort: true}
        },
        {
            name: '_action',
            label: 'Associée'
        }
    ];
    const [isReadyTable, setReadyTable] = React.useState(false);

    const getAction = (row, checked, disabled) => {
        return (
            <Checkbox
                key={row.id + '-' + new Date().getTime()}
                checked={checked}
                disabled={disabled}
                onChange={() => {
                    if (inArray(row.id, sessionFormationChecked)) {
                        let index = sessionFormationChecked.indexOf(row.id);
                        if (index > -1) {
                            sessionFormationChecked.splice(index, 1);
                        }
                    } else {
                        sessionFormationChecked.push(row.id);
                    }

                    dispatch(TableReload('administrator_session_formation_list'));
                }}
            />
        );
    };
    const getInfoSessionFormation = (id, sessionFormations) => {
        for (let index in sessionFormations) {
            if (sessionFormations[index].formation === id) {
                return {checked: true, disabled: sessionFormations[index].cannotBeDeleted};
            }
        }
        return false;
    };
    const getDatas = (resolve) => {
        if (datas.length > 0 && isReadyTable) {
            for (let index in datas) {
                datas[index]._action = getAction(datas[index], inArray(datas[index].id, sessionFormationChecked), inArray(datas[index].id, sessionFormationDisabled));
            }

            resolve([...datas]);
            datas = [...datas];
        } else {
            if (props.organismId) {
                Api.get({route: 'administrator_session_formation_list', data: {organismId: props.organismId, financeTypeId : props.financeTypeId}}, (response) => {
                    if (response.data) {
                        Api.get({route: 'administrator_session_session_formation_list', params: {id: props.match.params.id}}, (res) => {
                            let data = response.data;

                            for (let index in data) {
                                let infoFormation = getInfoSessionFormation(data[index].id, res.data);

                                if (infoFormation.checked) sessionFormationChecked.push(data[index].id);
                                if (infoFormation.disabled) sessionFormationDisabled.push(data[index].id);
                                data[index]._action = getAction(data[index], infoFormation.checked, infoFormation.disabled);
                            }

                            resolve([]);
                            resolve([...data]);
                            datas = [...data];
                        });

                    } else {
                        resolve([]);
                        datas = [];
                    }
                })
            } else {
                resolve([]);
                datas = [];
            }

            setReadyTable(true);
        }
    };
    const sendFormationsRelation = () => {
        Api.post({
                route: 'administrator_session_formations_relation',
                params: {id: props.match.params.id},
                data: {formations: sessionFormationChecked}
            },
            (response) => {
                if (response.status === 200) {
                    if (response.data.errorRelation && response.data.errorRelation.length > 0) {
                        const isSingleError = response.data.errorRelation.length === 1;
                        const errorRelation = isSingleError 
                            ? `La formation ${response.data.errorRelation[0]} n'a pas pu être retirée car des inscriptions sont liées à cette session.` 
                            : `Les formations ${response.data.errorRelation.join(', ')} n'ont pas pu être retirées car des inscriptions sont liées à cette session.`;
                        dispatch(
                            SnackbarOpen({
                                text: errorRelation + ' Le reste des formations ont été traitées avec succès.',
                                variant: 'warning',
                                duration: 7000
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Formations associées.',
                                variant: 'success',
                            })
                        );
                    }
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const selectedAll = () => {
        for (let index in datas) {
            if (!inArray(datas[index].id, sessionFormationChecked)) {
                sessionFormationChecked.push(datas[index].id);
            }
        }

        dispatch(TableReload('administrator_session_formation_list'));
    };

    React.useEffect(() => {
        datas = [];
        sessionFormationChecked = [];
        sessionFormationDisabled = [];
    }, [])

    return (
        <TableComponent
            id={'administrator_session_formation_list'}
            title={'Formations'}
            columns={columnsFormations}
            promiseData={(resolve) => getDatas(resolve)}
            actionFirst={{
                label: 'Associer les formations',
                onClick: sendFormationsRelation
            }}
            actionSecond={{
                label: 'Tous sélectionner',
                onClick: selectedAll
            }}
            onBeforeRefresh={() => {
                datas = [];
                sessionFormationChecked = [];
                sessionFormationDisabled = [];
            }}
        />
    )
}
