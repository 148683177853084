import React from 'react';
import { useSelector } from "react-redux"
import { getRoutePathname } from "../../../../Config/Route"
import { OrganismIndex, Organisms } from '../../../../Organism'
import CardComponent from "./CardComponent"
import CardDashboardManagerComponent from "./CardDashboardManagerComponent"
import CardInstitutionComponent from "./CardInstitutionComponent"
import CardInstitutionGroupComponent from "./CardInstitutionGroupComponent"
import CardWelcomeComponent from "./CardWelcomeComponent"
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import CategoryIcon from '@material-ui/icons/Category';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ComputerIcon from '@material-ui/icons/Computer';
import DvrIcon from '@material-ui/icons/Dvr';
import SchoolIcon from '@material-ui/icons/School';
import ApartmentIcon from '@material-ui/icons/Apartment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { hasRight } from '../../../../Tool/UserTool';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CardRelaunchComponent from './CardRelaunchComponent';
import CloseIcon from '@material-ui/icons/Close';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import { Box, Dialog, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';

const getAccess = (props) => {
    const isAdmin = hasRight(props.authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ADMIN');
    return {
        isAdmin: isAdmin,
        isCrmAdmin: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN'),
        isCrm: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN') || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN'),
        isContentManager: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_CONTENT_MANAGER'),
        isFinancial: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ACCOUNTANT'),
        isAdministrator: isAdmin || hasRight(props.authenticationReducer, 'ROLE_INSTITUTION_ADMINISTRATOR'),
        isInstitution: hasRight(props.authenticationReducer, 'ROLE_INSTITUTION') && hasRight(props.authenticationReducer, 'ROLE_USER')
    }
}

export function CardWelcome(props) {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const organism = Organisms[OrganismIndex[selectOrganismReducer.organism.value]];
    return (
        <CardWelcomeComponent
            icon={organism ? <img src={'/asset/images/' + organism.icon} /> : ''}
            onClick={() => props.history.push(getRoutePathname('institution_health_admin_user', { id: props.authenticationReducer.id }))}
        />
    )
}

export function CardHotlines(props) {
    return (
        <CardComponent
            icon={<HeadsetMicIcon style={{ width: 17, height: 17 }} />}
            title={'Mes hotlines'}
            text={'Voir vos hotlines.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_hotline_list')) }
            }}
        />
    )
}


export function CardBilling(props) {
    const [isAddInstitution, setIsAddInstitution] = React.useState(false);
    const classes = useStyles();
    return (
        <>
            <CardComponent
                icon={<LibraryBooksIcon style={{ width: 20, height: 20 }} />}
                title={'Facturation'}
                text={'Liste des facturations.'}
                background={'#5E6E82'}
                color={'#FFF'}
                button={{
                    title: 'Accéder',
                    color: '#5E6E82',
                    background: '#FFFFFF',
                    onClick: () => { setIsAddInstitution(true); }
                }}
            />
            <Dialog open={isAddInstitution} maxWidth={'lg'} onClose={() => { setIsAddInstitution(false); }}>
                <Box>
                    <h1 style={{ color: '#5E6E82', fontSize: 23, margin: 0, padding: 20, display: 'flex', }} >
                        Choix de Facturation
                        <Tooltip title={'Fermer'} placement="left">
                            <IconButton style={{ padding: 2, margin: '0 0 0 auto' }} onClick={() => { setIsAddInstitution(false); }}>
                                <CloseIcon style={{ color: '#5E6E82' }} />
                            </IconButton>
                        </Tooltip>
                    </h1>
                    <hr style={{ margin: 0, opacity: 0.2 }} />
                </Box>
                <Grid container spacing={0} style={{ maxWidth: 600, padding: 23 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle}>
                            <Box style={{ cursor: 'pointer' }} onClick={() => { props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_mixte_list')) }}>
                                <Box><ApartmentIcon /></Box>
                                <Box>
                                    <p><b>Facturation mixte</b></p>
                                    <p>Accéder à la lists des établissements</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle}>
                            <Box style={{ cursor: 'pointer' }} onClick={() => { props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_personal_list')) }}>
                                <Box><AccountBoxIcon /></Box>
                                <Box>
                                    <p><b>Facturation personnel</b></p>
                                    <p>Accéder à la lists des établissements</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className={classes.boxDialogStyle} onClick={() => props.history.push(getRoutePathname('institution_health_financial_management_billing_formation_list'))}>
                            <Box style={{ cursor: 'pointer' }}>
                                <Box><DvrIcon /></Box>
                                <Box>
                                    <p><b>Facturation formation</b></p>
                                    <p>Accéder à la lists des sessions formation</p>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export function CardInstitution(props) {
    return (
        <CardInstitutionComponent
            data={props.data}
            button={{
                title: 'Gestion d’établissement',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#5E6E82' },
                background: '#FFFFFF',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_institution_list')) }
            }}
        />
    )
}

export function CardInvestisor(props) {
    return (
        <CardComponent
            icon={<TrendingUpIcon style={{ width: 20, height: 20 }} />}
            title={'Investisseur'}
            text={'Statistiques.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_investor')) }
            }}
        />
    )
}

export function CardVirtualClassroom(props) {
    return (
        <CardComponent
            icon={<ComputerIcon style={{ width: 20, height: 20 }} />}
            title={'Classes virtuelles'}
            text={'Liste classes virtuelles.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_virtual_classroom_list')) }
            }}
        />
    )
}

export function CardRessource(props) {
    return (
        <CardComponent
            icon={<CategoryIcon style={{ width: 20, height: 20 }} />}
            title={'Ressource'}
            text={'Liste des ressources.'}
            background={'#5E6E82'}
            color={'#FFF'}
            button={{
                title: 'Accéder',
                color: '#5E6E82',
                background: '#FFFFFF',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_resource_list')) }
            }}
        />
    )
}

export function CardCourse(props) {
    return (
        <CardComponent
            icon={<SchoolIcon style={{ width: 20, height: 20 }} />}
            title={'Cours'}
            text={'Liste des cours.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_course_list')) }
            }}
        />
    )
}

export function CardFormations(props) {
    return (
        <CardComponent
            icon={<DvrIcon style={{ width: 20, height: 20 }} />}
            title={'Formations'}
            text={'Liste des formations.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_content_manager_formation_list')) }
            }}
        />
    )
}

export function CardSessions(props) {
    return (
        <CardComponent
            icon={<DateRangeIcon style={{ width: 20, height: 20 }} />}
            title={'Sessions'}
            text={'Liste des sessions.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_admin_session_list')) }
            }}
        />
    )
}

export function CardInstitutionGroup(props) {
    return (
        <CardInstitutionGroupComponent
            data={props.data}
            button={{
                title: 'Gestion de groupe',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#FFFFFF' },
                background: '#5E6E82',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_institution_group_list')) }
            }}
        />
    )
}

export function CardInstitutionGroup2(props) {
    return (
        <CardComponent
            icon={<GroupWorkIcon style={{ width: 17, height: 17 }} />}
            title={'Gestion de groupe'}
            text={'Liste de groupement d\'établissement.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_institution_group_list')) }
            }}
        />
    )
}

export function CardDashboardManager(props) {
    return (
        <CardDashboardManagerComponent
            data={props.data}
            button={{
                title: 'Détails',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#5E6E82' },
                background: '#FFFFFF',
                isAccess: getAccess(props).isCrmAdmin,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_dashboard_manager')) }
            }}
        />
    )
}

export function CardRelaunch(props) {
    return (
        <CardRelaunchComponent
            data={props.data}
            button={{
                title: 'Accéder',
                style: { margin: 'auto auto 0 auto', width: 'fit-content', padding: '6px 40px', color: '#FFFFFF' },
                background: '#5E6E82',
                isAccess: getAccess(props).isCrm,
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_relaunch_list')) }
            }}
        />
    )
}

export function CardRelaunchV2(props) {
    return (
        <CardComponent
            icon={<PlaylistAddCheckIcon />}
            title={'Suivi des inscriptions'}
            text={'Liste des inscriptions.'}
            button={{
                title: 'Accéder',
                background: '#5E6E82',
                onClick: () => { props.history.push(getRoutePathname('institution_health_crm_relaunch_list')) }
            }}
        />
    )
}

const useStyles = makeStyles({
    boxDialogStyle: {
        background: '#EFF2F4',
        boxShadow: '0px 0px 2px #0000005e',
        borderRadius: 4,
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            padding: 18,
            margin: '5px 0',
        },
        '& label': {
            background: 'transparent !important'
        },
        '& p': {
            color: '#5E6E82',
            margin: 0
        },
        '& svg': {
            color: '#5E6E82',
            margin: 0
        }
    },
});