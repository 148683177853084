import React from 'react';
import Api from "../../../../Api";
import { Box, IconButton, Tooltip, makeStyles, Drawer as DrawerMUI, Grid } from '@material-ui/core';
import TableComponent from '../../../../Component/TableComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Edit, Assignment, Cancel } from "@material-ui/icons";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FilterListIcon from "@material-ui/icons/FilterList";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useDispatch, useSelector } from 'react-redux';
import { TableReload } from '../../../../Action/TableAction';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SubjectIcon from '@material-ui/icons/Subject';
import SelectComponent from '../../../../Component/SelectComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { getRoutePathname } from '../../../../Config/Route';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import moment from 'moment';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';

export default function InvoiceList(props) {

    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dataEdit, setDataEdit] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(true);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loadDownload, setLoadDownload] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(true);
    const [filtersData, setFiltersData] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const currentYear = new Date().getFullYear();

    //Options
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionsInstitution, setOptionsInstitution] = React.useState([]);
    const [optionsInvoiceType, setOptionsInvoiceType] = React.useState([]);
    const [optionsType, setOptionsType] = React.useState([
        { value: 1, label: 'Facture' },
        { value: 2, label: 'Avoir' },
        { value: 3, label: 'Contrat' },
    ]);
    const [optionsSteps, setOptionsSteps] = React.useState([
        { value: 1, label: 'Étape 1' },
        { value: 2, label: 'Étape 2' },
        { value: 3, label: 'Étape 3' },
    ]);
    const [optionsPaid, setOptionsPaid] = React.useState([
        { value: 'all', label: 'Tout' },
        { value: 1, label: 'Payer' },
        { value: 2, label: 'Non Payer' },
    ]);
    const [optionsInternal, setOptionsInternal] = React.useState([
        { value: 'all', label: 'Tout' },
        { value: 1, label: 'Interne' },
        { value: 2, label: 'Réel' },
    ]);
    const [optionsContractType, setOptionsContractType] = React.useState([
        { value: 'all', label: 'Tout' },
        { value: 1, label: 'Personnel' },
        { value: 2, label: 'Mixte' },
    ]);
    const [optionsCancel, setOptionsCancel] = React.useState([
        { value: 'all', label: 'Tout' },
        { value: 1, label: 'Annuler' },
        { value: 2, label: 'Non Annuler' },
    ]);
    const [optionsComplete, setOptionsComplete] = React.useState([
        { value: 'all', label: 'Tout' },
        { value: 1, label: 'Complété' },
        { value: 2, label: 'Non Complété' },
    ]);
    const [type, setType] = React.useState(1);
    const [step, setStep] = React.useState(1);


    const invoiceColumns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Numéro",
            options: { filter: true, sort: true }
        },
        {
            name: "invoiceTypeLabel",
            label: "Type de facture",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Fait le",
            options: { filter: true, sort: true }
        },
        {
            name: "billingAt",
            label: "Pour le",
            options: { filter: true, sort: true }
        },
        {
            name: "paidAt",
            label: "Payé le",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Annulé le",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "amountHt",
            label: "Prix HT",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTtc",
            label: "Prix TTC",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const creditColumns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Numéro d'avoir",
            options: { filter: true, sort: true }
        },
        {
            name: "invoiceTypeLabel",
            label: "Type de facture",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Fait le",
            options: { filter: true, sort: true }
        },
        {
            name: "amountHt",
            label: "Prix HT",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTtc",
            label: "Prix TTC",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const contractColumns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Numéro de contrat",
            options: { filter: true, sort: true }
        },
        {
            name: "contractType",
            label: "Type de contrat",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Fait le",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "amountHt",
            label: "Prix HT",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTtc",
            label: "Prix TTC",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        let route = '';
        let data = {};
        if (row?.invoiceSessionFormationId) {
            route = 'institution_health_financial_management_billing_formation'
            data = { id: row?.invoiceSessionFormationId }
        } else {
            route = row.isPersonal ? 'institution_health_financial_management_billing_institution_invoice_personal' :
                'institution_health_financial_management_billing_institution_invoice'
            data = { institution: row?.institutionId, id: row?.invoiceContractId }
        }
        return (
            <div style={{ minWidth: 90 }}>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => {
                        window.open(getRoutePathname(route, data))
                    }}>
                        <LibraryBooksIcon />
                    </IconButton>
                </Tooltip>
                {/*(parseInt(type) === 1) && < Tooltip title={row.isPaid ? 'Annuler le paiement' : 'Valider le paiement'} placement="left">
                    <IconButton disabled={row.cancelAt ? true : false} onClick={() => setDataEdit(row)}>
                        {row.isPaid ? <MoneyOffIcon /> : <AttachMoneyIcon />}
                    </IconButton>
                </Tooltip>*/}
            </div >
        );
    };

    const [formSearch, setFormSearch] = React.useState({
        label: {
            name: 'label',
            label: 'Numéro',
            textHelper: 'Saisissez un numéro.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].label ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].label : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].year ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].year : null,
            options: {}
        },
        institutionId: {
            name: 'institutionId',
            label: 'Établissement',
            textHelper: 'Trier par Établissement',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].institutionId ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].institutionId : 'all',
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Trier par type',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].type ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].type : 1,
            options: {}
        },
        isCompleted: {
            name: 'isCompleted',
            label: 'État du contrat',
            textHelper: 'Trier par état du contrat',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].isCompleted ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].isCompleted : 'all',
            options: {}
        },
        isPersonal: {
            name: 'isPersonal',
            label: 'Type de document',
            textHelper: 'Trier par type de contrat',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].isPersonal ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].isPersonal : 'all',
            options: {}
        },
        step: {
            name: 'step',
            label: 'Étape de facture',
            textHelper: 'Saisissez étape de facture',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].step ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].step : 1,
            options: {}
        },
        invoiceTypeId: {
            name: 'invoiceTypeId',
            label: 'Type de facture',
            textHelper: 'Saisissez type de facture',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].invoiceTypeId ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].invoiceTypeId : 'all',
            options: {}
        },
        isCancel: {
            name: 'isCancel',
            label: 'Annulation',
            textHelper: 'Trier par type d\'annulation',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].isCancel ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].isCancel : 'all',
            options: {}
        },
        isPaid: {
            name: 'isPaid',
            label: 'Paiement',
            textHelper: 'Trier par type de paiement',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].isPaid ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].isPaid : 'all',
            options: {}
        },
        isInternal: {
            name: 'isInternal',
            label: 'Type d\'avoir',
            textHelper: 'Trier par type d\'avoir',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].isInternal ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].isInternal : 'all',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Début de session',
            textHelper: 'Trier par début de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].startAt ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de session',
            textHelper: 'Trier par fin de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].endAt ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].endAt : '',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        if ((index === 'step')) {
            handlerSearch.setValue('invoiceTypeId', 'all');
            handlerSearch.setValue('isCancel', 'all');
            handlerSearch.setValue('startAt', null);
            handlerSearch.setValue('endAt', null);
        }
        if ((index === 'type')) {
            if (parseInt(value) != 3) {
                handlerSearch.setValue('institutionId', 'all');
                handlerSearch.setValue('isPersonal', 'all');
                handlerSearch.setValue('isCancel', 'all');
                handlerSearch.setValue('isCompleted', 'all');
            }
            if (parseInt(value) != 1) {
                handlerSearch.setValue('isPaid', 'all');
            }
            if (parseInt(value) != 2) {
                console.log('ici')
                handlerSearch.setValue('isInternal', 'all');
            }
        }
        cacheTablePage['institution_health_financial_management_billing_invoice_list'] = 0;
        cacheTableServer['institution_health_financial_management_billing_invoice_list'].page = 0;
        cacheTableServer['institution_health_financial_management_billing_invoice_list'][index] = value;
        dispatch(TableReload('institution_health_financial_management_billing_invoice_list'));
    };

    const getFilterValues = (options) => {
        let data = handlerSearch.getData();
        for (const key in data) {
            if ((data[key] === 'all')) {
                data[key] = null;
            } else if ((key === 'isCancel') ||
                (key === 'isCompleted') ||
                (key === 'isPersonal') ||
                (key === 'isPaid') ||
                (key === 'isInternal')) {
                data[key] = (parseInt(data[key]) === 1) ? true : false;
            }
        }

        let optionsFilter = {}

        if (options) {
            optionsFilter = {
                limit: options.rowsPerPage,
                offset: options.page * options.rowsPerPage,
                sortName: options.sortOrder.name,
                sortDirection: options.sortOrder.direction
            }
        } else {
            optionsFilter = {
                limit: 10000,
            }
        }

        const filters = {
            ...data,
            ...optionsFilter
        };
        setFiltersData(filters);
        return filters
    }

    const getDownloadExcel = () => {
        setLoadDownload(true);
        dispatch(LinearProgressMainLayoutActivate());
        let exportType = optionsType.find((data) => parseInt(data.value) === parseInt(type))?.label ?? 'suivi';
        const { offset, limit, ...updatedObject } = filtersData;
        Api.getDownload({
            route: 'institution_health_financial_management_billing_invoice_export',
            filename: `export_liste_${exportType}.xls`,
            data: { ...updatedObject, organismId: selectOrganismReducer.organism.value }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                setLoadDownload(false);
            }
        })
    }

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box className={classes.filterStyle}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'type'} handler={handlerSearch} options={optionsType} onChange={(val) => { inputSearchChange('type', val); setType(val) }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'label'} handler={handlerSearch} onChange={(val) => inputSearchChange('label', val)} />
                    </Grid>
                    {(parseInt(type) != 3) &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SelectComponent name={'step'} handler={handlerSearch} options={optionsSteps} onChange={(val) => { inputSearchChange('step', val); setStep(val); }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SelectComponent name={'invoiceTypeId'} handler={handlerSearch} options={optionsInvoiceType} onChange={(val) => { inputSearchChange('invoiceTypeId', val) }} />
                            </Grid>
                        </>
                    }
                    {(parseInt(type) === 1) &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'isPaid'} handler={handlerSearch} options={optionsPaid} onChange={(val) => { inputSearchChange('isPaid', val) }} />
                        </Grid>
                    }
                    {(parseInt(type) === 2) &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'isInternal'} handler={handlerSearch} options={optionsInternal} onChange={(val) => { inputSearchChange('isInternal', val) }} />
                        </Grid>
                    }
                    {parseInt(step) != 2 &&
                        <>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SelectComponent name={'institutionId'} handler={handlerSearch} options={optionsInstitution} onChange={(val) => { inputSearchChange('institutionId', val) }} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <SelectComponent name={'isPersonal'} handler={handlerSearch} options={optionsContractType} onChange={(val) => { inputSearchChange('isPersonal', val) }} />
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'isCancel'} handler={handlerSearch} options={optionsCancel} onChange={(val) => { inputSearchChange('isCancel', val) }} />
                    </Grid>
                    {(parseInt(type) === 3) &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <SelectComponent name={'isCompleted'} handler={handlerSearch} options={optionsComplete} onChange={(val) => { inputSearchChange('isCompleted', val) }} />
                        </Grid>}
                </Grid>
                {((parseInt(type) != 3) && parseInt(step) === 2) &&
                    <>
                        <Box className={classes.filterStyle}>
                            <hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                            <p style={{ margin: 0, padding: '0 10px' }}>Session</p>
                            <hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                        </Box>
                        <Grid container spacing={1} style={{ width: '100%', margin: 0 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                            </Grid>
                        </Grid>
                    </>
                }
            </Box>
        )
    }

    const savePaid = (checked, id) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'institution_health_financial_management_billing_invoice_paid',
            data: { isPaid: checked },
            params: { id: id }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                dispatch(TableReload('institution_health_financial_management_billing_invoice_list'))
                dispatch(
                    SnackbarOpen({
                        text: response.data.message,
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                dispatch(TableReload('institution_health_financial_management_billing_invoice_list'))
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                    })
                );
            } else {
                dispatch(TableReload('institution_health_financial_management_billing_invoice_list'))
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    onClick={() => getDownloadExcel()}
                    disabled={loadDownload}
                    label={<span style={{ display: 'flex', gap: 5, alignItems: 'center' }}><GetAppIcon style={{ color: loadDownload ? 'rgba(0, 0, 0, 0.26)' : '#FFFFFF' }} />Télécharger au format Excel</span>}
                />
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<span style={{ display: 'flex', gap: 5, alignItems: 'center' }}><FilterListIcon style={{ color: '#FFFFFF' }} />Filtre</span>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Suivi de facturation',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste de suivi de facturation.',
            html: <GetActionMore />
        });
    }, [isOpen, filtersData, loadDownload]);

    React.useEffect(() => {
        setReadyContent(true);
        handlerSearch.setFormLoading(true)
        Api.get({ route: 'institution_health_financial_management_billing_select_year' },
            (response) => {
                if (response && response.data) {
                    handlerSearch.setValue('year', response.data[0].value);
                    setReadyContent(false);
                    handlerSearch.setFormLoading(false)
                    setOptionYears(response.data);
                }
            });
    }, []);

    React.useEffect(() => {
        Api.get({
            route: 'institution_health_financial_management_billing_select_invoice_type',
            data: { step: step }
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionsInvoiceType(data);
                }
            });
    }, [step]);

    React.useEffect(() => {
        if (handlerSearch.form.year.value) {
            Api.get({
                route: 'institution_health_financial_management_billing_select_institution',
                data: { year: handlerSearch.form.year.value, organismId: selectOrganismReducer.organism.value }
            },
                (response) => {
                    if (response && response.data) {
                        let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                        setOptionsInstitution(data);
                    }
                });
        }
    }, [handlerSearch.form.year.value]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_financial_management_billing_invoice_list'}
                        title={
                            <h1 style={{ margin: 0, fontSize: 17, display: 'flex', alignItems: 'center', gap: 5 }}>
                                <SubjectIcon style={{ width: 20, height: 20 }} />
                                {optionsType.find((data) => parseInt(data.value) === parseInt(type))?.label ?? 'List'}
                            </h1>
                        }
                        search={false}
                        columns={parseInt(type) === 3 ? contractColumns : parseInt(type) === 2 ? creditColumns : invoiceColumns}
                        promiseServerData={(resolve, options) => {
                            let filters = getFilterValues(options);
                            Api.get({
                                route: 'institution_health_financial_management_billing_invoice_list',
                                data: { ...filters, organismId: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        for (let index in data) {
                                            const descriptions = {
                                                1: { text: 'Facture | Annuler', color: '#DD4E4E' },
                                                2: { text: 'Facture | Payer', color: '#28a745' },
                                                3: { text: 'Facture | Non Payer', color: '#007bff' },
                                                4: { text: 'Annuler', color: '#DD4E4E' },
                                                5: { text: 'Non Complété', color: '#007bff' },
                                                6: { text: 'Complété', color: '#28a745' },
                                                default: { text: '-', color: '' }
                                            };

                                            const conditions = [
                                                { condition: ((parseInt(type) === 1) && (data[index].cancelAt ? true : false)), type: 1 },
                                                { condition: ((parseInt(type) === 1) && data[index].isPaid), type: 2 },
                                                { condition: ((parseInt(type) === 1) && !data[index].isPaid), type: 3 },
                                                { condition: ((parseInt(type) === 3) && (data[index].cancelAt ? true : false)), type: 4 },
                                                { condition: ((parseInt(type) === 3) && !data[index].isCompleted), type: 5 },
                                                { condition: ((parseInt(type) === 3) && data[index].isCompleted), type: 6 },
                                            ];

                                            const descriptionType = conditions.find(cond => cond.condition)?.type || 'default';
                                            const { text: stateLabel, color } = descriptions[descriptionType];

                                            data[index].action = getAction(data[index]);
                                            data[index].invoiceTypeLabel = data[index].invoiceTypeLabel ?? '-';
                                            data[index].contractType = data[index].isPersonal ? <span style={{ color: '#9C27B0', fontWeight: 'bold' }}>Personnel</span> : <span style={{ color: '#007bff', fontWeight: 'bold' }}>Mixte</span>;
                                            data[index].label = data[index].estimationLabel ?? data[index].label ?? '-';
                                            data[index].state = <span style={{ color: color, width: 'max-content', display: 'block', fontWeight: 'bold' }}>{stateLabel}</span>;
                                            data[index].createdAt = data[index].createdAt ? moment(data[index].createdAt).format('lll') : '-';
                                            data[index].billingAt = data[index].billingAt ? moment(data[index].billingAt).format('lll') : '-';
                                            data[index].paidAt = data[index].paidAt ? moment(data[index].paidAt).format('lll') : '-';
                                            data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt).format('lll') : '-';
                                            data[index].amountTtc = <span style={{ color: '#007bff', fontWeight: 'bold' }}>{data[index].amountTtc ?? 0} €</span>;
                                            data[index].amountHt = <span style={{ color: '#007bff', fontWeight: 'bold' }}>{data[index].amountHt ?? 0} €</span>;
                                        }
                                        resolve(data, response.data.count);
                                    } else resolve([]);
                                });
                        }}
                    />
                </Box>
                <SweetAlert
                    show={dataEdit}
                    title={dataEdit?.isPaid ? 'Annuler le paiement' : 'Valider le paiement'}
                    text={
                        dataEdit?.isPaid ?
                            `Êtes-vous sûr de vouloir annuler le paiement ?` :
                            `Êtes-vous sûr de vouloir valider le paiement ?`
                    }
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        setDataEdit(null);
                        savePaid(!dataEdit.isPaid, dataEdit.id)
                    }}
                    onCancel={() => setDataEdit(null)}
                />
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    filterStyle: {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        padding: '19px 19px 10px 19px',
        color: '#5E6E82', opacity: 0.7, fontSize: 14
    }
}))
